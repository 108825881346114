import { BasicRestClient } from "../basicRestClient";
import { FinishDialogData } from "./finishDialogData";
import { StepData } from "./stepData";
import { SUPPORTED_DOCUMENT_TYPES } from "../../utility/supportedDocumentTypes";



const START_DIALOG_OPERATION = "/startDialog";
const FINISH_DIALOG_OPERATION = "/processStep";
const UPLOAD_DIALOG_FILE_OPERATION = "/uploadDialogFile"
const REMOVE_DIALOG_FILE_OPERATION = "/removeDialogFile"
const KC_MODEL_TREE_ID_QUESTIONNAIRE = "obj://de.usu.knowledge_guide.model.TreeImpl/knowledgeObjects/usukb:";
const KC_MODEL_TREE_ID_ERROR_PATTERN = "errorPattern:knowledgeObjects/usukb:";

const KVS_QUESTIONNAIRE_ID_PREFIX = "docid";

const POST_HEADERS = {
    "Content-Type": "application/json",
    "Access-Control-Request-Headers": "Content-Type",
    "Access-Control-Request-Method": "POST"
};

export class KnowledgeGuideServiceRestClient extends BasicRestClient {    

    /**
     * 
     * @param {String} p_treeId 
     * @param {String} p_mandator mandator
     * @param {String} p_language language     
     */
    _startDialog(p_treeId, p_mandator, p_language) {
        //console.kc_log("guid %s", p_docGUID)
        let startDialogServiceURL = this.createServiceURL(this.configuration.knowledgeGuideServiceURL, START_DIALOG_OPERATION);

        //let treeId = KC_MODEL_TREE_ID + p_docGUID;

        let l_body = JSON.stringify(
            {
                "properties":
                    [
                        { "name": "testRun", "value": "false" },
                        { "name": "mandatorKey", "value": p_mandator },
                        { "name": "language", "value": p_language }
                    ]
            }
        );

        if(startDialogServiceURL.indexOf('?') < 0) {
            startDialogServiceURL = startDialogServiceURL + "?treeId=" + p_treeId;
        } else {
            startDialogServiceURL = startDialogServiceURL + "&treeId=" + p_treeId;
        }

        return fetch(startDialogServiceURL, {
            method: 'POST',
            mode: 'cors',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders(POST_HEADERS), 
            body: l_body
        })
            .then(response =>
                response.text())
            .then(text => {
                console.kc_log("Result of calling POST KnowledgeGuideService.startDialog\nURL: %s\nbody: %s\n\n%s", startDialogServiceURL, l_body, text);
                return text;
            })
            .catch(function (err) {
                console.log(err)
                console.kc_log(err);
            });
    }

    startDialog(p_docType, p_docGUID, p_mandator, p_language) {
        let l_treeId = '';
        switch (p_docType) {
            case SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE:
                l_treeId = KC_MODEL_TREE_ID_QUESTIONNAIRE + p_docGUID;
                break;
            case SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE:
                l_treeId = KC_MODEL_TREE_ID_ERROR_PATTERN + p_docGUID;
                break;
        }
        return this._startDialog(l_treeId, p_mandator, p_language);
    }

    /**
         * 
         * @param {*} p_document the filled questionnaire
         * @param {*} p_contentLanguage the content language
         */
    resetDialog(p_document,  p_contentLanguage) {
        let l_resetDialogServiceURL = this.createServiceURL(this.configuration.knowledgeGuideServiceURL, FINISH_DIALOG_OPERATION, { "dialogId": p_document.dialogId });

        console.kc_log("Calling POST KnowledgeGuideService.resetDialog\nURL: %s", l_resetDialogServiceURL);

        // creating the result object
        let result = new FinishDialogData();

        // finally add global properties
        result.properties.push({ "name": "language", "value": p_contentLanguage });
        result.properties.push({ "name": "resetDialog", "value": "true" });

        // Variables should not be sent via request
        result.variables = undefined;

        console.kc_log("POST KnowledgeGuideService.resetDialog body %s", JSON.stringify(result));

        // and call the endpoint
        return fetch(l_resetDialogServiceURL, {
            method: 'POST',
            mode: 'cors',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders(POST_HEADERS),
            body: JSON.stringify(result)
        }).then(response => console.kc_log(response))
    }

    /**
     * 
     * @param {*} p_document the filled questionnaire
     * @param {*} p_contentLanguage the content language     
     * @param {*} p_documentType the type of the document
     */
    finishDialog(p_document, p_contentLanguage, p_documentType, p_rawQuestionnaire) {
        let l_finishDialogServiceURL = this.createServiceURL(this.configuration.knowledgeGuideServiceURL, FINISH_DIALOG_OPERATION, { "dialogId": p_document.dialogId });

        console.kc_log("Calling POST KnowledgeGuideService.finishDialog\nURL: %s\nDocument: %o", l_finishDialogServiceURL, p_document);

        // creating the result object
        let result = new FinishDialogData();
        switch (p_documentType) {
            case SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE: this._createFinishDialogStepDataForQuestionnaire(p_document, result); break;
            case SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE: this._createFinishDialogStepDataForErrorPattern(result); break;
        }

        // finally add global properties
        result.properties.push({ "name": "language", "value": p_contentLanguage });
        result.properties.push({ "name": "finish", "value": "true" });

        if(p_rawQuestionnaire) {

            if(p_rawQuestionnaire.bodyTemplateId) {
                result.properties.push({ "name": "transferData", "value": "true" });
                result.properties.push({ "name": "transfer_bodyTemplateId", "value": p_rawQuestionnaire.bodyTemplateId });
            }    
    
            if(p_rawQuestionnaire.templateId) {
                result.properties.push({ "name": "transfer_templateId", "value": p_rawQuestionnaire.templateId });
            }
    
            if(p_rawQuestionnaire.mandatorkey) {
                result.properties.push({ "name": "transfer_mandatorkey", "value": p_rawQuestionnaire.mandatorkey });
            }
    
            if(p_rawQuestionnaire.type) {
                result.properties.push({ "name": "transfer_type", "value": p_rawQuestionnaire.type });
            }


            var from = result.variables['from'];
            if(from && from.length > 0) {
                result.properties.push({ "name": "transfer_from", "value": from });
            } else {
                if(p_rawQuestionnaire.from) {
                    result.properties.push({ "name": "transfer_from", "value": p_rawQuestionnaire.from });
                }
            }

            var to = result.variables['to'];
            if(to && to.length > 0) {
                result.properties.push({ "name": "transfer_to", "value": to });
            } else {
                if(p_rawQuestionnaire.to) {
                    result.properties.push({ "name": "transfer_to", "value": p_rawQuestionnaire.to });
                }
            }

            var cc = result.variables['cc'];
            if(cc && cc.length > 0) {
                result.properties.push({ "name": "transfer_cc", "value": cc });
            } else {
                if(p_rawQuestionnaire.cc) {
                    result.properties.push({ "name": "transfer_cc", "value": p_rawQuestionnaire.cc });
                }
            }

            var bcc = result.variables['bcc'];
            if(bcc && bcc.length > 0) {
                result.properties.push({ "name": "transfer_bcc", "value": bcc });
            } else {
                if(p_rawQuestionnaire.bcc) {
                    result.properties.push({ "name": "transfer_bcc", "value": p_rawQuestionnaire.bcc });
                }
            }

            var subject = result.variables['subject'];
            if(subject && subject.length > 0) {
                result.properties.push({ "name": "transfer_subject", "value": subject });
            } else {
                if(p_rawQuestionnaire.subject) {
                    result.properties.push({ "name": "transfer_subject", "value": p_rawQuestionnaire.subject });
                }
            }
        }

        // Variables should not be sent via request
        result.variables = undefined;
        

        console.kc_log("POST KnowledgeGuideService.finishDialog body %s", JSON.stringify(result));        

        // and call the endpoint
        return fetch(l_finishDialogServiceURL, {
            method: 'POST',
            mode: 'cors',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders(POST_HEADERS),                
            body: JSON.stringify(result)
        })        
        .then(response => response.json()); 
    }

    //remove stepData from result to send
    _createFinishDialogStepDataForErrorPattern(p_result) {
        delete p_result.stepData;
    }

    _createFinishDialogStepDataForQuestionnaire(p_questionnaire, p_result) {
        // create stepData, also including multiple answers
        console.kc_log("trying to create finishdialogStepDataForQuestionnaire: %o, %o", p_questionnaire, p_result)
        for (let questionKey of Object.keys(p_questionnaire.questions)) {
            let currentStepData = new StepData(questionKey);
            let question = p_questionnaire.questions[questionKey];

            for (let answerKey of Object.keys(question.answers)) {
                let answer = question.answers[answerKey];
                if (answer.chosen) {
                    currentStepData.stateId.push(answerKey);
                    if(question.sid && answer.title) {
                        if(!p_result.variables[question.sid]) {
                            p_result.variables[question.sid] = answer.title;
                        } else {
                            p_result.variables[question.sid] += ',' + answer.title;
                        }
                    }
                }
            }
            if (question.extraData && question.extraData.valueProperties) {
                // now add the properties coming from extraDataValue
                for (let currentPropertyPack of question.extraData.valueProperties) {
                    if(currentPropertyPack.properties) {
                        for (let propertyKey of Object.keys(currentPropertyPack.properties)) {
                            currentStepData.properties.push({ "name": propertyKey, "value": currentPropertyPack.properties[propertyKey] });
                            if(question.sid && propertyKey == '=text') {
                                if(!p_result.variables[question.sid]) {
                                    p_result.variables[question.sid] = currentPropertyPack.properties[propertyKey];
                                } else {
                                    p_result.variables[question.sid] += ',' + currentPropertyPack.properties[propertyKey];
                                }
                            }
                        }
                    }              
                }
            }
            p_result.data.push(currentStepData);
        }
    }

    /**
     * uploads one or more files
     * @param {*} p_dialogId 
     * @param {*} p_questionId 
     * @param {*} p_files      
     */
    uploadDialogFile(p_dialogId, p_questionId, p_files) {
        let l_uploadDialogFileURL = this.createServiceURL(this.configuration.knowledgeGuideServiceURL, UPLOAD_DIALOG_FILE_OPERATION,
            {
                "dialogId": p_dialogId,
                "questionId": p_questionId,
                "multiple": "true"
            });

        let l_formData = new FormData();
        for( let i = 0; i < p_files.length; i++)
        {
            l_formData.append('file'+i, p_files.item(i));
        }

        console.kc_log("Calling POST KnowledgeGuideService.uploadDialogFile\nURL: %s", l_uploadDialogFileURL);
        return fetch(l_uploadDialogFileURL, {
            method: 'POST',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders(),
            body: l_formData,
        })
            .then(response => response.json());
    }

    /**
     * remove already uploaded file from questionnaire
     * @param {*} p_dialogId 
     * @param {*} p_questionId 
     * @param {*} p_fileId
     */
     removeDialogFile(p_dialogId, p_questionId, p_fileId) {
        let l_removeDialogFileURL = this.createServiceURL(this.configuration.knowledgeGuideServiceURL, REMOVE_DIALOG_FILE_OPERATION,
            {
                "dialogId": p_dialogId,
                "questionId": p_questionId,
                "fileId": p_fileId
            });

        console.kc_log("Calling GET KnowledgeGuideService.removeDialogFile\nURL: %s", l_removeDialogFileURL);
        return fetch(l_removeDialogFileURL, {
            method: 'GET',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders()
        });
    }

    /**
     * Creates a request to KeyValueStore to write the answers to extra data fields.
     * 
     */
     sendQuestionnaireToValueStore(p_questionnaire, p_kvsUrl, p_kvsSessionId, p_rawQuestionnaire) {
        let l_extradata = this._getExtraData(p_questionnaire);
        let l_answersWithTitles = this._getAnswerTitles(p_questionnaire, p_rawQuestionnaire);
        let l_dataToSend = this._createDataToSendToKVS(l_extradata, l_answersWithTitles);
        
        let l_dataId = KVS_QUESTIONNAIRE_ID_PREFIX + p_questionnaire.id;

        let l_url = (p_kvsUrl.endsWith('/')? p_kvsUrl + p_kvsSessionId : p_kvsUrl + '/' + p_kvsSessionId) + '/' + l_dataId;

        fetch(l_url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(l_dataToSend)
        });
    }

    /**
     *  Returns an array with all the extra data.
     * @param {*} p_questionnaire 
     */
    _getExtraData(p_questionnaire) {
        let l_result = [];
        for (let questionKey of Object.keys(p_questionnaire.questions)) {
            let question = p_questionnaire.questions[questionKey];
            let l_extradata = [];
            if (question.extraData.valueProperties) {
                // now add the properties coming from extraDataValue
                for (let currentPropertyPack of question.extraData.valueProperties) {
                    if(currentPropertyPack.properties) {
                        for (let propertyKey of Object.keys(currentPropertyPack.properties)) {
                            l_extradata.push({ "name": propertyKey, "value": currentPropertyPack.properties[propertyKey], "sid": question.sid?question.sid:question.id });
                            
                        }
                    }              
                }
            }
            if(l_extradata.length > 0) {
                l_result.push(l_extradata);
            }
                
        }
        return l_result;
    }

    _getAnswerTitles(p_questionnaire, p_rawQuestionnaire) {
        let l_answeredQuestions = []
        for(let questionKey of Object.keys(p_questionnaire.questions)) {
            let question = p_questionnaire.questions[questionKey]; 
            if(question.answerMode === "single" && question.answered) {
                l_answeredQuestions.push(question);
            } else if(question.answerMode === "multiple") {
                for(let answerKey of Object.keys(question.answers)) {
                    let answer = question.answers[answerKey];
                    if(answer.chosen) {
                        l_answeredQuestions.push(question);
                        break;
                    }
                }
            }
        }
        let l_answersWithTitles = [];
        for(let answeredQuestion of l_answeredQuestions) {
            let data = {};
            data.sid = answeredQuestion.sid;
            let rawQuestion = p_rawQuestionnaire.questions.find(q => q.id === answeredQuestion.id);
            data.answers = [];
            for(let answerKey of Object.keys(answeredQuestion.answers)) {
                let answer = answeredQuestion.answers[answerKey];
                if(answer.chosen) {
                    let rawAnswer = rawQuestion.answers.find(a => a.id === answer.id);

                    data.answers.push({
                        "id": rawAnswer.id,
                        "title": rawAnswer.title
                    });
                }
            }
            l_answersWithTitles.push(data);
        }
        return l_answersWithTitles;
    }

    _createDataToSendToKVS(p_extradata, p_answersWithTitles) {
        let l_dataToSend = {};

        for(let l_data of p_extradata) {
            let l_sid = l_data[0].sid;
            l_dataToSend[l_sid] = {};

            for(let i = 0; i < l_data.length; i++) {
                let l_key = l_data[i].name.replace('=','');
                
                l_dataToSend[l_sid][l_key] = l_data[i].value;
            }
        }

        for(let l_answer of p_answersWithTitles) {
            
            let l_sid = l_answer.sid;
            
            if(!l_dataToSend[l_sid]) {
                l_dataToSend[l_sid] = {}
            }
            l_dataToSend[l_sid].answers = l_answer.answers;
        }

        return l_dataToSend;
    }
    
}
