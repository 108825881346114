
export class Translate {
    getTranslatedLabel(p_languageTag, p_labelKey, p_labels)
    {
        let l_labelparts = p_labelKey.split('.');
        let l_labelobject = p_labels[p_languageTag];
        
        for(let part of l_labelparts)
        {
            l_labelobject = l_labelobject[part];
        }
        return l_labelobject;
    }
}