import { BasicRestClient } from "../basicRestClient";

const GET_NLS_OPERATION = "/nls"

export class KnowledgeServiceRestClient extends BasicRestClient {

    getNLS(p_language) {
        let l_getNLSValuesURL = this.createServiceURL(this.configuration.kcenterServiceURL, GET_NLS_OPERATION,
            {
                "class": 'InfoBoardMessages',
                "locale": p_language
            });

        console.kc_log("Get NLS list called with url\n%s", l_getNLSValuesURL);

        if(this.configuration.useNLSLabels)
        {
            return fetch(l_getNLSValuesURL, {
                method: 'GET',
                credentials: this.configuration.getCredentialsMode(),
                headers: this.createHeaders()
            })
                .then(response => response.text())
                .then(response => this._sanitizeToArray(response))
        } else 
        {
            return Promise.reject("not loading nls labels today!");
        }
    }

    _sanitizeToArray(p_text) {

        let sanitizedArray = [];
        //split by line
        let textArray = p_text.split("\n");
        for (let key in textArray) {
            let value = textArray[key];
            // omit unwanted lines
            if (value && !(value.includes('InfoBoardMessages') || value === '});')) {
                // split by json separator, add quotes and escape backslashes
                let kvSplit = value.split(':');
                let label = kvSplit.slice(1).join('').split('\\"').join('"').trim().replace('\"', '').trim();
                label = label.substring(0, label.indexOf('\",'));
                sanitizedArray[kvSplit[0].trim()] = label;
            }
        }
        return sanitizedArray;
    }
}