import { BasicRestClient } from "../basicRestClient";

const GET_DOCUMENT_OPERATION = "/documents/%guid%"

const GET_VALUELIST_OPERATION = "/valuelist/%listkey%"

const GET_VALUELIST_SL_OPERATION = "/documents/valuelist/%listkey%"

export class DocumentServiceRestClient extends BasicRestClient {

    getValueList(p_listKey, p_mandatorkey, p_language) {
        var valuelistOperation = GET_VALUELIST_OPERATION;
        if(this.configuration.embeddedInSmartLink === true) {
            valuelistOperation = GET_VALUELIST_SL_OPERATION;
        }

        let l_getValueListURL = this.createServiceURL(this.configuration.documentServiceURL, valuelistOperation,
            {
                "mandatorKey": p_mandatorkey,
                "language": p_language
            });
        l_getValueListURL = l_getValueListURL.replace("%listkey%", p_listKey);

        console.kc_log("Get value list called with url\n%s", l_getValueListURL);

        return fetch(l_getValueListURL, {
            method: 'GET',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders()
        })
            .then(response => response.json());
    }

    /**
     * get the document for this guid and mandator from backend
     * @param {*} p_guid 
     * @param {*} p_mandatorkey 
     * @param {*} p_contentLanguage 
     * @param {*} p_additionalData 
     */
    getDocument(p_guid, p_mandatorkey, p_contentLanguage, p_additionalData) {
        let l_getDocumentURL = this.createServiceURL(this.configuration.documentServiceURL, GET_DOCUMENT_OPERATION,
            {
                "mandatorKey": p_mandatorkey?p_mandatorkey:this.configuration.defaultMandator,
                "contentLanguage": p_contentLanguage
            });

        l_getDocumentURL = l_getDocumentURL.replace("%guid%", p_guid);

        let additionalData = p_additionalData;
        if (additionalData === undefined || additionalData.length == 0) {
            additionalData = { "references": "true", "referencedDocs": "true", "withGraph": "true", "includeReferencesUpToLevel": this.configuration.includeReferencesUpToLevel };
        }

        Object.keys(additionalData).forEach(function (key) {
            l_getDocumentURL += "&additionalData=" + key + "=" + additionalData[key];
        });

        console.kc_log("Get document called with url\n%s and given params guid %s, mandator %s, lang %s, additional %s", l_getDocumentURL, p_guid, p_mandatorkey, p_contentLanguage, p_additionalData);

        return fetch(l_getDocumentURL, {
            method: 'GET',
            credentials: this.configuration.getCredentialsMode(),
            headers: this.createHeaders()
        })
            .then(function (response) {
                if (response.status === 401) {
                    throw new Error('JWT validation failed');
                }
                return response;
            })
            .then(response => response.json())
            .then(response => Array.isArray(response) ? response[0] : response);
    }

    getDocumentWithCallback(p_guid, p_mandatorkey, p_contentLanguage, p_additionalData, p_callBack) {
        this.getDocument(p_guid, p_mandatorkey, p_contentLanguage, p_additionalData).then(response => p_callBack(response));
    }
}