export class PrimitiveAnswer {
    constructor(p_dependendComponent, p_referencedAnswer, p_negative) {
        this.dependendComponent = p_dependendComponent;
        this.referencedAnswer = p_referencedAnswer;
        this.negative = p_negative;
        this._answered = false;
    }

    checkAnswered() {
        return this._answered;
    }

    checkConditionsMet(p_chosenAnswers, p_notChosenAnswers) {
        if(p_chosenAnswers.length > 0) {
            let positiveResult = false;
            for (let oneChosenAnswer of p_chosenAnswers) {
                if (oneChosenAnswer === this.referencedAnswer) {
                    positiveResult = true;
                    break;
                }
            }
            if (this.negative) {
                // For negative condition check that referenced answer is included in notChosenAnswers
                if (positiveResult) {
                    this._answered = false;
                } else {
                    this._answered = p_notChosenAnswers && (p_notChosenAnswers.indexOf(this.referencedAnswer) >= 0);
                }
            } else {
                this._answered = positiveResult;
            }
        } else {
            this._answered = this.negative;
        }

        return this._answered
    }
}