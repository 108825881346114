export class Answer {
    constructor(p_id, p_title, p_order, p_label, p_nodeId) {
        this.id = p_id;
        this.title = p_title;
        this.order = p_order;
        this.label = p_label;
        this.nodeId = [];
        if(p_nodeId) {
            this.nodeId.push(p_nodeId);
        }
    }
}