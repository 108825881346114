import { EXTRA_DATA_STATE } from "./extraDataState";

export class ExtraData {
    constructor(p_type, p_mandatory, p_valueList) {

        this.type = p_type;
        this.mandatory = p_mandatory === 'true';
        this.valueProperties = '';
        this.answered = false;
        this.valueList = p_valueList;

        this.state = EXTRA_DATA_STATE.UNTOUCHED;
    }

    /**
     * 
     * @param {*} p_state EXTRA_DATA_STATE 
     */
    changeState(p_state) {
        this.state = p_state;
    }
}