export class PrimitiveValue {
    constructor(p_dependendComponent, p_referencedQuestion, p_expectedValue, p_userOrgUnitGuids) {
        this.dependendComponent = p_dependendComponent;
        this.referencedQuestion = p_referencedQuestion;
        this.expectedValue = p_expectedValue;
        this.userOrgUnitGuids = p_userOrgUnitGuids;

        if(p_userOrgUnitGuids) {
            for(let guid of p_userOrgUnitGuids) {
                if(this.expectedValue == guid) {
                    this._answered = true;
                    return;
                }
            }
            this._answered = false;
        } else {
            this._answered = true; // Condition returns true, if no list of userOrgUnitGuids is provided!
        }
    }

    checkAnswered() {
        return this._answered;
    }

    checkConditionsMet(p_conditions) {
        // SL implementation: Currently the condition is evaluated in the constructor.
        //
        // for (let condition of p_conditions) {
        //     if (condition === this.referencedQuestion) {
        //         return this._answered = true;
        //     }
        //     else {
        //         this._answered = false;
        //     }
        // }
        return this._answered;
    }
}