export class FocusTracker {
    
    /**
     * @param {HTMLElement} p_element HTML Element to track, usually the element containing the Document.
     */
    constructor(p_element) {
        this.hadFocus = false;
        this.lastFocusedElement;

        let l_listenerFunction = function(p_event) {
            this.hadFocus = true;
            this.lastFocusedElement = p_event.target;
        }

        p_element.addEventListener('focusin', l_listenerFunction.bind(this) );
    }

    getHadFocus() {
        return this.hadFocus;
    }

    getLastFocusedElement() {
        return this.lastFocusedElement;
    }

    setFocusToLastElement() {
        if(this.lastFocusedElement) {
            this.lastFocusedElement.focus()
        }        
    }

    resetFocus() {
        this.hadFocus = false;
        this.lastFocusedElement = undefined
    }
}