import { QuestionnaireBuilder } from "./questionnaireBuilder";
import { KnowledgeGuideServiceRestClient } from "../../rest/knowledgeGuideService/knowledgeGuideServiceRestClient";
import { DocumentServiceRestClient } from "../../rest/documentService/documentServiceRestClient";
import { DOCUMENT_STATES } from "../../utility/documentStates";
import { SUPPORTED_DOCUMENT_TYPES } from "../../utility/supportedDocumentTypes";
import { MultipleSelect } from "../../utility/select/multipleSelect";
import { EXTRA_DATA_STATE } from "./extraDataState";
import { Util } from "../../utility/util";



export class QuestionnaireEngine {
    constructor(p_rawQuestionnaire, p_basicConfiguration) {
        /**
         * This starts the Questionnaire,
         * then the next steps will be processed by
         * 1) changing the status of the question/answer that has been answered, based on the id
         * 2) evaluate if any conditions are met
         * 
        */
        this.basicConfiguration = p_basicConfiguration;
        this.knowledgeGuideServiceRestClient = new KnowledgeGuideServiceRestClient(this.basicConfiguration);
        this.documentServiceRestClient = new DocumentServiceRestClient(this.basicConfiguration);
        this.multipleSelects = {};
        this.rawQuestionnaire = p_rawQuestionnaire;
        this.questionnaireBuilder = new QuestionnaireBuilder(p_rawQuestionnaire);
        this.questionnaire = this.questionnaireBuilder.parse();
        this.uploadedFiles = {}; // keep record of uploaded files (needed for possible removal from questionnaire)
        this._addOptionsToSelect();
        this._addListenersToInputElements();
        this._addListenersToQuestionExtraData();
        this._addListenerToNextButton(this.basicConfiguration.nextButtonId, this.basicConfiguration.nextButtonAction);
        this._addListenerToFinishButton(this.basicConfiguration.finishButtonId, this.basicConfiguration.finishButtonAction, this.basicConfiguration.afterFinishCallback);
        this._showQuestions(this.questionnaire.currentAnsweredQuestion);
        this._addListenerToResetButton();
        this._markQuestionsAsMandatory();

        if (this.basicConfiguration.sendRequests) {
            // inform backend that a questionaire dialog has started and save the dialogId into the questionaire object for later use
            this.knowledgeGuideServiceRestClient.startDialog(SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE, p_rawQuestionnaire.guid, p_rawQuestionnaire.mandatorkey, p_rawQuestionnaire.contentLang).then(response => {
                this.questionnaire.dialogId = response;
                console.kc_log("Setting dialogId %s for questionnaire with guid %s", response, this.questionnaire.guid);
            })
        }
    }

    getQuestionnaire() {
        return this.questionnaire;
    }

    processStep(p_questionId, p_chosenAnswerId, p_newValueForAnswer) {
        this.questionnaire.changeAnswer(p_chosenAnswerId, p_questionId, p_newValueForAnswer);
        this.questionnaire.setCurrentAnsweredQuestion(p_questionId);
        this.questionnaire.evaluateConditions();
        this._showQuestions(this.questionnaire.currentAnsweredQuestion);
        this.questionnaire.setCurrentQuestionToAnswer();
        this._backToAnsweringQuestions(p_questionId);

        this._callProcessStepAction();

        return this.questionnaire;
    }

    printQuestionnaire() {
        let chosenAnswersAndQuestions = this.questionnaire.getChosenAnswers();
        let printout;

        for (let id of chosenAnswersAndQuestions) {
            let question = this.questionnaire.questions[id];
            let chosenAnswers = []

            if (question) {
                for (let answerKey of Object.keys(question.answers)) {
                    let answer = question.answers[answerKey];
                    if (answer.chosen) {
                        chosenAnswers.push(answer.id);
                    }
                }
                for (let rawQuestion of this.rawQuestionnaire.question) {
                    let printQuestion = {
                        "title": rawQuestion.title,
                        "answers": [],
                        "extraData": ''
                    };
                    if (rawQuestion.id === question.id) {

                        for (let rawAnswer of rawQuestion.answers) {
                            if (chosenAnswers.includes(rawAnswer.id)) {
                                printQuestion.answers.push(rawAnswer.title);
                            }
                        }
                        if (!(rawQuestion['stepGui_extraDataType'])) {
                            printout.push(printQuestion);
                        }
                    }
                    if (rawQuestion['stepGui_extraDataType']) {
                        printQuestion.extraData = Util.getElementByIdWithQuery("extraDataType_" + rawQuestion.id, this.basicConfiguration).id;
                        printout.push(printQuestion);
                    }
                }
                // question from  rawquestionnaire -> title
            }
        }

        // look for questions, then look which of their answers are chosen
        // add question title and answer to object
        // check if there are questions with extra data, add them to the object (question title + extradata)
        // sort by order
        // create template (in Zendesk) to render them
    }

    async removeDialogFiles(dialogId, questionId, uploadedFilesId) {
        let promises = [];
        if (Array.isArray(uploadedFilesId)) {
            for(let oneFileId of uploadedFilesId ) {
                promises.push(this.knowledgeGuideServiceRestClient.removeDialogFile(dialogId, questionId, oneFileId));
            }
        }
        return await Promise.all(promises);
    }

    /**
     * if the users starts answering questions again, hide the results and change the finish button with the next button (maybe even questions get hidden again)
     */
    _backToAnsweringQuestions(p_questionId) {
        for (let l_result of Object.values(this.questionnaire.results)) {
            l_result.displayed = false;
            this._addDisplayedClassToElement(l_result.id, false);
        }
        this._addActiveClassToButton('btn_next', true);
        this._addActiveClassToButton('btn_finish', false);
        this._addAriaToButton('btn_finish', false);
        this._addDisplayedClassToElement('results_description', false);
    }

    _addListenersToInputElements() {
        let l_answerIds = this._getAnswerIds(false);
        let self = this;

        let l_listenerFunction = function (evt) {
            console.kc_log("the event from listener function: %o", evt);
            if (evt.target) {
                let l_target = evt.target;
                let l_answerId = l_target.id;
                let l_questionId = l_target.parentElement.getAttribute('questionId');

                if(!l_questionId) {
                    l_questionId = l_target.parentElement.parentElement.getAttribute('questionId');
                }
                
                let l_newQuestionnaireState = self.processStep(l_questionId, l_answerId, l_target.checked);

            }
        }

        let _listenerFunctionSelectElement = function (p_question, p_event) {
            let l_options = p_event.target.options;
            let l_index = l_options.selectedIndex;

            let l_answerId = l_options[l_index].value;
            if(l_answerId === '') {
                self.processStep(p_question.id, l_answerId, false)    
            } else {
                self.processStep(p_question.id, l_answerId, true)    
            }
        }

        // This adds listeners to all answers, that are not displayed as a drop down
        for (let id of l_answerIds) {
            let l_element = Util.getElementByIdWithQuery(id, this.basicConfiguration);
            if(l_element) {
                l_element.addEventListener('click', l_listenerFunction);
            }
        }

        // functions for multiple dropdown, when an answer is selected or removed from the selected answers
        let multipleSelectCallbackSelect = function(p_option, p_questionId) {
            self.processStep(p_questionId, p_option.value, true);
        }
        let multipleSelectCallbackUnselect = function(p_option, p_questionId) {
            self.processStep(p_questionId, p_option.value, false);
        }
        // add listener to dropdown or create a multipleselect dropdown.
        for(let question of this._getDropDownQuestions()) {
            if(question.answerMode === "multiple") {
                this.multipleSelects[question.id] = new MultipleSelect("answerDropDown_"+question.id, multipleSelectCallbackSelect, multipleSelectCallbackUnselect, question.id, this.basicConfiguration);
            } else {
                let dropDownElement =  Util.getElementByIdWithQuery("answerDropDown_" + question.id, this.basicConfiguration);
                if(dropDownElement) {
                    dropDownElement.addEventListener('change', _listenerFunctionSelectElement.bind(null, question), false);
                }
            }
        }
    }

    /**
     * add file upload listeners for questions
     */
    _addListenersToQuestionExtraData() {
        // make "this" accessible within function
        let self = this;
        /**
         * value change listener for file upload
         * @param {*} p_question question to which files should be attached
         * @param {*} p_questionnaire parent questionnaire
         * @param {*} p_knowledgeGuideServiceRestClient the knowledgeGuideServiceRestClient instance
         * @param {*} p_event fileUploadEvent
         */
        let _listenerFunctionUpload = function (p_question, p_event) {
            console.kc_log("Upload triggered for question with id %s of dialog with Id %s\n%o", p_question.id, self.questionnaire.dialogId, p_event);

            let fileUploadKey = self.questionnaire.dialogId + '-' +  p_question.id;
            if (self.uploadedFiles[fileUploadKey]) {
                self.removeDialogFiles(self.questionnaire.dialogId, p_question.id, self.uploadedFiles[fileUploadKey]);
            }
            self.uploadedFiles[fileUploadKey] = [];

            if(p_event.target.files.length > 0) {
                self.knowledgeGuideServiceRestClient.uploadDialogFile(self.questionnaire.dialogId, p_question.id, p_event.target.files).then(result => {
                    // add the list of returned file as property to the current question for the final processStep call
                    var questionProperties = [];
                    questionProperties.push(p_event.target.files);
                    let cnt = 0;
                    self._removeFileList(p_question);
                    for (let oneResult of result) {
                        let properties = {
                            ["=file[" + cnt + "].id"]: oneResult.id,
                            ["=file[" + cnt + "].name"]: oneResult.name,
                            ["=file[" + cnt + "].size"]: oneResult.size,
                        };
                        self.uploadedFiles[fileUploadKey].push(oneResult.id);
                        questionProperties.push({ properties });
                        cnt++;
                        self._addFileNamesToFilelist(p_question, oneResult.name);
                    }
                    let l_state = self.questionnaire.tryToAnswerExtraData(p_question.id, questionProperties, self.basicConfiguration.extradataFormats);
                    self._markExtraData(p_event.target, l_state, p_question.id, p_question.extraData.type)
                });
            } else {
                let l_state = self.questionnaire.tryToAnswerExtraData(p_question.id, [], self.basicConfiguration.extradataFormats);
                self._markExtraData(p_event.target, l_state, p_question.id, p_question.extraData.type)
            }
            self._callProcessStepAction();
        }

        /**
         * value change listener for input elements
         * @param {*} p_question 
         * @param {*} p_event 
         */
        let _listenerFunctionInputElement = function (p_question, p_event) {
            var questionProperties = [];

            let value = p_event.target.value;

            // KGuide2 needs the time with seconds
            if(p_question.extraData.type == 'Usu_ExtraStepGuiType_Datetime') {
                if(value && value.length == 16) {
                    value += ':00';
                }
            }

            let properties = { "=text": value };
            questionProperties.push({ properties });

            //p_question.extraDataAnswered = p_event.target.value.length > 0;
            let l_state = self.questionnaire.tryToAnswerExtraData(p_question.id, questionProperties, self.basicConfiguration.extradataFormats);
            //p_question.extraDataValueProperties = questionProperties;

            self._markExtraData(p_event.target, l_state, p_question.id, p_question.extraData.type)

            self._callProcessStepAction();
        }

        /**
         * value change listener for select elements
         * @param {*} p_question 
         * @param {*} p_event 
         */
        let _listenerFunctionSelectElement = function (p_question, p_event) {
            console.kc_log("Additional field value for select element %s of question %s changed to %s", p_question.extraData.type, p_question.id, p_event.target.value);

            let l_options = p_event.target.options;
            let l_index = l_options.selectedIndex;
            let l_state;

            //get selected option
            if (l_options && l_index && l_options.length > 0) {
                var questionProperties = [];
                let properties = {
                    ["=value.name"]: l_options[l_index].text,
                    ["=value.key"]: l_options[l_index].value
                };
                questionProperties.push({ properties });

                l_state = self.questionnaire.tryToAnswerExtraData(p_question.id, questionProperties, self.basicConfiguration.extradataFormats);
            } else {
                l_state = self.questionnaire.tryToAnswerExtraData(p_question.id, [], self.basicConfiguration.extradataFormats);
            }

            self._markExtraData(p_event.target, l_state, p_question.id, p_question.extraData.type)
            self._callProcessStepAction();
        }
        /**
         * add listeners for every question extra data element
         */
        for (let questionKey of Object.keys(this.questionnaire.questions)) {
            let question = this.questionnaire.questions[questionKey];
            if (question.hasExtraData) {
                /**
                 * add listeners
                 */
                switch (question.extraData.type) {
                    case 'Usu_ExtraStepGuiType_KBValueList':
                        console.kc_log("Adding select value change listener for questionKey %s", questionKey);
                        Util.getElementByIdWithQuery("extraDataDropdown_" + questionKey, this.basicConfiguration).addEventListener('change', _listenerFunctionSelectElement.bind(null, question), false);
                        break;
                    case 'Usu_ExtraStepGuiType_Upload':
                        console.kc_log("Adding file upload listener for questionKey %s", questionKey);
                        Util.getElementByIdWithQuery("extraDataUpload_" + questionKey, this.basicConfiguration).addEventListener('change', _listenerFunctionUpload.bind(null, question), false);
                        break;
                    case 'Usu_ExtraStepGuiType_Date':
                    case 'Usu_ExtraStepGuiType_Datetime':
                    case 'Usu_ExtraStepGuiType_TextArea':
                    case 'Usu_ExtraStepGuiType_Text':
                        Util.getElementByIdWithQuery("extraDataInput_" + questionKey, this.basicConfiguration).addEventListener('change', _listenerFunctionInputElement.bind(null, question), { passive: true });
                        break;
                }
            }
        }
    }

    _removeFileList(p_question) {
        let l_filelist = Util.getElementByIdWithQuery('extraDataType_' + p_question.id, this.basicConfiguration);
        let l_spans = l_filelist.querySelectorAll('span');
        for(let l_span of l_spans) {
            l_span.remove();
        }
    }

    _addFileNamesToFilelist(p_question, p_name) {
        let l_filelist = Util.getElementByIdWithQuery('extraDataType_' + p_question.id, this.basicConfiguration);
        let l_file = document.createElement('span');
        let l_name = p_name.split('/');
        l_file.innerHTML = l_name[l_name.length-1] + '<br>';
        l_filelist.append(l_file);
    }



    /**
     * this adds a listener to the finish button which is needed to send the filled questionnaire to the backend
     * @param {*} p_buttonId id of the html finish-button element
     * @param {*} p_additionalAction a function which additionally should be called before finish
     * @param {*} p_AfterFinishCallback a function which additionally should be called after finish
     */
    _addListenerToFinishButton(p_buttonId, p_additionalAction, p_AfterFinishCallback) {
        // make "this" accessible within function
        let self = this;

        let l_listenerFunction = function (evt) {
            if (p_additionalAction != undefined) {
                console.kc_log("Calling additionally defined finish button logic:\n%s", p_additionalAction);
                //custom function must return the questionnaire if manipulating should occcur
                //therefore we pass copy by value
                let changedQuestionnaire = p_additionalAction(self.questionnaire, self.getReadableQuestionnaireObject());
                if (changedQuestionnaire != undefined) {
                    self.questionnaire = changedQuestionnaire;
                }
            }
            if (self.basicConfiguration.sendRequests) {
                if(self.basicConfiguration.sendQuestionnaireToValueStore) {
                    self.knowledgeGuideServiceRestClient.sendQuestionnaireToValueStore(self.questionnaire, self.basicConfiguration.valueStoreURL, self.basicConfiguration.valueStoreSessionId, self.rawQuestionnaire);
                }
                
                let response = self.knowledgeGuideServiceRestClient.finishDialog(self.questionnaire, self.rawQuestionnaire.contentLang, SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE, self.rawQuestionnaire);
                response.then(data => {
                    console.log(data);
                    if(data.properties) {
                        self.questionnaire.properties = data.properties;
                    }
                }).catch(error => {
                    console.kc_log(error);
                })                
                .finally(() => {
                    if(p_AfterFinishCallback) {
                        p_AfterFinishCallback(self.questionnaire, self.getReadableQuestionnaireObject());
                    }
                });
            } else {
                if(p_AfterFinishCallback) {
                    p_AfterFinishCallback(self.questionnaire, self.getReadableQuestionnaireObject());
                }
            }
        }
        let button = Util.getElementByIdWithQuery(p_buttonId, this.basicConfiguration);
        if(this.basicConfiguration.showFinishButton) {
            button.addEventListener('click', l_listenerFunction);
        } else {
            button.style.display = 'none';
        }
        
    }

    _addListenerToNextButton(p_buttonId, p_additionalAction) {
        this._addActiveClassToButton(p_buttonId, true);

        let self = this;

        let l_listenerFunction = function (evt) {
            if (p_additionalAction != undefined) {
                console.kc_log("Calling additionally defined next button logic:\n%s", p_additionalAction);
                //custom function must return the questionnaire if manipulating should occcur
                //therefore we pass copy by value
                let callbackParameter = {
                    "status": self.determineDocumentState(),
                    "data": self.getReadableQuestionnaireObject()
                };
                let changedQuestionnaire = p_additionalAction(self.questionnaire, undefined, callbackParameter);
                if (changedQuestionnaire != undefined) {
                    self.questionnaire = changedQuestionnaire;
                }
                self._handleNextButtonClick();
            } else {
                self._handleNextButtonClick();
            }
        }
        Util.getElementByIdWithQuery(p_buttonId, this.basicConfiguration).addEventListener('click', l_listenerFunction);
    }

    _fillQuestionMandatoryNotAnsweredInfoHolder(p_unanswered, p_questionId) {
        let l_question = Util.getElementByIdWithQuery(p_questionId, this.basicConfiguration);
        if(l_question) {
            let l_holder = l_question.getElementsByClassName(this.basicConfiguration.questionMandatoryNotAnsweredInfoHolderClass)[0];
            if(l_holder) {
                if(p_unanswered) {
                    l_holder.innerHTML = this.basicConfiguration.getTranslatedLabel(this.rawQuestionnaire.contentLang, "q.label.unansweredMandatoryQuestion");
                } else 
                {
                    l_holder.innerHTML = "";
                }
            }
        }
    }

    _fillExtraDataMandatoryNotAnsweredInfoHolder(p_unanswered, p_questionId) {
        let l_question = Util.getElementByIdWithQuery(p_questionId, this.basicConfiguration);
        if(l_question) {
            let l_holder = l_question.getElementsByClassName(this.basicConfiguration.extraDataMandatoryNotAnsweredInfoHolderClass)[0];
            if(l_holder) {
                if(p_unanswered) {
                    l_holder.innerHTML = this.basicConfiguration.getTranslatedLabel(this.rawQuestionnaire.contentLang, "q.label.unansweredMandatoryExtraData");
                } else 
                {
                    l_holder.innerHTML = "";
                }
            }
        }
    }

    _removeMandatoryUnansweredQuestionClass(p_questionId) {
        let l_classList = Util.getElementByIdWithQuery(p_questionId, this.basicConfiguration).classList;
        if (l_classList.contains('mandatoryUnansweredQuestion')) {
            l_classList.remove('mandatoryUnansweredQuestion');
        }
    }
    /**
     * reset all input elements and reset questionnaire object
     * @param {*} p_buttonId 
     * @param {*} p_additionalAction 
     */
    _addListenerToResetButton() {
        let self = this;
        let l_listenerFunction = function (evt) {
            console.kc_log("Resetting input fields");
            //reset questionnaire
            self.knowledgeGuideServiceRestClient.resetDialog(self.questionnaire, self.rawQuestionnaire.contentLang);
            let dialogId = self.questionnaire.dialogId; // preserve dialogId
            self.questionnaire = self.questionnaireBuilder.parse();
            self.questionnaire.dialogId = dialogId;
            // iterate over input fields and reset TODO: scope this somehow..
            let elementsToReset = document.querySelectorAll('input,textarea');
            for (let i = 0, element; element = elementsToReset[i++];) {
                switch (element.type) {
                    case 'radio':
                    case 'checkbox': element.checked = false; break;
                    case 'button': /* do nothing */ break;
                    default: element.value = '';
                }
            }
            // KBOT-813: check here, if mutiple select dropdowns are reset
            // iterate over selects and set selection to first
            elementsToReset = document.getElementsByTagName('select');
            for (let i = 0, element; element = elementsToReset[i++];) {
                element.options.selectedIndex = 0;
            }

            for(let selectKey of Object.keys(self.multipleSelects)) {
                let select = self.multipleSelects[selectKey];
                select.unselectAll();
            }
            
            for (let questionKey of Object.keys(self.questionnaire.questions)) {
                let question = self.questionnaire.questions[questionKey];

                self._removeMandatoryUnansweredQuestionClass(question.id);
                self._fillQuestionMandatoryNotAnsweredInfoHolder(false, question.id);
                self._fillExtraDataMandatoryNotAnsweredInfoHolder(false, question.id);
            }

            let l_firstQuestion = self.questionnaire.getQuestionIdsOnPage(0);
            if(l_firstQuestion) {
                let l_element = Util.getElementByIdWithQuery(l_firstQuestion.id, self.basicConfiguration);
                if(l_element) {
                    l_firstQuestion.focus();
                }
            }
            self._showQuestions(0);
            self._backToAnsweringQuestions();
        }
        Util.getElementByIdWithQuery(this.basicConfiguration.resetButtonId, this.basicConfiguration).addEventListener('click', l_listenerFunction);
    }


    _showQuestions(p_questionOrder) {
        let l_questionIdsOnCurrentPage = this.questionnaire.getQuestionIdsOnPage(p_questionOrder);

        let l_finalQuestionOnPage = false;

        for (let i = 0; i < l_questionIdsOnCurrentPage.length; i++) {
            let l_question = this.questionnaire.questions[l_questionIdsOnCurrentPage[i]];
            if (l_question.canBeDisplayed && !l_finalQuestionOnPage) {
                l_question.displayed = true;
                this._addDisplayedClassToElement(l_question.id, true)

                // There is the posibility that questions are displayed, but a question, that is marked as final is before them
                // they should not be displayed then. For Context see KFIRST-917 
                l_finalQuestionOnPage = l_question.final;
            } else {
                l_question.displayed = false;
                this._addDisplayedClassToElement(l_question.id, false);
                if(l_question.isDropdown){ // dropdowns don't have answers that can be selected or unchecked.
                    if(l_question.answerMode === 'multiple') {
                        let select = this.multipleSelects[l_question.id];
                        if(select) {
                            select.unselectAll();
                        }
                    } else {
                        let dropDownElement = Util.getElementByIdWithQuery("answerDropDown_" + l_question.id, this.basicConfiguration);
                        if(dropDownElement) {
                            dropDownElement.selectedIndex = 0;
                        }
                    } 
                } else {
                    for (let answerKey of Object.keys(l_question.answers)) {
                        Util.getElementByIdWithQuery(l_question.answers[answerKey].id, this.basicConfiguration).checked = false;
                    }
                }
                if (!l_question.alwaysAnswered) {
                    l_question.answered = false;
                }
                if(l_question.hasExtraData)
                {
                    l_question.extraData.answered = false;
                    
                    let element = Util.getElementByIdWithQuery('extraDataInput_' + l_question.id, this.basicConfiguration);
                    if(element)
                    switch (element.type) {
                        case 'radio':
                        case 'checkbox': element.checked = false; break;
                        case 'button': /* do nothing */ break;
                        default: element.value = '';
                    }

                    l_question.extraData.valueProperties = '';
                }
            }
        }
        // questions on later pages are not shown if something changes on a given page.
        let l_lastQuestionIdOnCurrentPage = l_questionIdsOnCurrentPage[l_questionIdsOnCurrentPage.length - 1];
        for (let k = this.questionnaire.sortedQuestionIds.indexOf(l_lastQuestionIdOnCurrentPage) + 1; k < this.questionnaire.sortedQuestionIds.length; k++) {
            let l_question = this.questionnaire.questions[this.questionnaire.sortedQuestionIds[k]];
            l_question.displayed = false;
            this._addDisplayedClassToElement(l_question.id, false);
            for (let answerKey of Object.keys(l_question.answers)) {
                let answerElement = Util.getElementByIdWithQuery(l_question.answers[answerKey].id, this.basicConfiguration);
                if(answerElement) {
                    answerElement.checked = false;
                }
            }
            if (!l_question.alwaysAnswered) {
                l_question.answered = false;
            }
            let l_element = Util.getElementByIdWithQuery(l_question.id, this.basicConfiguration);
            if (l_element.classList.contains('previousPage')) {
                l_element.classList.remove('previousPage');
            }
            if (!l_question.alwaysAnswered) {
                l_question.previousPage = false;
            }
        }

        // a page is displayed again -> remove classes from these questions
        for (let j = 0; j < l_questionIdsOnCurrentPage.length; j++) {
            let l_element = Util.getElementByIdWithQuery(l_questionIdsOnCurrentPage[j], this.basicConfiguration);
            if (l_element.classList.contains('previousPage')) {
                l_element.classList.remove('previousPage');
            }
            this.questionnaire.questions[l_questionIdsOnCurrentPage[j]].previousPage = false;
        }
    }

    _checkIfQuestionCanBeDisplayed(p_question) {
        if ((!p_question.firstOnPage) && p_question.canBeDisplayed) // canBeDisplayed is true, if the question has no conditions
        {
            return true;
        } else {
            return false;
        }
    }

    _findNextQuestionByOrder(p_order) {
        for (let questionKey of Object.keys(this.questionnaire.questions)) {
            let question = this.questionnaire.questions[questionKey];
            if (question.order === (p_order + 1)) {
                return question.id
            }
        }
        return 0;
    }

    _addDisplayedClassToElement(p_elementId, p_display) {
        let elementClassList = Util.getElementByIdWithQuery(p_elementId, this.basicConfiguration);
        if(elementClassList) {
            elementClassList = elementClassList.classList;
            
            let classToAdd = p_display ? 'displayed' : 'notDisplayed';
            let classToRemove = p_display ? 'notDisplayed' : 'displayed';
    
            elementClassList.add(classToAdd);
            if (elementClassList.contains(classToRemove)) {
                elementClassList.remove(classToRemove);
            }
        }

    }

    _addAriaToButton(p_elementId, p_active) {
        let element = Util.getElementByIdWithQuery(p_elementId, this.basicConfiguration);

        if(element) {
            p_active ? element.setAttribute('aria-descibedby', 'btn_finish_description results_description') : element.setAttribute('aria-descibedby', 'btn_finish_description') ;
        }
    }

    _addActiveClassToButton(p_buttonId, p_active) {
        let element = Util.getElementByIdWithQuery(p_buttonId, this.basicConfiguration);
        if(element) {
            let buttonClassList = element.classList;

            let classToAdd = p_active ? 'btn_active' : 'btn_inactive';
            let classToRemove = p_active ? 'btn_inactive' : 'btn_active';
    
            buttonClassList.add(classToAdd);
            if (buttonClassList.contains(classToRemove)) {
                buttonClassList.remove(classToRemove);
            }
        }
    }

    /**
     * returns the answerIds of all answers or only the answers for questions, that are not displayed as a dropdown.
     */
    _getAnswerIds(p_all) {
        let l_answerIds = [];
        if(this.rawQuestionnaire.questions)
        {
            for (let question of this.rawQuestionnaire.questions) {
            if (question.answers)
                for (let answer of question.answers) {
                    if(p_all || question.stepGui_answerLook !== "dropDown") {
                        l_answerIds.push(answer.id)
                    }
                }
            }
        }
        return l_answerIds;
    }

    /**
     * returns questions, that are displayed as dropdowns
     */
    _getDropDownQuestions() {
        let l_questions = [];
        if(this.rawQuestionnaire.questions) {
            for(let question of this.rawQuestionnaire.questions)
            {
                if(question.stepGui_answerLook === "dropDown") {
                    l_questions.push(question)
                }
            }
        }

        return l_questions;
    }

    /**
     * call backend for value list of dropdowns in extra data field
     */
    _addOptionsToSelect() {
        for (let questionKey of Object.keys(this.questionnaire.questions)) {
            let question = this.questionnaire.questions[questionKey];
            if (question.hasExtraData) {
                if (question.extraData.type === 'Usu_ExtraStepGuiType_KBValueList') {
                    console.kc_log("Adding extra data options for questionKey %s", questionKey);
                    this.documentServiceRestClient.getValueList(question.extraData.valueList, this.rawQuestionnaire.mandatorkey, this.rawQuestionnaire.contentLang).then(result => {
                        let l_dropdown = Util.getElementByIdWithQuery("extraDataDropdown_" + questionKey, this.basicConfiguration);
                        if (l_dropdown) {
                            let l_emptyOption = document.createElement("OPTION");
                            l_emptyOption.value = "";
                            l_emptyOption.text = "";
                            l_dropdown.appendChild(l_emptyOption)
                            for (let valueListOption of result.entries) {
                                let l_option = document.createElement("OPTION");
                                l_option.value = valueListOption.key;
                                l_option.text = valueListOption.value;
                                l_dropdown.appendChild(l_option);
                            }
                        }
                    });
                }
            }
        }
    }
    /**
     * handles click on the next button
     * -> check if the next page is available
     *      - all questions with answermode single, that can currently be displayed (before pagebreak) must be answered
     * -> if page is available add classes (e.g. "oldPage") to the already answered questions and display question on next page
     * -> if page is not available add classes (e.g. "mandatoryNotAnswered") to the questions that are not answered
     * -> check if there are more questions to be displayed or if the result should be displayed
     *      - if the result is displayed, hide the next button and display the finish button
     */
    _handleNextButtonClick() {
        let l_currentAnsweredQuestionObject = this.questionnaire.getQuestionByOrder(this.questionnaire.currentAnsweredQuestion);
        let l_questionsOnPage;

        if (l_currentAnsweredQuestionObject && l_currentAnsweredQuestionObject.previousPage) {
            l_questionsOnPage = this.questionnaire.getQuestionIdsOnPage(this.questionnaire.currentQuestionToAnswer);
        } else {
            l_questionsOnPage = this.questionnaire.getQuestionIdsOnPage(this.questionnaire.currentAnsweredQuestion);
        }

        let l_unansweredQuestion = false;
        let l_firstUnansweredQuestion;
        let l_finishQuestionnaireAfterQuestion = false;
        for (let i = 0; i < l_questionsOnPage.length; i++) {
            let l_question = this.questionnaire.questions[l_questionsOnPage[i]];
            
            if(l_question.canBeDisplayed)
            {
                // 4 cases here:
                // answerMode = single && extradata not mandatory/no extradata => question.answered
                // answerMode = single && extradata mandatory => question.answered && question.extraDataAnswered
                // answerMode = multiple && extradata mandatory => question.extraDataAnswered
                // answerMode = multiple && extradata not mandatory => doesn't matter
                if(l_question.answerMode === 'single')
                {
                    if(l_question.extraData.mandatory)
                    {
                        if(!l_question.answered || !l_question.extraData.answered)
                        {
                            l_unansweredQuestion = true;
                            if(!l_firstUnansweredQuestion) {
                                l_firstUnansweredQuestion = l_question;
                            }
                            Util.getElementByIdWithQuery(l_question.id, this.basicConfiguration).classList.add('mandatoryUnansweredQuestion');
                            this._fillQuestionMandatoryNotAnsweredInfoHolder(true, l_question.id);
                            this._fillExtraDataMandatoryNotAnsweredInfoHolder(true, l_question.id);
                        }
                        else
                        {
                            this._removeMandatoryUnansweredQuestionClass(l_question.id);
                            this._fillQuestionMandatoryNotAnsweredInfoHolder(false, l_question.id);
                            this._fillExtraDataMandatoryNotAnsweredInfoHolder(false, l_question.id);
                        }
                    } else
                    {
                        if(!l_question.answered )
                        {
                            l_unansweredQuestion = true;
                            if(!l_firstUnansweredQuestion) {
                                l_firstUnansweredQuestion = l_question;
                            }
                            Util.getElementByIdWithQuery(l_question.id, this.basicConfiguration).classList.add('mandatoryUnansweredQuestion');
                            this._fillQuestionMandatoryNotAnsweredInfoHolder(true, l_question.id);
                            this._fillExtraDataMandatoryNotAnsweredInfoHolder(true, l_question.id);
                        } else
                        {
                            this._removeMandatoryUnansweredQuestionClass(l_question.id);
                            this._fillQuestionMandatoryNotAnsweredInfoHolder(false, l_question.id);
                            this._fillExtraDataMandatoryNotAnsweredInfoHolder(false, l_question.id);
                        }
                    }
                } else 
                {
                    if(l_question.extraData.mandatory)
                    {
                        if(!l_question.extraData.answered)
                        {
                            l_unansweredQuestion = true;
                            if(!l_firstUnansweredQuestion) {
                                l_firstUnansweredQuestion = l_question;
                            }
                            Util.getElementByIdWithQuery(l_question.id, this.basicConfiguration).classList.add('mandatoryUnansweredQuestion');
                            this._fillQuestionMandatoryNotAnsweredInfoHolder(true, l_question.id);
                        } else
                        {
                            this._removeMandatoryUnansweredQuestionClass(l_question.id);
                            this._fillQuestionMandatoryNotAnsweredInfoHolder(false, l_question.id);
                        }
                    }
                }

                // check here if there is a question displayed on this page, that is final
                if(l_question.final && !l_unansweredQuestion)
                {
                    // check here if question is final and if yes,display the results.
                    this.questionnaire.state = DOCUMENT_STATES.COMPLETED;
                    this._showResults();
                    this._addActiveClassToButton('btn_next', false);
                    this._addActiveClassToButton('btn_finish', true);
                    this._addAriaToButton('btn_finish', true);
                    this._addDisplayedClassToElement('results_description', true);
                    return;
                }
            }
        }
        if (l_unansweredQuestion) {
            let l_element = Util.getElementByIdWithQuery(l_firstUnansweredQuestion.id, this.basicConfiguration);
            if(l_element) {
                l_element.focus();
            }
            return; // can't go to the next page or display result, because there are questions that have to be answered first.
        }



        // next page can be displayed -> add classes to other questions before this.
        for (let i = 0; i < l_questionsOnPage.length; i++) {
            Util.getElementByIdWithQuery(l_questionsOnPage[i], this.basicConfiguration).classList.add('previousPage');
            this.questionnaire.questions[l_questionsOnPage[i]].previousPage = true;

            this._removeMandatoryUnansweredQuestionClass(l_questionsOnPage[i]);
            this._fillQuestionMandatoryNotAnsweredInfoHolder(false, l_questionsOnPage[i].id);
            this._fillExtraDataMandatoryNotAnsweredInfoHolder(false, l_questionsOnPage[i].id);
        }
            this.questionnaire.setCurrentQuestionToAnswer();
        if (this.questionnaire.currentQuestionToAnswer > 0) {
            this._showQuestions(this.questionnaire.currentQuestionToAnswer);
        } else {
            this.questionnaire.state = DOCUMENT_STATES.COMPLETED;
            this._showResults();
            this._addActiveClassToButton('btn_next', false);
            this._addActiveClassToButton('btn_finish', true);
            this._addAriaToButton('btn_finish', true);
            this._addDisplayedClassToElement('results_description', true);
        }
    }
    /**  
        The following criteria are applied when showing results:
            1) only one result can be shown.
            2) a result with conditions (that are met) is shown before a result without conditions disregarding the order
            3) if more than one result can be shown the one with the lower order is shown 
    */
    _showResults() {
        let l_results = Object.values(this.questionnaire.results);

        l_results = l_results.filter(result => result.canBeDisplayed);
        l_results = l_results.some(result => result.hasConditions) ? l_results.filter(result => result.hasConditions) : l_results;
        let l_resultToShow = l_results[0];
        for (let l_result of l_results) {
            if (l_result.order < l_resultToShow.order) {
                l_resultToShow = l_result;
            }
        }
        if(l_resultToShow)
        {    
            l_resultToShow.displayed = true;
            this._addDisplayedClassToElement(l_resultToShow.id, true);
        }

        if(this.basicConfiguration.showResultsCallback) {
            this.basicConfiguration.showResultsCallback(this.questionnaire);
        }
    }

    _markQuestionsAsMandatory() {
        for (let questionKey of Object.keys(this.questionnaire.questions)) {
            let question = this.questionnaire.questions[questionKey];
            if(question.answerMode === "single" && question.answers && Object.keys(question.answers).length > 0) {
                Util.getElementByIdWithQuery(question.id, this.basicConfiguration).classList.add("mandatory");
            }
            if(question.extraData.mandatory) {
                Util.getElementByIdWithQuery(question.id, this.basicConfiguration).classList.add("extradata_mandatory");
            }
        }
    }

    // wrapper to have the same function in every engine, 
    // getReadableQuestionnaireObject() was already used from outside of Docrenderer, so we don't want to rename this.
    getReadableData() {
        return this.getReadableQuestionnaireObject();
    }

    getReadableQuestionnaireObject()
    {
        let l_readableQuestionnaireObject = {
            'id':this.questionnaire.id,
            'guid':this.questionnaire.guid,
            'dialogId':this.questionnaire.dialogId
        }

        l_readableQuestionnaireObject.questions = [];
        for(let questionsKey of Object.keys(this.questionnaire.questions))
        {
            let l_question = this.questionnaire.questions[questionsKey];

            if(l_question.answerMode === 'single') {
                if(l_question.answered) // this is only available on answers with answermode single, because only they are 
                {
                    l_readableQuestionnaireObject.questions.push(this._createReadableQuestion(l_question));
                }
            } else {
                let l_questionAnswered = false;
                for(let answerKey of Object.keys(l_question.answers)) {
                    let l_answer = l_question.answers[answerKey];
                    if(l_answer.chosen) {
                        l_questionAnswered = true;
                    }
                }
                if(l_questionAnswered || ( l_question.hasExtraData && l_question.extraData.answered) ) {
                    l_readableQuestionnaireObject.questions.push(this._createReadableQuestion(l_question));
                }
            }
        }

        return l_readableQuestionnaireObject;
    }

    _createReadableQuestion(p_question)
    {
        let l_rawQuestion = this._getRawQuestion(p_question.id);

        let l_readableQuestion = {};

        l_readableQuestion['id'] = p_question.id;
        l_readableQuestion['answerMode'] = p_question.answerMode;
        l_readableQuestion['hasExtraData'] = p_question.hasExtraData;
        l_readableQuestion['extraDataValueProperties'] = p_question.extraData.valueProperties;
        l_readableQuestion['title'] = l_rawQuestion.title;
        l_readableQuestion['content'] = l_rawQuestion.content;
        l_readableQuestion['sid'] = l_rawQuestion.sid;
        l_readableQuestion['answers'] = [];

        for(let answerKey of Object.keys(p_question.answers))
        {
            let l_answer = p_question.answers[answerKey];

            if(l_answer.chosen)
            {
                l_readableQuestion.answers.push(this._createReadableAnswer(l_answer, l_rawQuestion));
            }
        }

        return l_readableQuestion;
    }

    _createReadableAnswer(p_answer, p_rawQuestion)
    {
        let l_rawAnswer = this._getRawAnswerFromQuestion(p_answer.id, p_rawQuestion);
        let l_readableAnswer = {};

        l_readableAnswer['id'] = p_answer.id;
        l_readableAnswer['title'] = l_rawAnswer.title;
        l_readableAnswer['content'] = l_rawAnswer.content;
        
        return l_readableAnswer;
    }

    _getRawQuestion(p_questionId)
    {
        return Object.values(this.rawQuestionnaire.questions).find(element => element.id === p_questionId);
    }

    _getRawAnswerFromQuestion(p_answerId, p_rawQuestion)
    {
        return Object.values(p_rawQuestion.answers).find(element => element.id === p_answerId);
    }

    determineDocumentState() {
        return this.questionnaire.state;
    }

    _callProcessStepAction() {
        if(this.basicConfiguration.processStepAction) {
            let callbackParameter = {
                "status": this.determineDocumentState(),
                "data": this.getReadableQuestionnaireObject()
            };
            this.basicConfiguration.processStepAction(callbackParameter);
        }
    }

    getDocumentInfo() {
        return {
            'status': this.determineDocumentState(),
            'data': this.getReadableData()
        };
    }

    finishDocument() {
        if (this.basicConfiguration.sendRequests) {
            this.knowledgeGuideServiceRestClient.finishDialog(this.questionnaire, this.rawQuestionnaire.contentLang, SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE, this.rawQuestionnaire);
        }
    }

    _markExtraData(p_extraDataElement, p_state, p_questionId, p_extraDataType) {
        if(p_extraDataElement.classList.contains(EXTRA_DATA_STATE.UNTOUCHED)) {
            p_extraDataElement.classList.remove(EXTRA_DATA_STATE.UNTOUCHED);
        }
        if(p_extraDataElement.classList.contains(EXTRA_DATA_STATE.CORRECT)) {
            p_extraDataElement.classList.remove(EXTRA_DATA_STATE.CORRECT);
        }
        if(p_extraDataElement.classList.contains(EXTRA_DATA_STATE.WRONG)) {
            p_extraDataElement.classList.remove(EXTRA_DATA_STATE.WRONG);
        }
        p_extraDataElement.classList.add(p_state);

        let l_questionElement = Util.getElementByIdWithQuery(p_questionId, this.basicConfiguration);
        if(l_questionElement) {
            if(p_state === EXTRA_DATA_STATE.WRONG) {
                let l_extraDataFormatExpectationNotMetHolder = l_questionElement.getElementsByClassName(this.basicConfiguration.extraDataFormatExpectationNotMetClass)[0];
                l_extraDataFormatExpectationNotMetHolder.innerHTML = this.basicConfiguration.getTranslatedLabel(this.rawQuestionnaire.contentLang, "q.label.expectedExtraDataFormatsNotMet."+p_extraDataType);
            }
        }
    }
}