export class Result {
    constructor(p_id, p_order, p_conditions)
    {
        this.id = p_id;
        this.displayed = false;
        this.order = p_order;
        this.hasConditions = p_conditions.length>0?true:false;
        this.canBeDisplayed = !this.hasConditions;
        this.conditions = p_conditions;
    }
}