export class BasicRestClient {

    constructor(p_configuration) {
        this.configuration = p_configuration;
    }

    /**
     * creates a url to the backend an sets url params
     * additionally the authenticationURLFragement is always set as first argument
     * 
     * @param {*} p_serviceUrl url of the backend service 
     * @param {*} p_operation operation name
     * @param {*} p_paramMap list of url parameters in terms of key=value
     */
    createServiceURL(p_serviceUrl, p_operation, p_paramMap) {
        let serviceURL = p_serviceUrl + p_operation;

        if(this.configuration.authenticationURLFragment && this.configuration.authenticationURLFragment.length > 0) {
            serviceURL += "?" + this.configuration.authenticationURLFragment;
        }

        if (p_paramMap != undefined) {
            Object.keys(p_paramMap).forEach(function (key) {
                if(serviceURL.indexOf('?') < 0) {
                    serviceURL += "?";
                } else {
                    serviceURL += "&";
                }
                serviceURL += key + "=" + p_paramMap[key];
            });
        }

        return serviceURL;
    }

    createHeaders(p_headers) {
        let l_headers = {};
        if(p_headers) {
            l_headers = p_headers;
        }
        if (this.configuration.getClientJWT() != undefined) {
            if (this.configuration.jwtUseAuthorizationHeader) {
                l_headers['Authorization'] = 'Bearer ' + this.configuration.getClientJWT();
            }
            else {
                l_getDocumentURL = this.configuration.addClientJWTAsURLParam(l_getDocumentURL);
            }
        }

        let csrfHeader = this.configuration.getCsrfToken();
        if(csrfHeader) {
            l_headers['GK-CSRF-Token'] = csrfHeader;
        }

        let defaultHeaders = this.configuration.getDefaultHeaders();
        if(defaultHeaders) {
            Object.keys(defaultHeaders).forEach(function (key) {
                l_headers[key] = defaultHeaders[key];
            });
        }

        return l_headers;
    }
}