import { ErrorPattern } from "./errorPattern";
import { ErrorPatternNode } from "./errorPatternNode";
import { Answer } from "./answer";



export class ErrorPatternBuilder {
    constructor(p_rawErrorPattern) {
        this.rawErrorPattern = p_rawErrorPattern;
        this.errorPattern = '';
    }

    parse() {
        return this._parseRawErrorPattern(this.rawErrorPattern);
    }

    /**
     * parse the json of the errorpattern
     * @param {*} p_rawErrorPattern 
     */
    _parseRawErrorPattern(p_rawErrorPattern) {
        let l_id = p_rawErrorPattern['id'];
        let l_guid = p_rawErrorPattern['guid'];
        let l_mandatorKey = p_rawErrorPattern['mandatorkey'];
        let l_showSolutions = p_rawErrorPattern['customFields']['showSolutions'];
        let l_masterLang = p_rawErrorPattern['masterLang'];
        let l_contentLang = p_rawErrorPattern['contentLang'];
        let l_content = p_rawErrorPattern['content'];
        let l_showTOC = 'true' === p_rawErrorPattern['showTOC'];
        let l_jumpDiagnoses = p_rawErrorPattern['customFields']['jumpDiagnoses'];
        //create basic object
        this.errorPattern = new ErrorPattern(l_id, l_guid, l_showSolutions, l_masterLang, l_contentLang, l_content, l_showTOC, l_mandatorKey);

        // create dependency graph
        let l_references = this._parseNodeDetail(p_rawErrorPattern.references);
        let l_solutions = this._parseNodeDetail(p_rawErrorPattern.solutions);
        let l_questions = this._parseQuestions(p_rawErrorPattern.questions);
        let l_nodes = this._parseNodes(p_rawErrorPattern, l_references, l_solutions, l_questions, l_jumpDiagnoses);

        this.errorPattern.nodes = l_nodes;
        this.errorPattern.questions = l_questions;
        this.errorPattern.solutions = l_solutions;
        this.errorPattern.references = l_references;
        this.errorPattern.jumpDiagnoses = l_jumpDiagnoses;

        this._addFallbackEmptyNode();
        console.kc_log("Parsed raw document \n%o\nto error pattern\n%o", this.errorPattern, p_rawErrorPattern);
        this._computeLooseSolutions(l_questions, l_solutions, l_references, l_nodes);

        return this.errorPattern;
    }

    // the default fallback node is a solution with an empty content
    _addFallbackEmptyNode() {
        this.errorPattern.nodes[ErrorPattern.getDefaultNodeId()] = new ErrorPatternNode(ErrorPattern.getDefaultNodeId(), 'solution', ErrorPattern.getDefaultNodeId());
    }
    /**
     * parse the graph-nodes of the errorPattern and the referencing elements (references, questions, solutions)
     * @param {*} p_rawErrorPattern 
     * @param {*} p_references 
     * @param {*} p_solutions 
     * @param {*} p_questions 
     */
    _parseNodes(p_rawErrorPattern, p_references, p_solutions, p_questions, p_jumpDiagnoses) {
        let l_nodes = {};
        if (p_rawErrorPattern.graph)
            for (let node of p_rawErrorPattern.graph) {
                let l_currentNode = new ErrorPatternNode(node.nodeId, node.type, node.docId);
                let l_nodeDetail = '';
                switch (l_currentNode.type) {
                    case 'reference':
                        {
                            if( p_references && p_references[l_currentNode.docId])
                            {
                                l_nodeDetail = p_references[l_currentNode.docId];
                                p_references[l_currentNode.docId].nodeId = l_currentNode.nodeId;
                                p_references[l_currentNode.docId].isJumpDiagnose = this._isJumpDiagnose(l_currentNode.docId, p_jumpDiagnoses);
                                if (node.targets) {
                                    for (let target of node.targets) {
                                        l_currentNode.answers.push(new Answer(target.answerId, '', '', '', target.nodeId));
                                    }
                                }
                            }
                            else
                            {
                                l_nodeDetail = {};
                                console.kc_log("No references");
                            }
                            break;
                        }
                    case 'solution': {
                        if( p_solutions && p_solutions[l_currentNode.docId])
                        {
                            l_nodeDetail = p_solutions[l_currentNode.docId];
                            p_solutions[l_currentNode.docId].nodeId = l_currentNode.nodeId;
                            p_solutions[l_currentNode.docId].isLooseSolution = true;
                        }
                        else
                        {
                            l_nodeDetail = {};
                            console.kc_log("No solutions");
                        }
                        break;
                    }
                    case 'question':
                        {
                            if( p_questions && p_questions[l_currentNode.docId])
                            {
                                l_nodeDetail = p_questions[l_currentNode.docId];

                                if(l_nodeDetail.isUsed == true) {
                                    l_nodeDetail = JSON.parse(JSON.stringify(l_nodeDetail));

                                    // Remove cloned nodeId references
                                    for(let answerId in l_nodeDetail.answers) {
                                        l_nodeDetail.answers[answerId].nodeId = [];
                                    }

                                    p_questions[l_currentNode.docId + ' ' + node.id] = l_nodeDetail;
                                }
                                l_nodeDetail.isUsed = true;                                

                                l_currentNode.answers = l_nodeDetail.answers;
                                // add nodeId reference
                                if (node.targets) {
                                    for (let target of node.targets) {
                                        l_currentNode.answers[target.answerId].nodeId.push(target.nodeId);
                                    }
                                }
                            }
                            else
                            {
                                l_nodeDetail = {};
                                console.kc_log("No questions");
                            }
                            break;
                        }
                    case 'automatic_question':
                        {
                            l_nodeDetail = this._handleAutomaticQuestionNode(p_questions, l_currentNode, l_nodeDetail, node);
                            break;
                        }
                    default:
                        {
                            l_nodeDetail = this._handleAutomaticQuestionNode(p_questions, l_currentNode, l_nodeDetail, node);
                        }
                }

                if (node.targets) {
                    for (let target of node.targets) {
                        this.errorPattern.targetNodesToPreviousNodes[target.nodeId] = node.nodeId;
                    }
                }

                //map details
                l_currentNode.title = l_nodeDetail.title;
                l_currentNode.content = l_nodeDetail.content;
                l_currentNode.summary = l_nodeDetail.summary;
                l_currentNode.contentLang = l_nodeDetail.contentLang;
                l_currentNode.showTOC = l_nodeDetail.showTOC;
                l_currentNode.componentType = l_nodeDetail.componentType;
                l_currentNode.guid = l_nodeDetail.guid;
                l_currentNode.mandatorKey = l_nodeDetail.mandatorKey;
                l_currentNode.customFields = l_nodeDetail.customFields;

                //check for entry node            
                l_currentNode.isEntry = node.isEntry;

                if (l_currentNode.isEntry) {
                    this.errorPattern.entryNodeId = l_currentNode.nodeId;
                }

                l_nodes[l_currentNode.nodeId] = l_currentNode;
            }
        return l_nodes;
    }

    _handleAutomaticQuestionNode(p_questions, l_currentNode, l_nodeDetail, node) {
        if (p_questions && p_questions[l_currentNode.docId]) {
            l_nodeDetail = p_questions[l_currentNode.docId];
            l_currentNode.answers = l_nodeDetail.answers;
            l_currentNode.data_key = l_nodeDetail.data_key;
            
            // add nodeId reference
            if (node.targets) {
                for (let target of node.targets) {
                    l_currentNode.answers[target.answerId].nodeId = target.nodeId;
                }
            }
        }
        else {
            l_nodeDetail = {};
            console.kc_log("No questions");
        }
        return l_nodeDetail;
    }

    /**
     * parse details for solutions and references
     * @param {*} p_refs 
     */
    _parseNodeDetail(p_refs) {
        let l_references = {};
        if (p_refs) {            
            for (let reference of p_refs) {
                l_references[reference.id] = {
                    "id": reference.id,
                    "title": reference.title,
                    "content": reference.content,
                    "contentLang": reference.contentLang,
                    "showTOC": reference.showTOC,
                    "componentType": reference.componentType,
                    "guid": reference.guid,
                    "mandatorKey": reference.mandatorkey,
                    "path": reference.path,
                    "summary": reference.summary,
                    "customFields": reference.customFields
                };
            }            
        }
        return l_references;
    }

    /**
     * parse questions and corresponding answers
     * @param {*} p_questions 
     */
    _parseQuestions(p_questions) {
        let l_questions = {};
        if (p_questions)
            for (let question of p_questions) {
                let l_answers = {};
                if( question.answers ) 
                {
                    for (let answer of question.answers) {
                        l_answers[answer.id] = new Answer(answer.id, answer.title, answer.order, answer.label);
                    }
                }
                l_questions[question.id] = {
                    "id": question.id,
                    "title": question.title,
                    "content": question.content,
                    "summary": question.summary,
                    "componentType": question.componentType,
                    "isEntry": question.isEntry,
                    "answers": l_answers
                };

                if (question.answers_key) {
                    l_questions[question.id]['answers_key'] = question.answers_key;
                }

                if (question.data_key) {
                    l_questions[question.id]['data_key'] = question.data_key;
                }

            }
        return l_questions;
    }

    /**
     * Compute the loose solutions based on linked answers of questions and linked targets of references
     * @param {*} p_questions 
     * @param {*} p_solutions 
     * @param {*} p_references 
     * @param {*} p_nodes 
     */
    _computeLooseSolutions(p_questions, p_solutions, p_references, p_nodes) {
        if (p_questions) {
            Object.keys(p_questions).forEach(function (qKey) {
                let l_questionNode = p_questions[qKey];
                Object.keys(l_questionNode.answers).forEach(function (qaKey) {
                    let l_currentAnswer = l_questionNode.answers[qaKey];
                    for(let l_nodeId of l_currentAnswer.nodeId)
                    {
                        let l_answerNode = p_nodes[l_nodeId];
                        if (l_answerNode) {
                            if (p_solutions[l_answerNode.docId]) {
                                p_solutions[l_answerNode.docId].isLooseSolution = false;
                            }
                        }
                    }
                });
            });
        }
        if (p_references) {
            Object.keys(p_references).forEach(function (rKey) {
                let l_referenceNode = p_nodes[p_references[rKey].nodeId];
                if (l_referenceNode && l_referenceNode.answers) {
                    Object.keys(l_referenceNode.answers).forEach(function (raKey) {
                        let l_currentAnswer = l_referenceNode.answers[raKey];
                        for(let l_nodeId of l_currentAnswer.nodeId)
                        {
                            let l_answerNode = p_nodes[l_nodeId];
                            if (l_answerNode) {
                                if (p_solutions[l_answerNode.docId]) {
                                    p_solutions[l_answerNode.docId].isLooseSolution = false;
                                }
                            }
                        }
                    });
                }
            });
        }
    }

    _isJumpDiagnose(p_docId, p_jumpDiagnoses) {

        if (p_jumpDiagnoses) {
            for (let l_jumpDiagnose of p_jumpDiagnoses)
                if (l_jumpDiagnose == p_docId) {
                    return true;
                }
        }
        return false;
    }
}