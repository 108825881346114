import { Conditional } from "./conditional";
import { Question } from "./question";
import { Answer } from "../shared/answer";
import { Result } from "../shared/result";
import { PrimitiveAnswer } from "../shared/primitiveAnswer";
import { ComplexAnswer } from "../shared/complexAnswer";
import { PrimitiveValue } from "../shared/primitiveValue";

export class ConditionalBuilder {
    constructor(p_rawConditional, p_basicConfiguration)
    {
        this.rawConditional = p_rawConditional;
        this.basicConfiguration = p_basicConfiguration;
    }

    parse() {
        let l_id = this.rawConditional['id'];
        let l_guid = this.rawConditional['guid'];
        let l_questions = this._parseQuestions();
        let l_results = this._parseResults();

        return new Conditional(l_id, l_guid, l_questions, l_results);
    }

    _parseQuestions() {
        let l_questions = {};
        if(this.rawConditional['questions'])
        {
            for (let question of this.rawConditional['questions']) {
                // Do no show OrgUnit questions at the moment (Not needed in SL)
                if(question.stepGui_extraDataType == 'Usu_ExtraStepGuiType_OrganizationUnit') {
                    question.visible = false;
                    continue;
                } else {
                    question.visible = true;
                }
                
                l_questions[question.id] = new Question(
                    question.id,
                    question.answerMode,
                    this._parseAnwers(question),
                    this._parseQuestionConditionsFromRawConditional(question)
                );
            }
        }
        return l_questions;
    }

    _parseAnwers(p_question) {
        let l_answers = {};

        if(p_question.answers) {
            for(let answer of p_question.answers) {
                l_answers[answer.id] = new Answer( answer.id, false);
            }
        }
        return l_answers
    }

    _parseQuestionConditionsFromRawConditional(p_question) {
        let l_conditions = [];
        if (p_question.conditions) {
            if (p_question.conditions.componentType) {
                if (p_question.conditions.componentType === "primitiveAnswer") {
                    l_conditions.push(new PrimitiveAnswer(p_question.id, p_question.conditions.referencedAnswer));
                } else {
                    l_conditions.push(new ComplexAnswer(p_question, p_question.conditions.operator, p_question.conditions.components));
                }
            } else {
                for (let condition of p_question.conditions) {
                    if (condition.componentType === "primitiveAnswer") {
                        l_conditions.push(new PrimitiveAnswer(p_question.id, condition.referencedAnswer));
                    } else {
                        l_conditions.push(new ComplexAnswer(p_question, condition.operator, condition.components));
                    }
                }
            }
        }
        return l_conditions;
    }

    _parseResults() {
        let l_results = {};
        if(this.rawConditional.results) {
            for( let result of this.rawConditional.results) {
                l_results[result.id] = new Result(
                    result.id,
                    result.order,
                    this._parseResultConditions(result)
                );
            }
        }
        return l_results;
    }

    _parseResultConditions(p_result) {
        let l_conditions = [];
        if(p_result.conditions)
        { 
           
            if (p_result.conditions.componentType) {
                if (p_result.conditions.componentType === "primitiveAnswer") {
                    l_conditions.push(new PrimitiveAnswer(p_result.id, p_result.conditions.referencedAnswer));
                } else if (p_result.conditions.componentType === "primitiveValue") {
                    l_conditions.push(new PrimitiveValue(p_result.id, p_result.conditions.referencedQuestion, p_result.conditions.expectedValue, this.basicConfiguration.userOrgUnitGuids));
                } else {
                    l_conditions.push(new ComplexAnswer(p_result, p_result.conditions.operator, p_result.conditions.components, this.basicConfiguration.userOrgUnitGuids));
                }
            } else {
                for (let condition of p_result.conditions) {
                    if (condition.componentType === "primitiveAnswer") {
                        l_conditions.push(new PrimitiveAnswer(condition.id, condition.referencedAnswer));
                    } else if (p_result.conditions.componentType === "primitiveValue") {
                        l_conditions.push(new PrimitiveValue(condition.id, condition.referencedQuestion, p_result.conditions.expectedValue, this.basicConfiguration.userOrgUnitGuids));
                    } else {
                        l_conditions.push(new ComplexAnswer(p_result, condition.operator, condition.components, this.basicConfiguration.userOrgUnitGuids));
                    }
                }
            }
        }
        return l_conditions;
    }
}