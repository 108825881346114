import { KnowledgeGuideServiceRestClient } from "../../rest/knowledgeGuideService/knowledgeGuideServiceRestClient";
import { DocumentServiceRestClient } from "../../rest/documentService/documentServiceRestClient";
import { ErrorPatternBuilder } from "./errorPatternBuilder";
import { Util } from "../../utility/util";
import { ErrorPatternNode } from "./errorPatternNode";
import { KnowledgeServiceRestClient } from "../../rest/knowledgeCenterService/knowledgeCenterServiceRestClient";
import { SUPPORTED_DOCUMENT_TYPES } from "../../utility/supportedDocumentTypes";
import { DOCUMENT_STATES } from "../../utility/documentStates";
import { DefaultDocumentParser } from "../../parser/default_documentparser";
import { ErrorPattern } from "./errorPattern";

export class ErrorPatternEngine {
    constructor(p_rawErrorPattern, p_basicConfiguration) {

        this.basicConfiguration = p_basicConfiguration;
        this.knowledgeGuideServiceRestClient = new KnowledgeGuideServiceRestClient(this.basicConfiguration);
        this.documentServiceRestClient = new DocumentServiceRestClient(this.basicConfiguration);
        this.knowledgeServiceRestClient = new KnowledgeServiceRestClient(this.basicConfiguration);

        this.rawErrorPattern = p_rawErrorPattern;
        //this.errorPatternBuilder = new ErrorPatternBuilder(p_rawErrorPattern);

        // add dynamic parts
        this._initEngine();
        console.kc_log("Error pattern engine initalized");

        // Remove old event listeners
        this.removeEventListeners();

        // add listeners
        this.addListenerToStartButton();
        console.kc_log("Start button listener added");
        this.addListenerToFinishButton();
        console.kc_log("Finish button listener added");
        this.addListenerToResetButton();
        console.kc_log("Reset button listener added");
        this.addListenerToPreviousButton();
        console.kc_log("Previous button listener added");
        this.addModalDialogListeners();
        console.kc_log("Modal Dialog listener added");
        this._updateDisplay();

        // this param holds the anonymous function for next, which is dynamically removed and added, depending on the previous answer
        this.nextButtonBoundFunction = '';
    }
    ;
    /**
     * reset all inputs and history elements
     */
    _initEngine() {
        this.baseErrorPatternGUID = this.rawErrorPattern.guid;

        this.history = [];
        this.nodePath = [];
        this.dialogStarted = false;

        // caching
        this.errorPatternCache = {};
        this.rawErrorPatternCache = [];

        // fill defaults
        this.rawErrorPatternCache.push(this.rawErrorPattern);
        this.errorPatternCache = [];
        this.loadedGUIDS = [];
        this.loadedGUIDS[this.rawErrorPattern.guid] = true;

        // save error pattern stack for returning to previous states
        this.errorPatternStack = [];

        this._parseErrorPattern(this.baseErrorPatternGUID, this.rawErrorPattern.mandatorkey, this.rawErrorPattern.contentLang);
        this.baseErrorPattern = this.errorPatternCache[this.baseErrorPatternGUID];
        console.kc_log("Parsed error pattern for guid %s %o", this.baseErrorPatternGUID, this.baseErrorPattern);
        this.errorPatternStack.push(this.baseErrorPattern);

        // save the solutions
        this.suggestedSolutionsStack = [];
    }

    removeEventListeners() {
        this.removeEventListenerFromElement(this._selectWithQuerySelector(this.basicConfiguration.startButtonId));
        this.removeEventListenerFromElement(this._selectWithQuerySelector(this.basicConfiguration.finishButtonId));
        this.removeEventListenerFromElement(this._selectWithQuerySelector(this.basicConfiguration.restartButtonId));
        this.removeEventListenerFromElement(this._selectWithQuerySelector(this.basicConfiguration.previousButtonId));
        this.removeEventListenerFromElement(this._selectWithQuerySelector(this.basicConfiguration.nextButtonId));
        this.removeEventListenerFromElement();
    }

    removeEventListenerFromElement(p_element) {
        if(p_element) {
            let l_clone = p_element.cloneNode(true);
            p_element.parentNode.replaceChild(l_clone, p_element);
        }
    }

    /**
     * listener for starting the error pattern tree traversal
     * - call backend with startdialog
     */
    addListenerToStartButton() {
        let self = this;
        let l_startAction = function (event) {
            if (self.basicConfiguration.startButtonAction != undefined) {
                console.kc_log("Calling additionally defined start button logic:\n%s", self.basicConfiguration.startButtonAction);

                let callbackParameter = {
                    'status': DOCUMENT_STATES.NOT_COMPLETED,
                    'data': self.getReadableData(),
                    'solutions': self.getCurrentSolutionsArray()
                };

                self.basicConfiguration.startButtonAction(self.baseErrorPattern, callbackParameter);
            }
            self._focusQuestionTitle();
            if (self.basicConfiguration.sendRequests) {
                self.knowledgeGuideServiceRestClient.startDialog(SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE, self.rawErrorPattern.guid, self.rawErrorPattern.mandatorkey, self.rawErrorPattern.contentLang).then(response => {
                    self.dialogId = (response.id)? response.id : response;
                    self._getCurrentErrorPattern().dialogId = self.dialogId;
                });
            }
            self.nodePath.push(self._getCurrentErrorPattern().getEntryNode());
            self.dialogStarted = true;
            self._processNextSuggestedSolutionsToStack(self._getCurrentErrorPattern());
            self._updateDisplay();
        }
        this._selectWithQuerySelector(this.basicConfiguration.startButtonId).addEventListener('click', l_startAction);
    
        if(this.basicConfiguration.autoStart) {
            l_startAction();
        }
    }

    /**
     * listener for finish 
     * - call custom defined action
     * - add final node     
     */
    addListenerToFinishButton() {
        let self = this;
        let l_finishButton = this._selectWithQuerySelector(this.basicConfiguration.finishButtonId);
        let l_finishButtonListener = function () {
            if (self.basicConfiguration.finishButtonAction != undefined) {
                console.kc_log("Calling additionally defined finish button logic:\n%s", self.basicConfiguration.finishButtonAction);
                self.basicConfiguration.finishButtonAction(self.baseErrorPattern);
            }
            if (self.basicConfiguration.sendRequests) {
                var document = self._getCurrentErrorPattern();
                document.dialogId = self.dialogId;
                self.knowledgeGuideServiceRestClient.finishDialog(self, self._getCurrentErrorPattern().contentLang, SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE);
            }
            let l_finishNode = new ErrorPatternNode(-1, "finished", -1);
            l_finishNode.title = self.basicConfiguration.getTranslatedLabel(self._getCurrentErrorPattern().contentLang, 'ep.finalMessage');
            self.nodePath.push(l_finishNode);
            self._updateDisplay();
        }
        l_finishButton.addEventListener('click', l_finishButtonListener, false);

        if(this.basicConfiguration.showFinishButton) {
            l_finishButton.addEventListener('click', l_finishButtonListener, false);
        } else {
            l_finishButton.style.display = 'none';
        }
    }

    /**
     * listener for previous button
     */
    addListenerToPreviousButton() {
        let self = this;
        let l_previousButtonListener = function () {

            if (self.basicConfiguration.previousButtonAction != undefined) {
                console.kc_log("Calling additionally defined previous button logic:\n%s", self.basicConfiguration.previousButtonAction);
                self.basicConfiguration.previousButtonAction(self.baseErrorPattern);
            }
            self._focusQuestionTitle();
            self._resolvePreviousNode();
        }
        this._selectWithQuerySelector(this.basicConfiguration.previousButtonId).addEventListener('click', l_previousButtonListener, false);
    }

    /**
     * reset button after error pattern is finished
     */
    addListenerToResetButton() {
        let self = this;
        let l_restartButton = this._selectWithQuerySelector(this.basicConfiguration.restartButtonId);
        let l_restartButtonListener = function () {
            self.basicConfiguration.renderDocumentFunction(self.baseErrorPatternGUID, self.baseErrorPattern.mandatorKey, self.baseErrorPattern.contentLang);
        }
        l_restartButton.addEventListener('click', l_restartButtonListener, false);
    }

    addListenerToNextButton() {
        let l_nextButton = this._selectWithQuerySelector(this.basicConfiguration.nextButtonId);
        let l_currentNode = this._getCurrentNode();
        let l_answer = '';
        if (l_currentNode.answers) {
            Object.keys(l_currentNode.answers).forEach(function (key) {
                let answer = l_currentNode.answers[key];
                if (l_currentNode.lastChoosenAnswerId === answer.id) {
                    //console.kc_log("resolved for next %o", l_answer);
                    l_answer = answer;
                }
            });
        }
        if (this.nextButtonBoundFunction) {
            l_nextButton.removeEventListener('click', this.nextButtonBoundFunction, false);
        }
        if (l_answer) {
            console.kc_log("adding listener to next with params %o, %o, %s, %s %s", this, l_answer, l_answer.title, l_answer.id, l_answer.nodeId);
            this.nextButtonBoundFunction = this._nextNodeListener.bind(null, this, l_answer.title, l_answer.id, l_answer.nodeId);
            l_nextButton.addEventListener('click', this.nextButtonBoundFunction, false);
        
            l_nextButton.disabled = false;
        }
    }

    /**
     * listeners for model dialog to restart while traversing the node tree
     */
    addModalDialogListeners() {
        let self = this;
        // listener for show modal dialog
        let l_restartButtonFromHistory = document.getElementById(this.basicConfiguration.restartButtonFromHistoryId);
        let l_restartButtonListener = function () {
            Util.displayElement(self.basicConfiguration.restartModalContainer, self.basicConfiguration);
        }
        l_restartButtonFromHistory.addEventListener('click', l_restartButtonListener, false);
        // listener for close modal dialog via 'X' or 'no'        
        let l_xButton = document.getElementById('ep_restartModalContainer_close');
        let l_noButton = document.getElementById("kc_modal_no");
        let l_closeListener = function () {
            Util.hideElement(self.basicConfiguration.restartModalContainer, self.basicConfiguration);
        }
        l_xButton.addEventListener('click', l_closeListener, false);
        l_noButton.addEventListener('click', l_closeListener, false);
        // listener for reset
        let l_yesButton = document.getElementById("kc_modal_yes");
        let l_doResetListener = function () {
            let l_errorPatternToOpen = self.baseErrorPattern;
            self.basicConfiguration.renderDocumentFunction(l_errorPatternToOpen.guid, l_errorPatternToOpen.mandatorKey, l_errorPatternToOpen.contentLang);
        }
        l_yesButton.addEventListener('click', l_doResetListener, false);
    }

    /**
     * set visibility of modal dialog for reset
     * @param {*} p_visible true/false
     */
    _setModalVisibility(p_visible) {
        let modal = document.getElementById(this.basicConfiguration.restartModalContainer);
        if (modal) {
            modal.style.display = p_visible ? "block" : "none";
        }
        else {
            console.error("Cannot find modal dialog with id " + this.basicConfiguration.restartModalContainer)
        }
    }

    /**
     * get the currently traversed error pattern - this changes, when the user dives into or exists a reference (subDialog)
     */
    _getCurrentErrorPattern() {
        return this.errorPatternStack[this.errorPatternStack.length - 1];
    }

    _getCurrentErrorPatternGUID() {
        return this._getCurrentErrorPattern().guid;
    }

    _getCurrentErrorPatternLevel() {
        if (this.errorPatternStack.length > 0) {
            return this.errorPatternStack.length - 1;
        }
        return 0;
    }

    /**
     * add the current node to the traversed path 
     * @param {*} p_node 
     */
    _addNodeToUserPath(p_node) {
        this.nodePath.push(p_node);
    }

    /**
     * add the question and made decision to the history
     * @param {*} p_node 
     * @param {*} p_answer 
     * @param {*} p_answerId 
     */
    _addDecisionToHistory(p_node, p_answer, p_answerId) {
        this.history.push({ "question": p_node.title, "answer": p_answer, "nodeId": p_node.nodeId, "nodeDocId": p_node.docId, "answerId": p_answerId, "content": p_node.content });
        console.kc_log("User history is now\%o", this.history);
    }

    _getErrorPatternFromCache(p_guid) {
        if (this.errorPatternCache[p_guid] != undefined) {
            console.kc_log("Error pattern with guid %s resolved from cache", p_guid);
            return this.errorPatternCache[p_guid];
        }
    }
    _initErrorPatternCache(p_errorPattern) {
        let self = this;
        if (p_errorPattern.references) {
            Object.keys(p_errorPattern.references).forEach(function (rKey) {
                let l_reference = p_errorPattern.references[rKey];
                if (!l_reference.isJumpDiagnose || (self.basicConfiguration.embeddedInKFirst || self.basicConfiguration.embeddedInSmartLink)) {
                    self._parseErrorPattern(l_reference.guid, p_errorPattern.mandatorKey, p_errorPattern.contentLang);
                }
            });
        }
    }

    _parseErrorPattern(p_guid, p_mandatorKey, p_contentLang) {
        if (this.loadedGUIDS[p_guid] && p_guid != this.baseErrorPatternGUID) {
            console.kc_log("Already loaded guid %s (circular dependency in solution graph) - guid will not be loaded again", p_guid);
        }
        else {
            this.loadedGUIDS[p_guid] = true;
            let l_rawReference = this._getRawErrorPattern(this.rawErrorPatternCache, p_guid);
            let l_referenceResolvedFromCache = false;
            if (l_rawReference) {
                console.kc_log("guid %s is resolved from cache (raw pattern), trying to parse", p_guid);
                try {
                    let l_cacheLoadedErrorPattern = new ErrorPatternBuilder(l_rawReference).parse();
                    this.errorPatternCache[p_guid] = l_cacheLoadedErrorPattern;
                    this._initErrorPatternCache(l_cacheLoadedErrorPattern);
                    l_referenceResolvedFromCache = true;
                }
                catch (error) {
                    console.kc_log("error: %o", error)
                    console.kc_log("guid %s is resolved from cache (raw pattern), but is not completely loaded", p_guid);
                    l_referenceResolvedFromCache = false;
                }
            }
            if (!l_referenceResolvedFromCache) {
                console.kc_log("guid %s is resolved via service call", p_guid);
                this.documentServiceRestClient.getDocument(p_guid, p_mandatorKey, p_contentLang)
                    .then(result => {
                        this.rawErrorPatternCache.push(result);
                        let l_lazyLoadedErrorPattern = new ErrorPatternBuilder(result).parse();
                        this.errorPatternCache[p_guid] = l_lazyLoadedErrorPattern;
                        this._initErrorPatternCache(l_lazyLoadedErrorPattern);
                    });
            }
        }
    }

    /**
     * iterate through references tree (of unparsed error pattern) and try to find reference element with given guid
     * until no further references are defined / loaded
     * @param {*} p_rawErrorPattern 
     * @param {*} p_docId 
     */
    _getRawErrorPattern(p_rawReferences, p_guid) {
        if (p_rawReferences) {
            for (let rawReference of p_rawReferences) {
                if (rawReference.guid == p_guid) {
                    return rawReference;
                }
                else if (rawReference.references) {
                    return this._getRawErrorPattern(rawReference.references, p_guid);
                }
            }
        }
    }

    /**
     * resolve the next node depending on the given node
     * if reference, get the sub errorPattern from the backend
     * if exit, switch back to the "parent" errorPattern
     * otherwise render the current node
     * @param {*} p_nodeId 
     */
    _resolveNextNode(p_nodeId) {
        let self = this;
        let l_currentNode = this._getCurrentErrorPattern().getNode(p_nodeId);
        l_currentNode = l_currentNode?l_currentNode:{'type':'noNodeFound'}; // it is possible there is no next node found

        console.kc_log("Resolving next node %o from errorPattern with id %s\n%o of stack %o", l_currentNode, this._getCurrentErrorPattern().id, this._getCurrentErrorPattern(), this.errorPatternStack);
        this._addNodeToUserPath(l_currentNode);
        this._processNextSuggestedSolutionsToStack(this._getCurrentErrorPattern());
        
        switch (l_currentNode.type) {
            case 'reference': {
                let l_cacheLoadedErrorPattern = this._getErrorPatternFromCache(l_currentNode.guid);
                this.errorPatternStack.push(l_cacheLoadedErrorPattern);
                this._resolveNextNode(this._getCurrentErrorPattern().getEntryNode().nodeId);
                break;
            }
            case 'exit': {
                console.kc_log("Resolve next node is processing exit to parent dialog");
                // add the element before the current to the stack, because the path is returning
                // to the parent errorPattern
                if (this.errorPatternStack.length > 1) {
                    console.kc_log("s- resolved exit with node %o, stack %o", l_currentNode, this.errorPatternStack);
                    let l_currentStackElement = this.errorPatternStack.pop();
                    let l_previousStackElement = this._getCurrentErrorPattern();
                    this.errorPatternStack.push(l_currentStackElement);
                    this.errorPatternStack.push(l_previousStackElement);
                    /**
                     * if we exit and it directly resolves to a solution, the answer id in the subgraph and the parent graph are the same, 
                     * but the node ids differ, so we need to find the answerid and take this nodeId for the parent EP
                    */
                    let self = this;
                    let currentEP = this._getCurrentErrorPattern();
                    console.kc_log("coming back from subgraph and nodeid cannot be found in parent ep - trying to find answerId %s in parent EP %o", l_currentNode.docId, this._getCurrentErrorPattern());
                    Object.keys(currentEP.nodes).forEach(function (nodenr) {
                        let node = currentEP.nodes[nodenr];
                        Object.keys(node.answers).forEach(function (answernr) {
                            let answer = node.answers[answernr];
                            if (answer.id == l_currentNode.docId) {
                                self._resolveNextNode(answer.nodeId);
                            }
                        });
                    });
                    console.kc_log("resolved exit with node %o, stack %o", l_currentNode, this.errorPatternStack);
                }
                // in this case we have an exit without initially coming from a parent errorPattern
                // and the errorPattern should end - therefore we add an empty node with solution type
                // we also add the currentPattern to the stack in case the users chooses returns
                else {
                    this._addNodeToUserPath(new ErrorPatternNode(l_currentNode.nodeId, 'solution'));
                    this.errorPatternStack.push(this._getCurrentErrorPattern());
                    this._updateDisplay();
                }
                break;
            }            
            default: {
                // Handle automatic questions
                if(l_currentNode.componentType == 'automatic_question' && l_currentNode.data_key != undefined) {
                    if(l_currentNode.data_key) {
                        // TODO: Call command and wait for the answer:
                        // - Loading screen
                        // - Show answer in history
                        if (self.basicConfiguration.automaticQuestionAction != undefined) {
                            console.kc_log("Calling automatic question action:\n%s", self.basicConfiguration.automaticQuestionAction);
                            self.basicConfiguration.automaticQuestionAction(this, l_currentNode);
                        } else {
                            console.kc_log('automaticQuestionAction is undefined!');
                        }
                    }
                    break;
                } else {
                    this._updateDisplay();
                    break;
                }
            }
        }
    }

    /**
     * resolve the previous node depending on the given node in case the user is traversing the tree backwards
     * if reference or exit - remove traversed errorPattern and get the previous node
     * otherwise remove last node and last entry from history
     * @param {*} p_omitRendering 
     */
    _resolvePreviousNode(p_omitRendering) {
        this.nodePath.pop();
        let l_currentNode = this._getCurrentNode();
        console.kc_log("Resolving previous node with id %s from errorPattern with id %s\n%o", l_currentNode.nodeId, this._getCurrentErrorPattern().id, this._getCurrentErrorPattern());
        this._processPreviousSuggestedSolutions();

        switch (l_currentNode.type) {
            case 'reference':
            case 'exit': {
                if (!this.errorPatternStack.pop()) {
                    throw 'ErrorPatternStack is empty';
                }
                this._resolvePreviousNode();
                break;
            }
            default: {
                let l_lastChoosenAnswer = this.history.pop();
                if (!l_lastChoosenAnswer) {
                    throw 'ErrorPattern history is empty';
                } 

                if (!p_omitRendering) {
                    if(l_currentNode.componentType == 'automatic_question') {
                        this._resolvePreviousNode(p_omitRendering);
                        return;
                    }
                }
                
                this._getCurrentNode().lastChoosenAnswerId = l_lastChoosenAnswer.answerId;
                if (!p_omitRendering) {
                    this._updateDisplay();
                }
                break;
            }
        }
    }

    /**
     * this method wraps all calls to display elements which need to be updated
     */
    _updateDisplay() {
        this._renderContent();
        this._updateDynamicElements();
        this._updateHistoryDisplay();
        console.log(this.getDocumentInfo())
        if (this.baseErrorPattern.showSolutions) {
            this._renderSolutions();
        }
    }

    /**
     * this method hides all elements of an error pattern and shows the given loading content instead
     * @param {*} p_title 
     * @param {*} p_content 
     * @param {*} p_summary 
     * @param {*} p_answer 
     */
    _showLoadingView(p_title, p_content, p_summary, p_answer) {
        
        // Hide all elements that are not needed
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.startButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.previousButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.finishButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.restartButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.nextButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.solutionsContainer), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.historyContainer), this.basicConfiguration);

        // Get the DOM elements
        let l_answersDiv = this._selectWithQuerySelector(this.basicConfiguration.questionContainerAnswers);
        let l_titleElement = this._selectWithQuerySelector(this.basicConfiguration.questionContainerTitle);
        let l_contentElement = this._selectWithQuerySelector(this.basicConfiguration.questionContent);
        let l_summaryElement = this._selectWithQuerySelector(this.basicConfiguration.questionSummary);

        // Set title
        if(l_titleElement) {
            if (p_title) {
                l_titleElement.innerText = p_title;
            }
        }
        // Set summary
        if (l_summaryElement) {
            l_summaryElement.innerHTML = '';

            if (p_summary) {
                l_summaryElement.innerText = p_summary;
            }
        }

        // Set content
        if (l_contentElement) {
            l_contentElement.innerHTML = '';
            if (p_content) {
                l_contentElement.innerHTML = p_content;
            }
        }

        // Set answers
        if (l_answersDiv) {
            l_answersDiv.innerHTML = '';
            if(p_answer) {
                l_answersDiv.innerHTML = p_answer;
            }
        }
    }

    /**
     * renders the question title and.contentLang
     * if answers are available, these are also rendered and the buttons action listener is added
     */
    _renderContent() {
        let l_currentNode = this._getCurrentNode();
        let l_answersDiv = this._selectWithQuerySelector(this.basicConfiguration.questionContainerAnswers);
        let l_titleElement = this._selectWithQuerySelector(this.basicConfiguration.questionContainerTitle);
        let l_contentElement = this._selectWithQuerySelector(this.basicConfiguration.questionContent);
        let l_summaryElement = this._selectWithQuerySelector(this.basicConfiguration.questionSummary);
        console.kc_log("Rendering content: answersDiv %o titleElement %o contentElement %o, summaryElement %o", l_answersDiv, l_titleElement, l_contentElement, l_summaryElement);
        //clean body
        let l_documentBody = document.getElementById(this.rawErrorPattern.guid);
        console.kc_log("Rendering content: body element for guid %s, %o", this.rawErrorPattern.guid, l_documentBody);
        if (l_documentBody) {
            l_documentBody.innerHTML = '';
        }
        //set the title
        if (l_currentNode && !this._isNodeASolution(l_currentNode)) {

            if (l_titleElement) {
                if (l_currentNode.title) {
                    l_titleElement.innerText = l_currentNode.title;
                }
            }

            // reset summary and.content
            if (l_summaryElement) {
                l_summaryElement.innerHTML = '';

                if (l_currentNode.summary) {
                    l_summaryElement.innerText = l_currentNode.summary;
                }
            }

            if (l_contentElement) {
                l_contentElement.innerHTML = '';
            }

            if (l_currentNode.content) {
                let l_docParser = new DefaultDocumentParser(this.basicConfiguration);
                if (!(this.basicConfiguration.embeddedInKFirst)) {
                    if (this.basicConfiguration.customParsingFunction) {
                        this.basicConfiguration.customParsingFunction(l_currentNode.content).then(p_parsedContent => {
                            if (l_contentElement) {
                                l_contentElement.innerHTML = p_parsedContent;
                            }
                        }).catch(() => {
                            if (l_contentElement) {
                                l_contentElement.innerHTML = l_currentNode.content;
                            }
                        });
                    } else {
                        if (l_contentElement) {
                            l_contentElement.innerHTML = l_docParser.parse(l_currentNode.content);
                        }
                        l_docParser.addImageListeners(this.baseErrorPattern.guid);
                        l_docParser.registerImageForContainer(l_contentElement);
                        l_docParser.addReferenceListeners(this.basicConfiguration.renderDocumentFunction, this.baseErrorPattern.master);
                    }
                } else {
                    if (this.basicConfiguration.customParsingFunction) {
                        this.basicConfiguration.customParsingFunction(l_currentNode.content).then(p_parsedContent => {
                            if (l_contentElement) {
                                l_contentElement.innerHTML = p_parsedContent;
                            }
                        }).catch(() => {
                            if (l_contentElement) {
                                l_contentElement.innerHTML = l_currentNode.content;
                            }
                        });
                    } else {
                        if (l_contentElement) {
                            l_contentElement.innerHTML = l_currentNode.content;
                        }
                    }
                }
            }
        }
        else {
            if (l_titleElement && !this._isNodeASolution(l_currentNode)) {
                l_titleElement.innerHTML = this._getCurrentErrorPattern().content;
            }
            if(this._isNodeASolution(l_currentNode)) {
                if (l_summaryElement) {
                    l_summaryElement.innerHTML = '';
                }

                if (l_contentElement) {
                    l_contentElement.innerHTML = '';
                }  
            }
            
        }
        // set the answers
        if (l_answersDiv) {
            l_answersDiv.innerHTML = '';
        }

        if (l_currentNode && this._isNodeASolution(l_currentNode)) {
            let self = this;
            let l_titleElement = this._selectWithQuerySelector(this.basicConfiguration.questionContainerTitle);

            const l_titleLabel = this.basicConfiguration.getTranslatedLabel(this._getCurrentErrorPattern().contentLang, 'ep.solution');
            if (l_titleLabel && l_titleElement) {
                l_titleElement.innerHTML = l_titleLabel;
            }

            if(l_currentNode && Array.isArray(l_currentNode) && l_currentNode.length > 1) {
                l_currentNode.forEach( node => {
                    if (this.basicConfiguration.renderSolutionsLink) {
                        let l_solutionLink = this._createSolutionsLink(node);
                        if (l_answersDiv) {
                            let l_solutionBlock = Util.createRowCol(l_solutionLink, this.basicConfiguration);
                            if (node.summary) {
                                let l_summaryDiv = document.createElement("div");
                                l_summaryDiv.textContent = node.summary;
                                l_summaryDiv.classList.add('ep_solution_summary');
                                l_solutionBlock.appendChild(l_summaryDiv);
                            }
                            l_answersDiv.appendChild(l_solutionBlock);
                        }
                    }
                });
            } else if (self.basicConfiguration.renderSolutionsLink) {
                let l_solutionLink = this._createSolutionsLink(l_currentNode);
                if (l_answersDiv) {
                    let l_solutionBlock = Util.createRowCol(l_solutionLink, this.basicConfiguration);
                    if (l_currentNode.summary) {
                        let l_summaryDiv = document.createElement("div");
                        l_summaryDiv.textContent = l_currentNode.summary;
                        l_summaryDiv.classList.add('ep_solution_summary');
                        l_solutionBlock.appendChild(l_summaryDiv);
                    }
                    l_answersDiv.appendChild(l_solutionBlock);
                }
            }
            
            if (self.basicConfiguration.renderLooseSolutions) {
                Object.keys(this._getCurrentSuggestedSolutionsStackElementAsList()).forEach(function (key) {
                    let l_solution = self._getCurrentSuggestedSolutionsStackElementAsList()[key];
                    if (l_solution.isLooseSolution) {
                        let l_looseSolutionLink = self._createSolutionsLink(l_solution);
                        if (l_answersDiv) {
                            l_answersDiv.appendChild(Util.createRowCol(document.createElement("br"), self.basicConfiguration));
                            l_answersDiv.appendChild(Util.createRowCol(l_looseSolutionLink, self.basicConfiguration));
                        }
                    }
                });
            }            
        }
        let self = this;
        if (l_currentNode) {
            //iterate over all answers            
            if (l_currentNode.answers) {
                let l_sortedKeys = Object.keys(l_currentNode.answers);
                l_sortedKeys.sort((key1, key2) => {
                    return l_currentNode.answers[key1].order - l_currentNode.answers[key2].order;
                })
                let l_nextButton = this._selectWithQuerySelector(this.basicConfiguration.nextButtonId);
                l_nextButton.disabled = false;
                l_sortedKeys.forEach(function (key) {
                    let answer = l_currentNode.answers[key];

                    let l_option = document.createElement('BUTTON');
                    l_option.textContent = answer.title;
                    l_option.setAttribute('role', 'switch');
                    l_option.setAttribute('aria-checked', 'false');
                    l_option.classList.add(self.basicConfiguration.css_ep_answer);
                    l_option.id = l_currentNode.nodeId + "_" + answer.nodeId;
                    l_option.nodeId = answer.nodeId;
                    if (l_currentNode.lastChoosenAnswerId === answer.id) {
                        l_option.classList.add(self.basicConfiguration.css_ep_mark_answer);
                        l_option.setAttribute('aria-checked', 'true');
                    }
                    let isJumpDiagnose = false;
                    let answerNode = self._getCurrentErrorPattern().getNode(answer.nodeId);
                    if( !(self.basicConfiguration.embeddedInKFirst || self.basicConfiguration.embeddedInSmartLink)) {
                        if (answerNode && self._getCurrentErrorPattern().references) {
                            let referenceElement = self._getCurrentErrorPattern().references[answerNode.docId];
                            if (referenceElement) {
                                if (referenceElement.isJumpDiagnose) {
                                    isJumpDiagnose = true;
                                }
                            }
                        }
                    }
                    if (isJumpDiagnose) {
                       
                            answerNode.title = answer.title;
                            let href = self._createSolutionsLink(answerNode);
                            href.classList.add(self.basicConfiguration.css_ep_answer);
                            href.classList.add("nolink");
                            if (l_answersDiv) {
                                l_answersDiv.appendChild(Util.createRowCol(href, self.basicConfiguration));
                            }

                    }
                    else {
                        //add event listener for answers                    
                        l_option.addEventListener('click', self._nextNodeListener.bind(null, self, answer.title, answer.id, answer.nodeId), false);
                        
                        l_option.addEventListener('click', function() {
                            l_option.setAttribute('aria-checked', 'true');
                        });
                        
                        if (l_answersDiv) {
                            l_answersDiv.appendChild(Util.createRowCol(l_option, self.basicConfiguration));
                        }
                    }


                });
                this.addListenerToNextButton();
            }
        }
    }

    /**
     * event listener when clicking on answer
     * @param {*} self 
     * @param {*} p_answerTitle 
     * @param {*} p_answerId 
     * @param {*} p_answerNodeId 
     */
    _nextNodeListener(self, p_answerTitle, p_answerId, p_answerNodeId) {
        let l_currentNode = self._getCurrentNode();
        console.kc_log("Processing next node listener with node id %s and answer %s", p_answerNodeId, p_answerTitle);

        // resolve next node - if value set, use this    
        if (p_answerNodeId != null) {
            // call custom action
            if (self.basicConfiguration.nextButtonAction != undefined) {
                let callbackParameter = {
                    'status': DOCUMENT_STATES.NOT_COMPLETED,
                    'data': self.getReadableData(),
                    'solutions': self.getCurrentSolutionsArray()
                };

                console.kc_log("Calling additionally defined next button logic:\n%s", self.basicConfiguration.nextButtonAction);
                self.basicConfiguration.nextButtonAction(self._getCurrentErrorPattern(), p_answerNodeId, callbackParameter);
            }
            self._focusQuestionTitle();
            //save history
            self._addDecisionToHistory(l_currentNode, p_answerTitle, p_answerId);
            self._resolveNextNode(p_answerNodeId);
        }
        // otherwise it is a answer without a following node - render empty node
        else {
            self._addDecisionToHistory(l_currentNode, p_answerTitle, p_answerId);
            self._resolveNextNode(ErrorPattern.getDefaultNodeId());
        }
    }

    _focusQuestionTitle() {
        this._selectWithQuerySelector(this.basicConfiguration.questionContainerTitle).focus();
    }

    _selectWithQuerySelector(id) {
        return Util.getElementByIdWithQuery(id, this.basicConfiguration)
    }

    /**
     * updates all buttons and divs according to the behaviour of KCenter
     */
    _updateDynamicElements() {
        // hide all buttons and reset disabled state
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.startButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.previousButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.finishButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.restartButtonId), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.nextButtonId), this.basicConfiguration);
        // hide all elements
        if (!this.basicConfiguration.embeddedInKFirst) {
            Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.historyContainer), this.basicConfiguration);
        }
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.questionContainer), this.basicConfiguration);
        Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.solutionsContainer), this.basicConfiguration);


        // get the state depending on node type
        let l_currentNode = this._getCurrentNode();
        let l_finished = 'finished' === (l_currentNode ? l_currentNode.type : undefined);
        let l_solutionReached = this._isNodeASolution(l_currentNode);
        

        // display start button
        if (!this.dialogStarted) {
            Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.startButtonId), this.basicConfiguration);
            Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.questionContainer), this.basicConfiguration);
        }

        if (this.dialogStarted) {
            Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.questionContainer), this.basicConfiguration);
            if (this.basicConfiguration.renderNextButton) {
                Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.nextButtonId), this.basicConfiguration);
            }
        }

        if (this.dialogStarted && !l_finished) {
            if (!this.basicConfiguration.embeddedInKFirst) {
                Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.historyContainer), this.basicConfiguration);
            }

            if (this.baseErrorPattern.showSolutions) {
                Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.solutionsContainer), this.basicConfiguration);
            }
        }

        // display previous button
        if (!l_finished && this.nodePath.length > 1) {
            Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.previousButtonId), this.basicConfiguration);
        }

        // display finish button
        if (l_solutionReached) {
            Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.finishButtonId), this.basicConfiguration);
            Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.solutionsContainer), this.basicConfiguration);
            Util.hideElement(this._selectWithQuerySelector(this.basicConfiguration.nextButtonId), this.basicConfiguration);
        }

        // display restart button
        if (l_finished) {
            Util.displayElement(this._selectWithQuerySelector(this.basicConfiguration.restartButtonId), this.basicConfiguration);
            Util.hideElement(   this._selectWithQuerySelector(this.basicConfiguration.nextButtonId), this.basicConfiguration);
        }

        if(this.basicConfiguration.processStepAction) {
            let callbackParameter = {
                'status': l_finished?DOCUMENT_STATES.COMPLETED:DOCUMENT_STATES.NOT_COMPLETED,
                'data': this.getReadableData(), //see what comes from here and if that makes sense.
                'solutions': this.getCurrentSolutionsArray()
            };
                this.basicConfiguration.processStepAction(callbackParameter);
        }
    }

    /**
     * updates the history (selected questions + answers) the user traversed
     * adds buttons to go back to answered questions
     */
    _updateHistoryDisplay() {
        let self = this;

        if(this.basicConfiguration.updateHistoryCallback){
            this.basicConfiguration.updateHistoryCallback(this.history, self);
        } else {
            let l_historyDiv = this._selectWithQuerySelector(this.basicConfiguration.historyTreeContainer);
            if (l_historyDiv) {
                l_historyDiv.innerHTML = '';
            }
            if (this.history) {
                let questionCount = 1;
                for (let historyEntry of this.history) {
                    let l_pEntry = document.createElement('P');
                    let l_pHeader = document.createElement('H3');

                    if (this.basicConfiguration.embeddedInSmartLink) {
                        l_pHeader = document.createElement('div');
                        l_pHeader.classList.add('history-entry');
                    }

                    l_pHeader.innerText = questionCount + '. ' + historyEntry.question;
                    let l_pAnswer = document.createElement('SPAN');
                    l_pAnswer.innerText = historyEntry.answer;

                    let l_goBackUntilButton;
                    if (this.basicConfiguration.embeddedInKFirst) {
                        l_goBackUntilButton = document.createElement('SPAN');
                        l_goBackUntilButton.classList.add("spanAsInput");
                        l_goBackUntilButton.innerHTML = '<div class="kfirst-svg"><svg class="kfirst-svg-icon"><use xlink:href="#kfirst_solid_undo"></use></svg></div>';
                    } else if (this.basicConfiguration.embeddedInSmartLink) {
                        l_goBackUntilButton = document.createElement('SPAN');
                        l_goBackUntilButton.classList.add("spanAsInput");
                        l_goBackUntilButton.innerHTML = '<span class="sl-icon-back clickable"></span>';
                    } else {
                        l_goBackUntilButton = document.createElement("SPAN");
                        l_goBackUntilButton.classList.add("spanAsInput");
                        let l_icon = this._selectWithQuerySelector(this.basicConfiguration.historyBackButtonId).cloneNode(true);
                        Util._removeCSSClass(l_icon, self.basicConfiguration.css_element_invisible);
                        l_goBackUntilButton.appendChild(l_icon);
                    }
                    l_goBackUntilButton.id = historyEntry.nodeDocId + '_kc_back_until';
                    l_goBackUntilButton.addEventListener('click', function () {
                        console.kc_log("Processing go back until historyEntry [%s, %s]", historyEntry.nodeDocId, historyEntry.nodeId);
                        self._goBackUntilNode(historyEntry.nodeDocId, historyEntry.nodeId);
                    }, false);

                    l_pHeader.appendChild(l_goBackUntilButton);
                    l_pEntry.appendChild(l_pHeader);
                    l_pEntry.appendChild(l_pAnswer);
                    questionCount++;

                    if (l_historyDiv) {
                        l_historyDiv.appendChild(l_pEntry);
                    }
                }
            }
        }
    }

    /**
     * traverse the node path the user took back until the given question (nodeId)
     * @param {*} p_nodeId 
     */
    _goBackUntilNode(p_docId, p_nodeId) {
        let l_currentNode;
        while (l_currentNode = this._getCurrentNode(), l_currentNode.nodeId != p_nodeId || l_currentNode.docId != p_docId) {
            this._resolvePreviousNode(true);
        }

        if(this._getCurrentNode().componentType == 'automatic_question') {
            const l_currentErrorPattern = this._getCurrentErrorPattern();
            const l_previousNode = l_currentErrorPattern.getPreviousNode(this._getCurrentNode().nodeId);
            const l_nodeId = l_previousNode.nodeId;
            this._goBackUntilNode(l_nodeId);
        }

        this._updateDisplay();
    }

    /**
     * return the current node traversed node path
     */
    _getCurrentNode() {
        console.kc_log("User node path is now\%o", this.nodePath);
        return this.nodePath[this.nodePath.length - 1];
    }

    /**
     * if showSolutions in the error pattern is true, this renders all possible solutions within the current 
     * errorPattern and it's parent errorPattern
     */
    _renderSolutions() {
        let self = this;
        let l_solutionsList = this._selectWithQuerySelector(this.basicConfiguration.possibleAnswersContainer);
        if (l_solutionsList) {
            l_solutionsList.innerHTML = '';
        }
        Object.keys(this._getCurrentSuggestedSolutionsStackElementAsList()).forEach(function (key) {
            let solution = self._getCurrentSuggestedSolutionsStackElementAsList()[key];
            let l_solutionElement = Util.createRowCol(self._createSolutionsLink(solution), self.basicConfiguration);
            l_solutionElement.classList.add('ep_solution_element');
            if (solution.summary && solution.summary.length > 0) {
                let l_summaryDiv = document.createElement("div");
                l_summaryDiv.textContent = solution.summary;
                l_summaryDiv.classList.add('ep_solution_summary');
                l_solutionElement.appendChild(l_summaryDiv);
            }
            if (l_solutionsList) {
                l_solutionsList.appendChild(l_solutionElement);
            }
        });
    }

    /**
     * creates the link to render the solution - this is done using the renderDocumentFunction which again
     * calls the documentRenderer
     * @param {*} p_solution 
     */
    _createSolutionsLink(p_solution) {
        let self = this;
        let l_solutionLink = document.createElement('A');
        l_solutionLink.innerText = p_solution.title;

        if (this.basicConfiguration.embeddedInKFirst) {
            l_solutionLink.classList.add("referenceLink");
            l_solutionLink.setAttribute("data-kfirst-doc", "guid:" + p_solution.guid);
            l_solutionLink.title = p_solution.title;
            l_solutionLink.addEventListener('click', function () {
                //  console.kc_log("click on solution link %o", self.basicConfiguration.renderDocumentFunction);
                self.basicConfiguration.renderDocumentFunction(p_solution.guid, p_solution.mandatorKey, p_solution.contentLang);
            }, false);
        } else if (this.basicConfiguration.embeddedInSmartLink) {

            if(p_solution.customFields && p_solution.customFields.responsepattern && p_solution.customFields.responsepattern.length > 0) {
                l_solutionLink = document.createElement('span');
                let index = Math.floor(Math.random() * p_solution.customFields.responsepattern.length);
                l_solutionLink.innerText = p_solution.customFields.responsepattern[index];
            } else {
                l_solutionLink.setAttribute('href', '#/document/' + p_solution.guid + '/' + 'SLD');
                l_solutionLink.title = p_solution.title;
                l_solutionLink.classList.add("link");
                l_solutionLink.target = '_self';
            }
        } else {
            if(this.basicConfiguration.newDocNoNewTab) {
                // l_solutionLink.classList.add("referenceLink");
                // l_solutionLink.setAttribute("documentreference", "guid:"+p_solution.guid);
                l_solutionLink.setAttribute('href', '');
                l_solutionLink.setAttribute('target', 'documentShow');
            }    
            l_solutionLink.addEventListener('click', function (p_evt) {
                //  console.kc_log("click on solution link %o", self.basicConfiguration.renderDocumentFunction);
                if(self.basicConfiguration.newDocNoNewTab) {
                    self.basicConfiguration.renderDocumentFunction(p_solution.guid, p_solution.mandatorKey, p_solution.contentLang);
                    p_evt.preventDefault();
                } else {
                    l_solutionLink.href = Util.getParamsEncodedInURL(p_solution.guid, p_solution.mandatorKey, p_solution.contentLang);
                    Util.storeConfigInBrowserCache(self.basicConfiguration);
                    l_solutionLink.target = '_blank';
                }
            }, false);
        }
        return l_solutionLink;
    }
    /**
     * ----------------------------------------------------------------------
     * logic for suggested solutions
     * ----------------------------------------------------------------------
     */

    /**
     * get the latest suggested solutions element from stack
     */
    _getCurrentSuggestedSolutionsStackElement() {
        if (this.suggestedSolutionsStack.length > 0) {
            return this.suggestedSolutionsStack[this.suggestedSolutionsStack.length - 1];
        }
        return [];
    }

    _getCurrentSuggestedSolutionsStackElementAsList() {
        let solutionsList = [];
        let solutions = this._getCurrentSuggestedSolutionsStackElement();
        if (solutions) {
            Object.keys(solutions).forEach(function (key) {
                let epSolutions = solutions[key]["solutions"];
                if(epSolutions) {
                    Object.keys(epSolutions).forEach(function (okey) {
                        let l_solution = epSolutions[okey];
                        // Check if a solution has a response pattern.
                        if(!l_solution.customFields || !l_solution.customFields.responsepattern) {
                            solutionsList.push(l_solution);
                        }
                    });
                }
            });
        }
        return solutionsList;
    }

    _processNextSuggestedSolutionsToStack() {
        let self = this;
        if (this.baseErrorPattern.showSolutions) {
            // stack is empty, its the start, just transform and push to stack
            if (this.suggestedSolutionsStack.length == 0) {
                let l_transformed = this._getEPAndReferencedSolutions(this._getCurrentErrorPattern(), 0);
                this.suggestedSolutionsStack.push(l_transformed);
            }
            // otherwise get all solutions reachable from current ep and compare to last stack element
            else {
                let l_currentLevel = this._getCurrentErrorPatternLevel();

                let l_currentSuggestedSolutionsStackElement = this._getCurrentSuggestedSolutionsStackElement();
                let l_nextSuggestedSolutionsStackElement = [];

                // get reachable solutions and references
                let l_reachableSolutions = this._getCurrentErrorPatternReachableSolutions(this._getCurrentErrorPattern(), this._getCurrentErrorPatternLevel());
                let l_reachableReferences = this._getReachableReferences(this._getCurrentErrorPattern(), this._getCurrentErrorPatternLevel() + 1);
                console.kc_log("Reachable solutions for guid %s\nbased on node\n%o\n%o\nRefs\n%o\n", this._getCurrentErrorPatternGUID(), this._getCurrentNode(), l_reachableSolutions, l_reachableReferences);



                // add all solutions from sub error pattern
                if (l_reachableReferences && l_reachableReferences.length > 0) {
                    Object.keys(l_reachableReferences).forEach(function (key) {
                        let l_reachableReference = l_reachableReferences[key];
                        let l_reachableErrorPattern = self._getErrorPatternFromCache(l_reachableReference.guid);
                        let l_solutionsFromReference = self._getCurrentErrorPatternReachableSolutions(l_reachableErrorPattern, l_reachableReference.level);
                        console.kc_log("Now resolving suggested solutions for reference \n%o\n%o", l_reachableReference, l_solutionsFromReference);
                        l_nextSuggestedSolutionsStackElement.push(l_solutionsFromReference);
                    });
                }

                // add solutions from parent graphs
                Object.keys(l_currentSuggestedSolutionsStackElement).forEach(function (key) {
                    let levelElement = l_currentSuggestedSolutionsStackElement[key];
                    if (levelElement) {
                        // if level is lower than current level just copy
                        // but copy only, if current guid is not here from another level
                        if (levelElement.level < l_currentLevel && levelElement.guid != l_reachableSolutions.guid) {
                            l_nextSuggestedSolutionsStackElement.push(levelElement);
                        }
                    }
                });

                //set reachable solutions from current error pattern
                if (l_reachableSolutions) {
                    l_nextSuggestedSolutionsStackElement.push(l_reachableSolutions);
                }

                console.kc_log("Setting new suggested solutions stack element\n%o", l_nextSuggestedSolutionsStackElement);
                this.suggestedSolutionsStack.push(l_nextSuggestedSolutionsStackElement);
            }
        }
    }

    /**
     * removes the last element from the suggested solutions stack
     */
    _processPreviousSuggestedSolutions() {
        this.suggestedSolutionsStack.pop();
    }


    /**
     * retrieves solutions from references if they are reachable
     */
    _getReachableReferences(p_errorPattern, p_level) {
        let self = this;
        let l_reachable = [];
        let l_currentErrorPattern = p_errorPattern;// this._getCurrentErrorPattern();

        if (l_currentErrorPattern.references) {
            Object.keys(l_currentErrorPattern.references).forEach(function (key) {
                let l_reference = l_currentErrorPattern.references[key];
                if (l_reference && !l_reference.isJumpDiagnose) {

                    let l_nodeReachable = self._checkSolutionsReachableFromCurrentNode(l_reference, self._getCurrentNode().nodeId, l_currentErrorPattern);
                    if (true == l_nodeReachable) {
                        l_reachable.push({ "guid": l_reference.guid, "level": p_level });
                        l_reachable.push(...self._getReachableReferences(self._getErrorPatternFromCache(l_reference.guid), ++p_level));
                    }
                }
            });
        }
        return l_reachable;
    }


    /**
     * retrieves all solutions from this errorPattern as well as all solutions
     * from all subsequent references
     * @param {*} p_errorPattern 
     */
    _getEPAndReferencedSolutions(p_errorPattern, p_level) {
        let self = this;
        let l_solutions = [];

        console.log(p_errorPattern);
        console.log(p_level);

        if (p_errorPattern) {
            let l_currentSolValue = this._createSolutionsJSON(p_errorPattern.guid, p_level);
            if (p_errorPattern.solutions) {
                Object.keys(p_errorPattern.solutions).forEach(function (sKey) {
                    let l_solution = p_errorPattern.solutions[sKey];
                    l_solution.errorPatternGUID = p_errorPattern.guid;
                    l_currentSolValue["solutions"][l_solution.guid] = l_solution;
                });
            }
            l_solutions.push(l_currentSolValue);
            // if referenced ErrorPattern has subreferences and is not a jumpDiagnose - recursively call the function
            if (p_errorPattern.references) {
                Object.keys(p_errorPattern.references).forEach(function (rKey) {
                    let l_reference = p_errorPattern.references[rKey];
                    if (l_reference && !l_reference.isJumpDiagnose) {
                        let l_referenceNode = p_errorPattern.getNode(l_reference.nodeId);
                        if(l_referenceNode) {
                            let l_parsedReferenceErrorPattern = self._getErrorPatternFromCache(l_referenceNode.guid);
                            let l_refSolutions = self._getEPAndReferencedSolutions(l_parsedReferenceErrorPattern, ++p_level);
                            if (l_refSolutions) {
                                l_solutions.push(...l_refSolutions);
                            }
                        }   
                    }
                });
            }
        }
        return l_solutions;
    }


    /**
     * get all solutions based on the current ErrorPattern and reachability outgoing from the current node
     */
    _getCurrentErrorPatternReachableSolutions(p_errorPattern, p_level) {
        let self = this;
        let l_currentErrorPattern = p_errorPattern;
        let l_solutions = this._createSolutionsJSON(l_currentErrorPattern.guid, p_level);

        l_solutions["solutions"].push(...l_currentErrorPattern.getLooseSolutions());

        if (l_currentErrorPattern.solutions) {
            Object.keys(l_currentErrorPattern.solutions).forEach(function (key) {
                let l_solution = l_currentErrorPattern.solutions[key];
                if (!l_solution.isLooseSolution) {
                    let l_nodeReachable = self._checkSolutionsReachableFromCurrentNode(l_solution, self._getCurrentNode().nodeId, l_currentErrorPattern);
                    if (true == l_nodeReachable) {
                        l_solutions["solutions"].push(l_solution);
                    }
                }
            });
        }
        return l_solutions;
    }

    /**
     * check if a solution of the current processed error pattern is reachable from the current selected node
     * @param {*} p_node 
     * @param {*} p_currentNodeId 
     * @param {*} p_errorPattern 
     */
    _checkSolutionsReachableFromCurrentNode(p_node, p_currentNodeId, p_errorPattern) {
        if (p_node.nodeId === p_currentNodeId) {
            console.kc_log("_checkSolutionsReachableFromCurrentNode\nnode is reachable\n%o", p_node);
            return true;
        }
        else if (p_node.isEntry) {
            console.kc_log("_checkSolutionsReachableFromCurrentNode\nnode reached start\n%o", p_node);
            return false;
        }
        let l_previousNode = p_errorPattern.getPreviousNode(p_node.nodeId);
        console.kc_log("_checkSolutionsReachableFromCurrentNode\nwith node\n%o\nbased on current node\n%o\nwith ep\n%o\previous node\n%o", p_node, p_currentNodeId, p_errorPattern, l_previousNode)
        return this._checkSolutionsReachableFromCurrentNode(l_previousNode, p_currentNodeId, p_errorPattern);
    }

    _createSolutionsJSON(p_guid, p_level) {
        return { "guid": p_guid, "level": p_level, "solutions": [] };
    }

    _isNodeASolution(p_node)
    {
        if(!Array.isArray(p_node)) {
            return 'solution' === (p_node ? p_node.type : undefined);
        } else {
            return p_node.some(element => element.type === 'solution');
        }
    }

    getReadableData() {
        let l_readableObject = {
            'id':this.rawErrorPattern.id,
            'guid':this.rawErrorPattern.guid,
        }
        l_readableObject.questions = [];

        for(let entry of this.history) {
            l_readableObject.questions.push(this._createReadableQuestion(entry));
        }
        return l_readableObject;
    }

    _createReadableQuestion(p_historyEntry) {
        let l_readableQuestion = {};

        l_readableQuestion['id'] = p_historyEntry.nodeDocId;
        l_readableQuestion['answerMode'] = undefined;
        l_readableQuestion['hasExtraData'] = undefined;
        l_readableQuestion['extraDataValueProperties'] = undefined;
        l_readableQuestion['title'] = p_historyEntry.question;
        l_readableQuestion['content'] = p_historyEntry.content;
        l_readableQuestion['sid'] = undefined;
        l_readableQuestion['answers'] = [{
            'id': p_historyEntry.answerId,
            'title': p_historyEntry.answer,
            'content': undefined
        }];

        return l_readableQuestion;
    }

    dertermineDocumentState() {
        if(this.dialogStarted){
            let l_currentNode = this._getCurrentNode();
            let l_finished = 'solution' === (l_currentNode ? l_currentNode.type : undefined);

            return l_finished?DOCUMENT_STATES.COMPLETED:DOCUMENT_STATES.NOT_COMPLETED;
        } else {
            return DOCUMENT_STATES.NOT_STARTED;
        }
    }

    getCurrentSolutionsArray() {
        let self = this;
        
        let l_solutionsArray = [];

        let l_currentNode = this._getCurrentNode();

        if(this._isNodeASolution(l_currentNode)) {
            l_solutionsArray.push(l_currentNode);
        } else {
            Object.keys(this._getCurrentSuggestedSolutionsStackElementAsList()).forEach(function (key) {
                let solution = self._getCurrentSuggestedSolutionsStackElementAsList()[key];
    
                l_solutionsArray.push(solution);
            });
        }

        return l_solutionsArray;
    }

    getDocumentInfo() {
        return {
            'status': this.dertermineDocumentState(),
            'data': this.getReadableData(),
            'solutions': this.getCurrentSolutionsArray()
        };
    }
    
    finishDocument() {
        if (this.basicConfiguration.sendRequests) {
            this.knowledgeGuideServiceRestClient.finishDialog(this._getCurrentErrorPattern(), this._getCurrentErrorPattern().contentLang, SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE);
        }
    }
}
