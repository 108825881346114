import { BasicRestClient } from "../basicRestClient";

const GET_SVG_OPERATION = "/kfirst-widget/icons/iconmap.svg"

const DEFAULT_HEADERS = {
    "lang": "de",
};

export class KFirstRestClient extends BasicRestClient {

    getBaseSVGIcons() {
        let l_getBaseSVGIcons = this.createServiceURL(this.configuration.kfirstURL, GET_SVG_OPERATION);

        console.kc_log("Get SVG icons from KFirst called with url\n%s", l_getBaseSVGIcons);

        if(this.configuration.kfirstURL) // TODO: implement something to turn this off
        {
            return fetch(l_getBaseSVGIcons, {
                method: 'GET',
                credentials: this.configuration.getCredentialsMode(),
                headers: this.createHeaders(DEFAULT_HEADERS)
            })
            .then(response => response.text())
        }else 
        {
            return Promise.reject("no kfirst url");
        }

       
    }
}