import { ErrorPatternNode } from "./errorPatternNode";

export class ErrorPattern {
    constructor(p_id, p_guid, p_showSolutions, p_masterLang, p_contentLang, p_content, p_showTOC, p_mandatorKey) {
        this.id = p_id;
        this.guid = p_guid;
        this.showSolutions = p_showSolutions;
        this.masterLang = p_masterLang;
        this.contentLang = p_contentLang;
        this.content = p_content;
        this.showTOC = p_showTOC;
        this.mandatorKey = p_mandatorKey;
        this.dialogId = '';
        this.entryNodeId = '';
        // parsed informations
        this.nodes = {};
        this.questions = [];
        this.solutions = [];
        this.references = [];
        this.targetNodesToPreviousNodes = [];
    }

    static getDefaultNodeId() {
        return -1;
    }

    getNode(p_nodeId) {
        if(Array.isArray(p_nodeId) && p_nodeId.length > 1)
        {
            let l_nodes = [];
            p_nodeId.forEach(id => {
                l_nodes.push(this.nodes[id]);
            });
            return l_nodes;
        }
        else 
        {
            return this.nodes[p_nodeId];
        }
    }

    getEntryNode() {
        return this.getNode(this.entryNodeId);
    }

    getPreviousNode(p_nodeId) {
        return this.getNode(this.targetNodesToPreviousNodes[p_nodeId]);
    }

    getReferenceNode(p_docId) {
        let self = this;
        Object.keys(this.nodes).forEach(function (key) {
            let l_currentNode = self.nodes[key];
            if ('reference' === l_currentNode.type && l_currentNode.docId === p_docId) {
                return l_currentNode;
            }
        });
    }

    getLooseSolutions() {
        let self = this;
        let l_looseSolutions = [];
        Object.keys(this.solutions).forEach(function (sKey) {
            let l_solution = self.solutions[sKey];
            if (l_solution.isLooseSolution) {
                l_looseSolutions.push(l_solution);
            }
        });
        return l_looseSolutions;
    }
}