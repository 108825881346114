import { DOCUMENT_STATES } from "../../utility/documentStates";
import { EXTRA_DATA_STATE } from "./extraDataState";

export class Questionnaire {
    constructor(p_id, p_guid, p_questions, p_results) {
        this.state = DOCUMENT_STATES.NOT_COMPLETED;
        this.id = p_id;
        this.guid = p_guid;
        this.questions = p_questions?p_questions:[];
        this.sortedQuestionIds = this._sortQuestionIds(p_questions); // TODO: check if this can make some functions simpler
        this.conditionsMet = []
        this.isResultShown = false;
        if(this.sortedQuestionIds)
        {
            this.currentAnsweredQuestion = this.questions[this.sortedQuestionIds[0]]?this.questions[this.sortedQuestionIds[0]].order:-1; // uses the questions order
            this.setCurrentQuestionToAnswer()
        }
        this.results = p_results;
        this.dialogId = '';
    }

    changeAnswer(p_answerId, p_questionId, p_newValueForAnswer) {
        let l_question = this.questions[p_questionId];

        if (l_question.answerMode === "single") {
            for (let key of Object.keys(l_question.answers)) {
                let answer = l_question.answers[key]
                if (answer.id === p_answerId) {
                    answer.chosen = p_newValueForAnswer;
                }
                else {
                    answer.chosen = false;
                }
            }
            l_question.answered = p_newValueForAnswer; // this information is currently only needed if answerMode is single (because only then the question is mandatory to answer)
        } else {
            l_question.answers[p_answerId].chosen = p_newValueForAnswer;
        }

    }

    tryToAnswerExtraData(p_questionId, p_valueProperties, p_format) {
        let l_question = this.questions[p_questionId];
        let l_extraData = l_question.extraData;
        let l_extraDataType = l_extraData.type;
        console.log("trying to answer extradata: %o", p_valueProperties)
        console.log(l_question);
        // check for correct values 
        // depending on type of the extradata
        // if value is correct, change the extra data of the question and return true
        // if value is not correct, return false;
        // eitherway change the state of the extradata

        switch(l_extraDataType) {
            case 'Usu_ExtraStepGuiType_KBValueList':
                if(p_valueProperties.length > 0) {
                    //check the criteria, until then:
                    console.log("after checking criteria:")
                    l_extraData.valueProperties = p_valueProperties;
                    l_extraData.answered = true
                    l_extraData.state = EXTRA_DATA_STATE.CORRECT;
                    console.log(l_extraData)
                } else {
                    // This function was called, but no extra data was added
                    l_extraData.state = EXTRA_DATA_STATE.EMPTY;
                }
                break;
            case 'Usu_ExtraStepGuiType_Upload':
                if(p_valueProperties.length > 0) {
                    //check the criteria, until then:
                    console.log("after checking criteria:")
                    l_extraData.valueProperties = p_valueProperties;
                    l_extraData.answered = true
                    l_extraData.state = EXTRA_DATA_STATE.CORRECT;
                    console.log(l_extraData)
                } else {
                    // This function was called, but no extra data was added
                    l_extraData.state = EXTRA_DATA_STATE.EMPTY;
                }
                break;
            case 'Usu_ExtraStepGuiType_Date':
            case 'Usu_ExtraStepGuiType_Datetime':
            case 'Usu_ExtraStepGuiType_TextArea':
            case 'Usu_ExtraStepGuiType_Text':
                if(p_valueProperties.length > 0) {
                    //check the criteria
                    if(this._extradataFormatCorrect(p_valueProperties, p_format, l_extraDataType)) {
                        l_extraData.valueProperties = p_valueProperties;
                        l_extraData.answered = true
                        l_extraData.state = EXTRA_DATA_STATE.CORRECT;
                    } else {
                        l_extraData.state = EXTRA_DATA_STATE.WRONG;
                    }
                } else {
                    // This function was called, but no extra data was added
                    l_extraData.state = EXTRA_DATA_STATE.EMPTY;
                }
                break;
            default:
                // format can't be right
                break;
        }

        return l_extraData.state;
    }

    _extradataFormatCorrect(p_valueProperties, p_format, p_extraDataType) {
        let regex = new RegExp(p_format[p_extraDataType]);
        return p_valueProperties[0].properties['=text'].match(regex)? p_valueProperties[0].properties['=text'].match(regex).length > 0:false;
    }

    evaluateConditions() {
        let l_allAnswers = this.getAllAnswers();
        let l_chosenAnswers = l_allAnswers.chosen;
        let l_notChosenAnswers = l_allAnswers.notChosen;

        this.conditionsMet = [];

        // do this in the correct order, so it is possible to remove a question and it's anwers from the chosen answers 
        // if the question can't be displayed anymore
        for(let questionKey of this.sortedQuestionIds)
        {
            let question = this.questions[questionKey];

            if (question.hasConditions) {
                let condition = question.conditions[0]; // for some reason this is an array, but there can only be one top condition (otherwise we would miss an operator)
                this._evaluateCondition(condition, l_chosenAnswers, l_notChosenAnswers);

                question.canBeDisplayed = condition.checkAnswered();

                if(!question.canBeDisplayed)
                {
                    this._removeQuestionAndAnswersFromChosenAnswers(question, l_chosenAnswers);
                }
            }
            
        }
        for(let l_resultKeys of Object.keys(this.results))
        {
            let l_result = this.results[l_resultKeys];
            if(l_result.hasConditions)
            {
                let l_condition = l_result.conditions[0]; // see the questions above
                this._evaluateCondition(l_condition, l_chosenAnswers);

                l_result.canBeDisplayed = l_condition.checkAnswered();
            }
        }
    }
    
    _removeQuestionAndAnswersFromChosenAnswers(p_question, p_chosenAnswers)
    {
        let l_questionIndex = p_chosenAnswers.indexOf(p_question.id);
        if(l_questionIndex >= 0)
        {
            p_chosenAnswers.splice(l_questionIndex, 1);
            if(!p_question.alwaysAnswered)
            {
                p_question.answered = false;
            }

            for(let answerId of Object.keys(p_question.answers))
            {
                let l_answerIndex = p_chosenAnswers.indexOf(answerId);
                if(l_answerIndex >= 0)
                {
                    p_chosenAnswers.splice(l_answerIndex, 1);
                    p_question.answers[answerId].chosen = false;
                }
            }

        }
    }

    setCurrentAnsweredQuestion(p_questionId) {
        let question = this.questions[p_questionId];

        let l_noAnswerChosen = true; // this part is for questions with answermode multiple (it is possible, that the given answer is removed and no answers left)
        for (let answerKey of Object.keys(question.answers)) {
            let answer = question.answers[answerKey];
            if (answer.chosen) {
                l_noAnswerChosen = false;
            }
        }
        if (l_noAnswerChosen) {
            if(question.answerMode === 'single') {
                question.answered = false;
            }
            let l_questionBefore;
            for (let questionKey of Object.keys(this.questions)) {
                let l_question = this.questions[questionKey]
                if (l_question.displayed && (l_question.order < question.order)) {
                    l_questionBefore = l_question;
                }
            }
            this.currentAnsweredQuestion = l_questionBefore?l_questionBefore.order:0;
        } else {
            this.currentAnsweredQuestion = this.questions[p_questionId].order;
        }
    }

    


    _evaluateCondition(p_condition, p_chosenAnswers, p_notChosenAnswers) {
        if (p_condition.checkConditionsMet(p_chosenAnswers, p_notChosenAnswers)) {
            this.conditionsMet.push(p_condition);
        }
    }

    _evaluatePrimitiveResultCondition(p_condition, p_id) {
        if (p_condition.referencedAnswer === p_id) {
            this.isResultShown = true;
        }
    }

    _sortQuestionIds(p_questions)
    {
        return Object.keys(p_questions).sort(function(a,b) {
            return a.order - b.order;
        });
    }

    getChosenAnswers() {
        /**
         * It is possible, that the user changes the answers he gives. 
         * If a question is not displayed anymore, the answer has to be deleted, so conditions are not met anymore.
         * Keep this in mind, when getting the chosen answers!
         */
        let l_chosenAnswers = []; // this contains ids, of questions with chosen answers as well as answers
        for (let questionKey of Object.keys(this.questions)) {
            let question = this.questions[questionKey];
            if(question.canBeDisplayed)
            {
                let l_questionAnswered = false;
                for (let answerKey of Object.keys(question.answers)) {
                    let answer = question.answers[answerKey];
                    if (answer.chosen) {
                        l_questionAnswered = true;
                        l_chosenAnswers.push(answer.id);
                    }
                }
                if (l_questionAnswered) {
                    l_chosenAnswers.push(question.id);
                }
            }
        }
        return l_chosenAnswers;
    }

    getAllAnswers() {
        /**
         * For evaluation of negative conditions I need both chosen and not chosen answers.
         * It is possible, that the user changes the answers he gives. 
         * If a question is not displayed anymore, the answer has to be deleted, so conditions are not met anymore.
         * Keep this in mind, when getting the chosen answers!
         */
        let l_chosenAnswers = []; // this contains ids, of questions with chosen answers as well as answers
        let l_notChosenAnswers = []; // this contains not chosen answers

        for (let questionKey of Object.keys(this.questions)) {
            let question = this.questions[questionKey];
            if(question.canBeDisplayed)
            {
                let l_questionAnswered = false;
                let l_notChosenAnswersForQuestion = [];
                for (let answerKey of Object.keys(question.answers)) {
                    let answer = question.answers[answerKey];
                    if (answer.chosen) {
                        l_questionAnswered = true;
                        l_chosenAnswers.push(answer.id);
                    } else {
                        l_notChosenAnswersForQuestion.push(answer.id);
                    }
                }
                if (l_questionAnswered) {
                    l_chosenAnswers.push(question.id);
                    l_notChosenAnswers.push(...l_notChosenAnswersForQuestion);
                }
            }
        }
        return {chosen: l_chosenAnswers, notChosen: l_notChosenAnswers};
    }

    /**
     * returns the questions that are on the current page. 
     * A Page starts at the beginning (or with a question that has firstOnPage) and ends at the question before a question with firstOnPage=true
     * It is possible, that a questionnaire consists of one page
     */
    getQuestionIdsOnPage(p_questionOrder) {
        //let l_currentQuestionToAnswerIndex = this.currentQuestionToAnswer > 0? this.currentQuestionToAnswer - 1: 0; 

        let l_questionOnPage = this.sortedQuestionIds.indexOf(this.getQuestionByOrder(p_questionOrder >0? p_questionOrder : p_questionOrder+1)?this.getQuestionByOrder(p_questionOrder >0? p_questionOrder : p_questionOrder+1).id:-1);
        let l_startOfPage = 0;
        let l_endOfPage = this.sortedQuestionIds.length; 

        for(let i = l_questionOnPage; i >= 0; i--)
        {   
            let l_question = this.questions[this.sortedQuestionIds[i]];
            if(l_question.firstOnPage)
            {
                l_startOfPage = this.sortedQuestionIds.indexOf(l_question.id);
                break;
            }
        }
        for(let k = l_questionOnPage; k < this.sortedQuestionIds.length; k++)
        {
            let l_question = this.questions[this.sortedQuestionIds[k]];
            if(l_question && l_question.firstOnPage && (k!==l_questionOnPage)) //(k!==l_questionOnPage) if l_questionOnPage is the first on the page
            {
                l_endOfPage = this.sortedQuestionIds.indexOf(l_question.id) - 1; // use the index in the sorted Ids array again and also the question before the one that is firstOnPage
                break;
            }
        }
        return this.sortedQuestionIds.slice(l_startOfPage, (l_endOfPage + 1) );
    }

    setCurrentQuestionToAnswer()
    {
        for(let i = 0; i < this.sortedQuestionIds.length; i++)
        {
            let l_question = this.questions[this.sortedQuestionIds[i]];
            // either the question is not answered but is mandatory (answermode = single)
            // or the question has extra data that is not answered
            // here are two cases possible:
            // the extra data is mandatory and has to be answered or the extra data is not mandatory, but the question was already displayed on a page,
            // so now we can go to the next question (because we displayed it, but the user chose not to answer it)
            if( 
                (
                    (!l_question.answered && l_question.answerMode === 'single' || l_question.alwaysAnswered && !l_question.previousPage) 
                    || 
                    (l_question.hasExtraData && !l_question.extraDataAnswered && (l_question.extraDataMandatory || !l_question.previousPage))
                ) 
                && 
                l_question.canBeDisplayed
            )  
            {
                this.currentQuestionToAnswer = l_question.order; 
                return;
            }
        }
        // there is no question that has to be answered 
        this.currentQuestionToAnswer = -1;
    }

    getQuestionByOrder(p_order)
    {
        return Object.values(this.questions).find(element => element.order === p_order);
    }
}