export class Util {

    static getElementByIdWithQuery(p_elementId, p_basicConfiguration) {
        let l_content = document.getElementById(p_basicConfiguration.content);
        let l_result;
        if(isNaN(p_elementId)) {
            l_result = l_content.querySelector(`#${p_elementId}`);
        } else {
            let l_firstDig = String(p_elementId)[0];
            let l_lastDigits = p_elementId.substring(1);
            l_result = l_content.querySelector(`#\\3${l_firstDig} ${l_lastDigits}`);
        }
        if(l_result) {
            return l_result;
        }
    }

    static displayElement(p_elementId, p_basicConfiguration) {
        return this._switchCSSClasses(p_elementId, p_basicConfiguration.css_element_visible, p_basicConfiguration.css_element_invisible);
    }

    static hideElement(p_elementId, p_basicConfiguration) {
        return this._switchCSSClasses(p_elementId, p_basicConfiguration.css_element_invisible, p_basicConfiguration.css_element_visible);
    }

    static _switchCSSClasses(p_elementId, p_classToAdd, p_classToRemove) {
        this._switchCSSClassesForElement(p_elementId, p_classToAdd, p_classToRemove);
    }

    static _switchCSSClassesForElement(p_element, p_classToAdd, p_classToRemove) {
        if (p_element) {
            let l_elementClassList = p_element.classList;
            if(p_element.classList) {
                if (p_classToAdd) {
                    l_elementClassList.add(p_classToAdd);
                }
                if (l_elementClassList.contains(p_classToRemove)) {
                    l_elementClassList.remove(p_classToRemove);
                }
            }
            return p_element;
        }
    }

    static _removeCSSClass(p_element, p_classToRemove) {
        if (p_element) {
            let l_elementClassList = p_element.classList;
            if (l_elementClassList.contains(p_classToRemove)) {
                l_elementClassList.remove(p_classToRemove);
            }
        }
    }

    static setModalFocus(p_basicConfiguration) {
        let l_bodyClassList = document.getElementsByTagName('body')[0];
        this._switchCSSClassesForElement(l_bodyClassList, p_basicConfiguration.css_body_modal_focus, p_basicConfiguration.css_body_focus);
    }

    static unsetModalFocus(p_basicConfiguration) {
        let l_bodyClassList = document.getElementsByTagName('body')[0];
        this._switchCSSClassesForElement(l_bodyClassList, p_basicConfiguration.css_body_focus, p_basicConfiguration.css_body_modal_focus);
    }

    static createRowCol(p_colContainingElement, p_basicConfiguration) {
        let l_row = document.createElement('DIV', p_basicConfiguration.css_row);
        let l_col = document.createElement('DIV', p_basicConfiguration.css_column);
        l_col.appendChild(p_colContainingElement);
        l_row.appendChild(l_col);
        return l_row;
    }

    static getParamsEncodedInURL(p_guid, p_mandator, p_language) {
        let l_b64EncodedParams = btoa('guid=' + p_guid + "&mandator=" + p_mandator + "&lang=" + p_language);
        let l_search = window.location.search;

        //check if already doc param is set in url, if yes, replace
        if (l_search != null && l_search.length > 0 && l_search.indexOf('doc=') > 0) {
            if (l_search.indexOf('?') == 0) {
                l_search = l_search.substring(1);
            }
            let params = this.splitParameterValuesInUrl(l_search);
            params['doc'] = l_b64EncodedParams;
            let l_resultURL = this.getAbsolutePath() + '?';
            for (var key in params) {
                l_resultURL += key + '=' + params[key] + '&';
            }
            return l_resultURL.substring(0, l_resultURL.length - 1);
        }
        // if doc is not set as param in url, check whether it is the first param or an additional one
        else {
            let l_prefix = '?doc=';
            if (window.location.search.length > 0) {
                l_prefix = '&doc=';
            }
            return window.location.href + l_prefix + l_b64EncodedParams;
        }
    }

    static storeConfigInBrowserCache(p_config) {        
        sessionStorage.setItem('config', JSON.stringify(p_config));
    }

    static loadConfigFromBrowserCache() {
        if (sessionStorage.getItem('config')) {
            let parsed = JSON.parse(sessionStorage.getItem('config'));
            parsed.__proto__ = new BasicConfiguration;
            return parsed;
        }
    }

    static decodeParams() {
        let l_url = window.location.search;
        if (l_url && l_url.length > 0 && l_url.indexOf('?') == 0) {
            try {
                let query = l_url.substring(1);
                // get doc url param
                query = this.splitParameterValuesInUrl(query)['doc'];
                if (query != undefined) {
                    // parse base64 encoded values
                    query = atob(query);
                    return this.splitParameterValuesInUrl(query);
                }
            } catch (error) {
                console.log("Error resolving encoded parameter from url %s", error);
            }
        }
        return {};
    }

    static getAbsolutePath() {
        return window.location.origin + window.location.pathname;
    }

    static splitParameterValuesInUrl(p_url) {
        let parameters = {};
        let keyValues = p_url.split(/&/);
        for (let keyValue of keyValues) {
            let keyValuePairs = keyValue.split(/=/);
            let key = keyValuePairs[0];
            let value = keyValuePairs[1];
            parameters[key] = value;
        }
        return parameters;
    }
}