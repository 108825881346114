import { Util } from "../utility/util";


export class DefaultDocumentParser {
    constructor(p_basicConfiguration) {
        this.basicConfiguration = p_basicConfiguration;
    }

    parse(p_text) {
        let parser = new DOMParser();
        if (p_text && p_text != "") {
            let l_rootElement = parser.parseFromString(p_text, "text/html");
            this._parse(l_rootElement);
            return l_rootElement.body.innerHTML;
        }
        else {
            return p_text;
        }
    }

    runAfterRenderingFunctions(p_guid, p_config, p_lang, p_renderDocumentFunction) {
        this.setKFirstMediaPoolImages();
        if (p_config.useNLSLabels) {
            this.setNLSLabels();
        } else {
            this.setLabels(p_lang);
        }
        if (!(p_config.embeddedInKFirst)) {
            this.addImageListeners(p_guid);
            this.addReferenceListeners(p_renderDocumentFunction, p_lang);
            this.setKFirstMediaPoolImages();

            let rootElement = document.getElementById(p_guid);

            // adds listeners to doc sections and the table of content
            this._parseForAfterRenderElements(rootElement);
        }
    } 

    /**
     * iterate over all html elements which have the class "resolve_kfirst_media_pool" indicating, that the image should be taken from infoboard
     * and replace the src value of the image defined in attribute "kfirst_media_pool_item"
     */
    setKFirstMediaPoolImages() {
        let replaceImages = document.getElementsByClassName("resolve_kfirst_media_pool");
        for (let element of replaceImages) {
            element.src = this.basicConfiguration.getKFirstMediaPoolURL(element.getAttribute("kfirst_media_pool_item"));
            //console.log("element %o, src %o", element, this.basicConfiguration.getKFirstMediaPoolURL(element.getAttribute("kfirst_media_pool_item")));
        }
    }

    /**
     * iterate over all html elements which have the class "resolve_nls_label" indicating, that the value / label should be taken from KCenter labels 
     * and replace the value / text with the value defined in attribute "nls_label"
     */
    setNLSLabels() {
        let replaceLabels = document.getElementsByClassName("resolve_nls_label");
        for (let element of replaceLabels) {
            let label = this.basicConfiguration.nls_label_cache[element.getAttribute("nls_label")];
            if (label == undefined) {
                label = 'Cannot get label for property ' + element.getAttribute("nls_label");
            }
            switch (element.tagName) {
                case ('INPUT'): element.value = label; break;
                default: element.textContent = label;
            }
        }
    }

    setLabels(p_lang) {
        let l_languageToUse = p_lang ? p_lang : this.basicConfiguration.defaultLanguage;

        let replaceLabels = document.getElementsByClassName(this.basicConfiguration.DOCUMENT_LABEL_CLASS);
        for (let element of replaceLabels) {
            let attribute;
            try{
                attribute = element.getAttribute(this.basicConfiguration.DOCUMENT_LABEL_ATTRIBUTE_NAME);
                let label = this.basicConfiguration.getTranslatedLabel(l_languageToUse, attribute);
                switch (element.tagName) {
                    case ('INPUT'): element.value = label; break;
                    default: element.textContent = label;
                }
            } catch (error) {
                console.error("Can't find translation for: " + attribute);
            }
            
        }
    }

    _imageZoom(p_imageSrc, p_config) {
        let l_modalDiv = document.getElementById(p_config.thumbnailModalContainer);
        let l_zoomImage = l_modalDiv.getElementsByTagName('img')[0];
        if (l_zoomImage) {
            l_zoomImage.src = p_imageSrc;
            Util.displayElement(p_config.thumbnailModalContainer, p_config);
            Util.setModalFocus(p_config);
        }
    }

    registerImageForContainer(p_containerWithImages) {
        let self = this;
        let images = p_containerWithImages.getElementsByTagName('img');
        if (images) {
            for (let image of images) {
                image.addEventListener('click', self._imageZoom.bind(null, image.src, self.basicConfiguration), false);
            }
        }
    }

    addImageListeners(p_guid) {
        let self = this;
        let l_modalDiv = document.getElementById(this.basicConfiguration.thumbnailModalContainer);
        if (l_modalDiv) {
            let _closeZoom = function () {
                console.kc_log("Close zoom");
                Util.hideElement(self.basicConfiguration.thumbnailModalContainer, self.basicConfiguration);
                Util.unsetModalFocus(self.basicConfiguration);
            }
            self.registerImageForContainer(document.getElementById(p_guid));
            let close = l_modalDiv.getElementsByClassName('close')[0];
            close.addEventListener('click', _closeZoom);
        }
    }

    addReferenceListeners(p_render, p_lang) {
        let self = this;
        let references = document.getElementsByClassName('referenceLink');
        for (let reference of references) {
            reference.onclick = function (p_evt) {
                // read the original url from the documentReference attribute
                let l_url = reference.attributes.getNamedItem("documentReference").value;
                let l_guid;
                let l_mandator;
                // l_url can either be a docshow url or have the format guid:<guid>
                // one is, if the document is loaded form KCenter, the other if it is loaded form KFirst API
                if(l_url.indexOf('docShow.do') >= 0) {
                    let l_properties = self._replaceAndSplitProperties(l_url, 'docShow.do?');
                    l_guid = l_properties['entity.GUID'];
                    l_mandator = l_properties['mandatorKey'];
                } else {
                    l_guid = l_url.replace("guid:","");
                }
                p_evt.preventDefault();
                p_render(l_guid, l_mandator, p_lang);
            }
        }
    }    

    /**
     * Takes a node and recursively checks the children (elements not nodes to avoid working on plain text nodes for example)
     * if they need some preprocessing done before being rendered into the template.
     * @param {*} p_element 
     */
    _parse(p_element) {
        if (p_element.childNodes) {
            for (let i = 0; i < p_element.childNodes.length; i++) {
                this._parse(p_element.childNodes[i])
            }
        }

        switch (p_element.tagName) {
            case 'P':
                {
                    this._handleParagraph(p_element)
                    break;
                }
            case 'DIV':
                {
                    if (p_element.attributes && p_element.attributes.getNamedItem("media-data-url")) {
                        this._handleUSUVideoDIV(p_element)
                    } else if (p_element.classList.contains('docSection')) {
                        //this._handleDocumentSection(p_element);
                    } else if (p_element.classList.contains('chapter')) {
                        //this._handleDocumentTocChapter(p_element);
                    }
                    break;
                }
            case 'IMG':
                {
                    this._handleImageTag(p_element);
                    break;
                }
            case 'A': {
                this._handleAttachment(p_element);
                break;
            }
            case 'table': {

            }
        }
    }

    _parseForAfterRenderElements(p_element) {
        if (p_element.childNodes) {
            for (let i = 0; i < p_element.childNodes.length; i++) {
                this._parseForAfterRenderElements(p_element.childNodes[i])
            }
        }

        
        if(p_element.tagName === 'DIV') {
            if (p_element.classList.contains('docSection')) {
                this._handleDocumentSection(p_element);
            } else if (p_element.classList.contains('chapter')) {
                this._handleDocumentTocChapter(p_element);
            }
        }
    }

    /**
     * the html of the document needs to be rendered for this function to run
     * @param {*} p_url 
     */
    handleThumbnail(p_url) {
        let thumbnailDiv = document.getElementById('kc_modal_thumbnail');
        thumbnailDiv.img.src = p_url;
        Util.displayElement(thumbnailDiv.id, this.basicConfiguration);
    }

    /**
     * Handle link element and replace the relative with an absolute url to retrieve the document from service connector
     * @param {*} p_element 
     */
    _handleAttachment(p_element) {
        // resolve absolute URL
        let l_src = p_element.attributes.getNamedItem('href');

        console.kc_log("Handling attachment %o", p_element);

        //check if it is anchor
        if (l_src != null) {
            l_src = l_src.value;

            // it is a document reference -> remove href, it will later be resolved via JS
            if (l_src.includes('docShow.do') && (l_src.indexOf('docShow.do') === 0)) {
                p_element.setAttribute('documentReference', l_src);
                if(this.basicConfiguration.newDocNoNewTab) {
                    p_element.removeAttribute('href');
                    p_element.setAttribute('href', '');
                    p_element.removeAttribute('target');
                } else {
                    p_element.removeAttribute('href');
                    p_element.setAttribute('href', '');
                }
            }

            // check if link has data-kfirst-doc attribute (KFirst transforms links)
            if (l_src.startsWith('#')) {
                let l_linkGUID = p_element.attributes.getNamedItem('data-kfirst-doc');

                if (l_linkGUID != null && l_linkGUID.value != null) {
                    // in case the link should be rendered outside of KFirst - resolve full url for href
                    if (!this.basicConfiguration.embeddedInKFirst) {

                        // if the link should not be renderered in a new tab, add empty href and target, only add that it is a document reference, rest will be handled later
                        if(this.basicConfiguration.newDocNoNewTab) {
                            p_element.setAttribute('documentReference', l_linkGUID.value);
                            p_element.setAttribute('href', '');
                        } else {
                            let newTabHREF = Util.getParamsEncodedInURL(l_linkGUID.value, this.basicConfiguration.defaultMandator, this.basicConfiguration.defaultLanguage);
                            // Util.storeConfigInBrowserCache(this.basicConfiguration);
                            p_element.setAttribute('href', newTabHREF);
                            p_element.setAttribute('target', '_blank');
                        }
                    }
                }
                else {
                    // Given <a> is the link to the anchor within the same document, do nothing
                }
            }
             else {
                // reset href attribute with resolved URL
                if(!this.basicConfiguration.newDocNoNewTab) {
                    p_element.setAttribute('href', this._checkImageSrc(l_src));
                }
            }
        }
        else {
            // Given <a> reference is the anchor element itself, do nothing 
        }
        
        if(this.basicConfiguration.openLinksInExternalTab) {
            p_element.setAttribute('target', '_blank'); 
        }
    }

    /**
     * Takes a paragraph Element and replaces it by a div element.
     * The new div element has a new class that is called "p" so when styles are developed, it can be devided wether a div was a p or a div in the first place.
     * @param {*} p_element The Paragraph Element that needs to be replaced by a div
     */
    _handleParagraph(p_element) {
        let l_attributes = p_element.attributes;
        let l_div = document.createElement('DIV');
        l_div.innerHTML = p_element.innerHTML;
        for (let attribute of l_attributes) {
            l_div.setAttribute(attribute.name, attribute.value)
        }
        l_div.classList.add("p");
        p_element.parentElement.replaceChild(l_div, p_element);
    }

    /**
     * creates an html5 video tag from a div that is used in infoboard to display a video. Also wraps the video tag in a div for style reasons
     * @param {*} p_element a div element with a media-url from KCenter
     */
    _handleUSUVideoDIV(p_element) {
        let l_videoDiv = document.createElement('DIV');
        let l_attributes = p_element.attributes;

        let l_videoSrc = l_attributes.getNamedItem('media-data-url').value;
        let l_isIframeEmbeddeVideo = false;

        if ((l_isIframeEmbeddeVideo = l_videoSrc.includes('youtube.'))) {
            if (l_videoSrc.includes('/watch?v=')) {
                l_videoSrc = l_videoSrc.replace('/watch?v=', '/embed/');
            }
        } else if ((l_isIframeEmbeddeVideo = l_videoSrc.includes('/youtu.be/'))) {
            l_videoSrc = l_videoSrc.replace('/youtu.be/', '/www.youtube.com/embed/');
        } else if (l_isIframeEmbeddeVideo = l_videoSrc.includes('vimeo.')) {
            if (l_videoSrc.includes('//vimeo.com/')) {
                l_videoSrc = l_videoSrcreplace('//vimeo.com/', '//player.vimeo.com/video//');
            }
        }

        if (l_isIframeEmbeddeVideo) {
            let l_videoIframe = document.createElement('iframe');
            l_videoIframe.setAttribute('src', l_videoSrc);
            l_videoIframe.setAttribute('webkitAllowFullScreen', 'webkitAllowFullScreen');
            l_videoIframe.setAttribute('mozallowfullscreen', 'mozallowfullscreen');
            l_videoIframe.setAttribute('frameborder', '0');
            if (l_attributes.getNamedItem('tid'))
                l_videoIframe.setAttribute("tid", l_attributes.getNamedItem('tid').value)

            l_videoDiv.appendChild(l_videoIframe);
        } else {
            let l_videoTag = document.createElement('video');
            l_videoTag.setAttribute("src", l_videoSrc);
            l_videoTag.setAttribute("controls", true);
            if (l_attributes.getNamedItem('tid'))
                l_videoTag.setAttribute("tid", l_attributes.getNamedItem('tid').value)
            if (l_attributes.getNamedItem('media-data-poster'))
                l_videoTag.setAttribute('poster', l_attributes.getNamedItem('media-data-poster').value)

            l_videoDiv.appendChild(l_videoTag);
        }

        l_videoDiv.classList.add('videoWrapper');
        l_videoDiv.classList.add('usu-video')

        p_element.parentElement.replaceChild(l_videoDiv, p_element);
    }

    /**
     * The image element from kcenter gets stripped of most of it's unnecessary attributes and wrapped in a div
     * @param {*} p_element an img element 
     */
    _handleImageTag(p_element) {

        console.kc_log("element %o", p_element);

        let l_attributes = p_element.attributes;
        let l_div = document.createElement('DIV');
        let l_newIMG = document.createElement('IMG');

        // only add responsive if no thumbnail
        if (l_attributes.getNamedItem('isthumbnail') && l_attributes.getNamedItem('isthumbnail').value === 'false') {
            l_newIMG.classList.add('responsive');
        }

        if (l_attributes.getNamedItem('src')) {
            l_newIMG.setAttribute('src', this._checkImageSrc(l_attributes.getNamedItem('src').value));
        }
        if (l_attributes.getNamedItem('title')) {
            l_newIMG.setAttribute('alt', l_attributes.getNamedItem('title').value)
            l_newIMG.setAttribute('title', l_attributes.getNamedItem('title').value)
        }
        if (l_attributes.getNamedItem('height')) {
            l_newIMG.setAttribute('height', l_attributes.getNamedItem('height').value);
        }
        if (l_attributes.getNamedItem('width')) {
            l_newIMG.setAttribute('width', l_attributes.getNamedItem('width').value);
        }
        if (l_attributes.getNamedItem('style')) {
            l_newIMG.setAttribute('style', l_attributes.getNamedItem('style').value);
        }
        if (l_attributes.getNamedItem('id')) {
            l_newIMG.setAttribute('id', l_attributes.getNamedItem('id').value);
        }
        
        l_div.classList.add('imageWrapper');
        l_div.appendChild(l_newIMG);

        p_element.parentElement.replaceChild(l_div, p_element);
    }

    _checkImageSrc(p_src) {
        p_src = p_src.trim();
        if (p_src.includes('openAttachment.do') && (p_src.indexOf('openAttachment.do') === 0)) {
            // todo: check for cases when src doesn't start with openAttachment.do 
            // check if '/' and '?' are part of any of the strings
            // http://localhost/serviceconnector/services/rest/documents/3/attachments/1?lang=de&gk_providerid=jwt-smartlink-shared&gk_token=&mandator=MANDATOR_USU
            // openAttachment.do?entity.id=3&mandatorKey=MANDATOR_USU&att.author=-%2C+Administrator&att.description=&att.fileName=image_0_1549964036965.png&att.mimeType=image%2Fpng&att.creationDate=1549964037126&att.fileSize=2132626&att.id=1&att.isPartOfHTML=true&att.isDocument=false?gk_providerid=jwt-eternal&gk_token=

            if(this.basicConfiguration.clientJWT)
            {
                return this._createAttachmentURL(this.basicConfiguration.serviceconnectorURL,
                this.basicConfiguration.getClientJWTAsURLParam(), //TODO: figure out when there is a ClientJWT, what it is used for and why it was added.
                this._replaceAndSplitProperties(p_src, 'openAttachment.do?'));
            } else
            {
                return this._createAttachmentURL(this.basicConfiguration.serviceconnectorURL,
                    this.basicConfiguration.authenticationURLFragment,
                    this._replaceAndSplitProperties(p_src, 'openAttachment.do?'));
            }
        }
        if(p_src.includes('knowledgebase/images') || p_src.startsWith('images'))
        {
            // set a transparent gif as src, because I have no idea how to reliably get those images
            // TODO: figure out a way to get those images here.
            if(p_src.endsWith('new.gif')) {
                return "data:image/gif;base64,R0lGODlhGQAOAPcAAAAAAIAAAACAAICAAAAAgIAAgACAgMDAwMDcwKbK8EAgAGAgAIAgAKAgAMAgAOAgAABAACBAAEBAAGBAAIBAAKBAAMBAAOBAAABgACBgAEBgAGBgAIBgAKBgAMBgAOBgAACAACCAAECAAGCAAICAAKCAAMCAAOCAAACgACCgAECgAGCgAICgAKCgAMCgAOCgAADAACDAAEDAAGDAAIDAAKDAAMDAAODAAADgACDgAEDgAGDgAIDgAKDgAMDgAODgAAAAQCAAQEAAQGAAQIAAQKAAQMAAQOAAQAAgQCAgQEAgQGAgQIAgQKAgQMAgQOAgQABAQCBAQEBAQGBAQIBAQKBAQMBAQOBAQABgQCBgQEBgQGBgQIBgQKBgQMBgQOBgQACAQCCAQECAQGCAQICAQKCAQMCAQOCAQACgQCCgQECgQGCgQICgQKCgQMCgQOCgQADAQCDAQEDAQGDAQIDAQKDAQMDAQODAQADgQCDgQEDgQGDgQIDgQKDgQMDgQODgQAAAgCAAgEAAgGAAgIAAgKAAgMAAgOAAgAAggCAggEAggGAggIAggKAggMAggOAggABAgCBAgEBAgGBAgIBAgKBAgMBAgOBAgABggCBggEBggGBggIBggKBggMBggOBggACAgCCAgECAgGCAgICAgKCAgMCAgOCAgACggCCggECggGCggICggKCggMCggOCggADAgCDAgEDAgGDAgIDAgKDAgMDAgODAgADggCDggEDggGDggIDggKDggMDggODggAAAwCAAwEAAwGAAwIAAwKAAwMAAwOAAwAAgwCAgwEAgwGAgwIAgwKAgwMAgwOAgwABAwCBAwEBAwGBAwIBAwKBAwMBAwOBAwABgwCBgwEBgwGBgwIBgwKBgwMBgwOBgwACAwCCAwECAwGCAwICAwKCAwMCAwOCAwACgwCCgwECgwGCgwICgwKCgwMCgwOCgwADAwCDAwEDAwGDAwIDAwKDAwP/78KCgpICAgP8AAAD/AP//AAAA//8A/wD//////yH5BAEAAE8ALAAAAAAZAA4ABwhpAJ8IHEiwoMGDAvcRVFhwH0OEDh0+iciQosSJCS1q3EhxoEMAEUECGCmSIsiLCUd+3HdyZUiUKVnKJLlS5cmFMlmWDElSZcOcMy2ebOnR5UyaOVtW5MjUYsKMEYtK7Ihw4sOnOKtq1RoQADs=";
            } else if(p_src.endsWith('global_doc.gif')) {
                return "data:image/gif;base64,R0lGODlhEQATAPcAAAAAAIAAAACAAICAAAAAgIAAgACAgMDAwMDcwKbK8DRLY5Gjuaezxr/I19Ha7OPs+/Dw7vv8/QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAP/78KCgpICAgP8AAAD/AP//AAAA//8A/wD//////yH5BAEAAPkALAAAAAARABMABwhsAPMJHEiwoMGDCBMmbMCQIYOHDRA2iECxIgMFEQ0yqFhxQQOMGjlSXKCgZEiRERaQPIlyZcGNKB+4JAiT4wOZCgwuiOlg5sCdNh/0zFkQaEWhDXwKNBpBqIOkRAnuVEmVatSfJbNqvaqwq9eAADs=";
            } else {
                return "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
            }
        }
        return this.basicConfiguration.addClientJWTAsURLParam(p_src);
    }

    _createAttachmentURL(p_serviceconnectorURL, p_authenticationURLFragment, p_propertiesFromSrc) {
        return p_serviceconnectorURL + '/' +
            'services/rest/documents/' +
            p_propertiesFromSrc['entity.id'] + '/' +
            'attachments/' +
            p_propertiesFromSrc['att.id'] + '?' +
            'mandator=' + p_propertiesFromSrc['mandatorKey'] + '&' +
            p_authenticationURLFragment;
    }

    _replaceAndSplitProperties(p_srcAttribute, p_toReplace) {
        if (p_srcAttribute) {
            p_srcAttribute = p_srcAttribute.replace(p_toReplace, '');
            let l_properties = {};
            p_srcAttribute.split('&').forEach(element => {
                let l_splitElement = element.split('=')
                l_properties[l_splitElement[0]] = l_splitElement[1];
            });
            return l_properties;
        }
    }

    /**
     * the html of the document needs to be rendered for this function to run
     * @param {*} p_sectionDiv 
     */ 
    _handleDocumentSection(p_sectionDiv) {
        let toggleSectionBodyFunction = function (p_evt) {
            let l_sectionHeader = p_evt.target;

            l_sectionHeader.parentElement.classList.contains('sectionOpened') ?
            l_sectionHeader.parentElement.classList.remove('sectionOpened') :
            l_sectionHeader.parentElement.classList.add('sectionOpened');
        };

        p_sectionDiv.getElementsByClassName('docSectionHeader')[0].addEventListener('click', toggleSectionBodyFunction);
    }

    /**
     * the html of the document needs to be rendered for this function to run
     * @param {} p_sectionDiv 
     */
    _handleDocumentTocChapter(p_sectionDiv) {
        let toggleSectionBodyFunction = function (p_section) {
            if(p_section) {
                p_section.classList.contains('sectionOpened') ?
                p_section.classList.remove('sectionOpened') :
                p_section.classList.add('sectionOpened');
            }
        };

        let l_chapterLink = p_sectionDiv.getElementById('chapter-link');
        if(l_chapterLink) {
            let l_chapterId = l_chapterLink.getAttribute('data-chapter-id');
            if(l_chapterId) {
                let l_chapterSection = document.getElementById(chapterId);
                if(l_chapterSection) {
                    l_chapterSection.scrollIntoView();
                }
            }
        }

        let l_docSectionHeader = p_sectionDiv.getElementsByClassName('docSectionHeader')[0];
        if(l_docSectionHeader) {
            l_docSectionHeader.addEventListener('click', toggleSectionBodyFunction.bind(this, l_docSectionHeader.parentElement));

            let l_docSectionTitle = p_sectionDiv.getElementsByClassName('docSectionTitle')[0];
            if(l_docSectionTitle) {
                l_docSectionTitle.addEventListener('click', toggleSectionBodyFunction.bind(this, l_docSectionHeader.parentElement));
            }
        }
    }

    _handleTable(p_element) {
        let l_border = p_element.attributes.border?p_element.attributes.border.value:0;
        let l_cellspacing = p_element.attributes.cellspacing?p_element.attributes.cellspacing.value:0;
        let l_style = p_element.attributes.style?p_element.attributes.style.value:"";

        l_style += "border: " + l_border + "px solid;";
        l_style += "border-spacing: " + l_cellspacing + "px;";
        
        p_element.attributes.style.value = l_style;
    }
}
