import { DefaultDocumentParser } from "../parser/default_documentparser";

export class ActiveTopicRenderer {

    constructor(p_jstemplatecenter, p_basicConfiguration) {
        this.jstemplatecenter = p_jstemplatecenter;
        this.basicConfiguration = p_basicConfiguration;
        this.allReferences = [];
    }

    render(p_document, p_templateName) {

        this._transformAbsoluteLinks(p_document);
        let activeStructureRef = this._getActiveStructureRef(p_document);
        p_document.activeStructureDocRef = activeStructureRef;

        return this.jstemplatecenter.templates[p_templateName](p_document);
    }

    _getActiveStructureRef(p_document) {
        if (p_document.references) {
            for (let ix = 0; ix < p_document.references.length; ix++) {
                const oneRef = p_document.references[ix];

                if (oneRef.doctypeclass === 'USU_TopicOverview') {
                    return oneRef;
                }
            }
        }
        return null;
    }

    // Transform absolute path to relative at document references inside of active search blocks
    _transformAbsoluteLinks(p_document) {
        if (p_document.blocks) {
            for (let ix = 0; ix < p_document.blocks.length; ix++) {
                const oneBlock = p_document.blocks[ix];

                if (oneBlock.componentType === 'activeTopicSearchBlock' && oneBlock.references) {
                    oneBlock.visibleReferences = 0;
                    for (let ix2 = 0; ix2 < oneBlock.references.length; ix2++) {
                        const oneRef = oneBlock.references[ix2];
        
                        if (oneRef.componentType === 'documentReference') {
                            let absolutePath = oneRef.path;
                            let pos = absolutePath.indexOf('/docShow.do');
                            if (pos >= 0) {
                                // Trim all before 'docShow.do'
                                // This is <scheme>://<host>:<port><context_path>/
                                oneRef.path = absolutePath.substr(pos + 1);
                            }                            
                        }

                        // Duplicate references can be hidden via CSS class
                        if(!this.allReferences.includes(oneRef.id)) {
                            this.allReferences.push(oneRef.id);
                            oneBlock.visibleReferences++;
                        } else {
                            oneRef.hidden = true;
                        }
                    }
                }
            }
        }
    }
}