export class ErrorPatternNode {
    constructor(p_nodeId, p_type, p_docId) {
        this.nodeId = p_nodeId;
        this.type = p_type;
        this.docId = p_docId;
        this.answers = [];
        this.isEntry = false;

        this.title = '';
        this.content = undefined;
        this.summary = undefined;
        this.contentLang = '';
        this.componentType = '';
        this.guid = '';
        this.mandatorKey = '';
        //last answerId the user chose
        this.lastChoosenAnswerId = '';
        //only in case of solutions
        this.isLooseSolution = '';
        this.data_key = undefined;
        this.customFields =undefined;
    }
}