
export class MetaEngine {
    constructor(p_metaDocument, p_basicConfiguration) {
        this.rawDocument = p_metaDocument;
        this.basicConfiguration = p_basicConfiguration;
        this.handleSections(p_metaDocument, p_basicConfiguration);
    }

    handleSections(p_document, p_basicConfiguration) {
        if(p_document && p_document.sections && p_document.sections.length > 0) {
            for(let i = 0; i < p_document.sections.length; i++) {
                let subdocument = p_document.sections[i];
                try {
                    let engine = p_basicConfiguration.documentRenderer.getActiveDocumentEngine(subdocument, p_basicConfiguration, true);
                    if(p_basicConfiguration.activeDocumentEngineFromRendererCallback) {
                        p_basicConfiguration.activeDocumentEngineFromRendererCallback(engine);
                    }
                } catch(e) {

                }

                this.handleSections(subdocument, p_basicConfiguration);
            }
        }
    }
}