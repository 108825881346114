import { Option } from "./option";
import { Util } from "../../utility/util";

export class MultipleSelect  {
    
    /**
     * Creates the object to provide the logic for a multiple select dropdown.
     * @param {*} p_id id of the <select>
     * @param {*} p_selectCallback function to call if options(s) are selected
     * @param {*} p_unselectCallback function to call if option(s) are deselected
     */
    constructor(p_id, p_selectCallback, p_unselectCallback, p_questionId, p_basicConfiguration) {
        let l_elem = Util.getElementByIdWithQuery(p_id, p_basicConfiguration);
        if(l_elem) {
            this.select = l_elem;
        } else {
            throw new Error("Couln't find SELECT element with id: " + l_elem);
        }

        if(this.select.multiple) {
            this.select.multiple = false;
        }

        this.selectCallback = p_selectCallback;
        this.unselectCallback = p_unselectCallback;
        this.questionId = p_questionId;

        this.selectedOptions = [];
        this.allAvailableOptions = this.availableOptions = this._parseOptionsfromSelect();

        this.selectedOptionsHolder = this._createSelectedOptionsHolder();

        this.select.parentElement.insertBefore(this.selectedOptionsHolder, this.select);

        this._addEventListenerToSelect();
    }

    unselectAll() {
        for(let option of this.selectedOptions) {
            this.selectedOptions = this.selectedOptions.filter(element => element.value !== option.value)
            this.availableOptions.push(option);

            this._resortOptionsInSelect();
        }

        this.selectedOptionsHolder.innerHTML = "";
    }

    _addEventListenerToSelect() {
        let self = this;
        let l_listenerFunction = function(p_event) {
            let l_options = p_event.target.options;
            let l_index = l_options.selectedIndex;
            // get selected option

            if (l_options && l_index && l_options.length > 0) {
                self._selectOption(l_options[l_index], l_index);
            }            
        }
        this.select.addEventListener('change', l_listenerFunction);
    }

    _parseOptionsfromSelect() {
        let l_optionsFromSelect = this.select.options;

        let l_options = [];

        for(let i = 0; i < l_optionsFromSelect.length; i++) {
            let option = l_optionsFromSelect[i];
            l_options.push(
                new Option(option.value, option.label, i)
            );
        }

        return l_options;
    }

    _selectOption(p_option, p_optionIndex) {
        if(!p_option.value !== "") {
            let l_chosenOption = this.availableOptions.find(option => option.value === p_option.value);
            this.selectCallback(l_chosenOption, this.questionId);

            this.selectedOptions.push(l_chosenOption);
            this._createSelectedOptionsHTML(l_chosenOption);
            this.select.remove(p_optionIndex);
            this.availableOptions.splice(this.availableOptions.indexOf(l_chosenOption), 1);
        }
    }

    _unselectOption(p_optionId)
    {
        let l_chosenOption = this.selectedOptions.find(option => option.value === p_optionId);
        this.unselectCallback(l_chosenOption, this.questionId);
        this.selectedOptions = this.selectedOptions.filter(element => element.value !== l_chosenOption.value)
        this.availableOptions.push(l_chosenOption);

        this._resortOptionsInSelect();
    }

    _createSelectedOptionsHTML(p_option) {
        let l_optionDiv = document.createElement('DIV');
        l_optionDiv.id = p_option.value;
        l_optionDiv.classList.add('selectedOption');

        let l_optionText = document.createElement('p');
        l_optionText.innerHTML = p_option.label;
        l_optionText.classList.add('optionText');

        let l_removeSymbol = document.createElement('SPAN');
        l_removeSymbol.classList.add('removeSelectedOption');
        l_removeSymbol.innerHTML = 'X';

        let self = this;
        let l_listenerFunction = function(evt) {
            let l_holderDiv = evt.target.parentElement;
            self._unselectOption(l_holderDiv.id);
            
            l_holderDiv.remove();
        }

        l_removeSymbol.addEventListener('click', l_listenerFunction);

        l_optionDiv.appendChild(l_optionText);
        l_optionDiv.appendChild(l_removeSymbol);

        this.selectedOptionsHolder.appendChild(l_optionDiv);
    }

    _createSelectedOptionsHolder() {
        let l_holder  = document.createElement('DIV');

        l_holder.id = this.select.id + '_options_holder';
        l_holder.classList.add('optionsHolder');

        return l_holder;
    }

    _resortOptionsInSelect() {
        let l_htmlOptions = this.select.options;

        while(l_htmlOptions.length > 0) {
            l_htmlOptions.remove(0);
        }
        
        let l_sortedOptions = this.availableOptions.sort( (option1, option2) => option1.order - option2.order)
        for(let option of l_sortedOptions) {
            let optionHtml = document.createElement("option");
            optionHtml.value = option.value;
            optionHtml.label = option.label;
            optionHtml.text = option.label;

            this.select.add(optionHtml);
        }
    }
}