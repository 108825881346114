import default_german_labels from "./default_labels/de.json";
import default_english_labels from "./default_labels/en.json";
import { Translate } from "../utility/translate.js";
import { DefaultADHelper } from "../ADRenderer/defaultADHelper";

/**
 * Global configuration element 
 * TODO: Add configuration from object, so we can save a json with a configuration (or multiple to switch)
 */
export class BasicConfiguration {    
    /**
     * @param {*} p_sendRequests if true, the backend will be requested
     * @param {*} p_knowledgeGuideServiceURL url to knowledgeGuideService REST-Endpoint
     * @param {*} p_documentServiceURL url to documentService REST-Endpoint
     * @param {*} p_authenticationToken authentication token for accessing the REST-Endpoints
     * @param {*} p_knowledgeBaseURL url to KBase to access attachments/images
     */
    constructor(p_sendRequests, p_knowledgeGuideServiceURL, p_documentServiceURL, p_kcenterServiceURL, p_authenticationURLFragment, p_serviceconnectorURL, p_kfirstURL) {
        
        /**
		 * Set global debug mode.
         * We can't add console properties earlier as cordova for ios platform replaces console object.
		 */		
      	console.debugging = false;
        if (!console.kc_log) {
            console.kc_log = function () {
                if (!console.debugging) return;
                console.log.apply(this, arguments);
            }
        }

        this.sendRequests = p_sendRequests;
        this.knowledgeGuideServiceURL = p_knowledgeGuideServiceURL;
        this.documentServiceURL = p_documentServiceURL;
        this.kcenterServiceURL = p_kcenterServiceURL;
        this.serviceconnectorURL = p_serviceconnectorURL;
        this.authenticationURLFragment = p_authenticationURLFragment;
        this.kfirstURL = p_kfirstURL;
        this.clientJWT = undefined;
        this.jwtUseAuthorizationHeader = true;
        this._initCSSDefaults();
        this._initButtonDefaults();
        /* error pattern specific configuration */
        this._initErrorPatternDefaults();
        this.nls_label_cache = [];
        this.embeddedInKFirst = false;
        this.embeddedInSmartLink = false;
        this.defaultLanguage = 'en';
        this.defaultMandator = 'MANDATOR_USU';
        /* customFields */
        this.customFields = {};
        this.kfirstMediaPoolPath = "kfirst-widget/api/widget/mediapool/";
        this.mediaPoolAuthenticationURLFragment = undefined;
        this.addMediaPoolAddAuthenticationToken = false;

        /* 
            List of all OrgUnit entries, the user belongs to.
            If the value is not set (null), all OU question conditions return true.        
        */
        this.userOrgUnitGuids = null;

        // set this to true, if you want to use the labels from infoboard
        this.useNLSLabels = false;
        //add default labels, they can be replaced later
        this.labels = {
            "de": default_german_labels,
            "en": default_english_labels
        }
        this.DOCUMENT_LABEL_CLASS = "resolve_doc_label";
        this.DOCUMENT_LABEL_ATTRIBUTE_NAME = "doc_label";

        this.showFinishButton = true;

        this.credentialsMode = 'same-origin';
        this.csrfToken = undefined;

        this.defaultHeaders = {};

        // DOCRENDER-140:
        this.openLinksInExternalTab = false;

        this.newDocNoNewTab = false;

        // wright questionnaire to Valuestore settings
        this.sendQuestionnaireToValueStore = false;
        this.valueStoreSessionId = '';
        this.valueStoreURL = '';

        // DOCRENDER-143/-144
        this.extradataFormats = {
            'Usu_ExtraStepGuiType_Text': '.*', // any character allowed 
            'Usu_ExtraStepGuiType_TextArea': '.*', // any character allowed
            'Usu_ExtraStepGuiType_Upload': '',
            'Usu_ExtraStepGuiType_Datetime': '(\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2})',  // regex to match datetime
            'Usu_ExtraStepGuiType_Date': '(\\d{4}-\\d{2}-\\d{2})', // regex to match date
            'Usu_ExtraStepGuiType_KBValueList':''
        }
        this.questionMandatoryInfoHolderClass = "question_mandatoryInfoHolder"
        this.questionMandatoryNotAnsweredInfoHolderClass = "question_mandatoryNotAnsweredInfoHolder";
        this.extraDataMandatoryInfoHolderClass = "extraData_manadatoryInfoHolder";
        this.extraDataMandatoryNotAnsweredInfoHolderClass = "extraData_mandatoryNotAnsweredInfoHolder";
        this.extraDataFormatExpectationClass = "extraData_FormatExpectation";
        this.extraDataFormatExpectationNotMetClass = "extraData_FormatExpectationNotMet";

        // Support class for new ActiveDocument renderer
        this.adHelper = new DefaultADHelper(this);
        // The final callback of documentrenderer.renderDocument(...) function
        this.finalRendererCallback = undefined;
    }

    /**
     * adds translated labels for one language
     * @param {*} p_languageTag example de, en, ...
     * @param {*} p_translatedLabels in the form of the default labels json (de.json, en.json)
     */
    addTranslatedLabels(p_languageTag, p_translatedLabels)
    {
        this.labels[p_languageTag] = p_translatedLabels;
    }

    /**
     * Adds translated labels for multiple languages
     * @param {*} p_translations object containting languagetags and translatedlabels
     * e.g.
     * {
     *  "en": {translatedLabelsObject},
     *  "de": {translatedLabelsObject}
     * }
     */
    addAllTranslatedLabels(p_translations)
    {
        for( let key of Object.keys(p_translations) )
        {
            this.labels[key] = p_translations[key];
        }
    }

    getTranslatedLabels(p_languageTag)
    {
        return this.labels[p_languageTag];
    }

    getTranslatedLabel(p_languageTag, p_label)
    {
        return new Translate().getTranslatedLabel(p_languageTag, p_label, this.labels);
    }

    _initCSSDefaults() {
        /* css style classes for hide/display elements */
        this.css_element_invisible = 'kc_invisible';
        this.css_element_visible = 'kc_visible';
        this.css_body_modal_focus = 'body_modal_focus';
        this.css_body_focus = 'body_focus';
        this.css_row = 'row';
        this.css_column = 'col';
        /* id of element, where rendered html will be placed */
        this.content = 'content';
        this.kbotContent = 'bot-doc-viewer';
    }

    _initButtonDefaults() {
        /* configurable buttons */
        this.resetButtonId = 'btn_reset';
        this.startButtonId = 'btn_start';
        this.startButtonAction = undefined;
        this.finishButtonId = 'btn_finish';
        this.finishButtonAction = undefined;
        this.afterFinishCallback = undefined;
        this.showResultsListener = undefined;
        this.automaticQuestionAction = undefined;
        this.updateHistoryCallback = undefined;
        this.restartButtonId = 'btn_restart';
        this.restartButtonFromHistoryId = 'btn_restart_from_history';
        this.previousButtonId = 'btn_previous';
        this.previousButtonAction = undefined;
        this.renderDocumentFunction = undefined;
        this.nextButtonId = 'btn_next';
        this.nextButtonAction = undefined;

        this.historyBackButtonId = 'history-reset-button';
    }

    _initErrorPatternDefaults() {
        this.css_ep_answer = 'ep_answer';
        this.css_ep_mark_answer = 'mark_answer_selected';
        /* configurable divs */
        this.questionContainer = 'ep_questionContainer';
        this.questionContainerTitle = 'ep_questionContainerTitle';
        this.questionContainerAnswers = 'ep_questionContainerAnswers';
        this.questionContent = 'ep_questionContent';
        this.questionSummary = 'ep_questionSummary';
        this.historyContainer = 'ep_historyContainer';
        this.historyTreeContainer = 'historyTreeDiv';
        this.solutionsContainer = 'ep_solutionsContainer';
        this.possibleAnswersContainer = 'ep_possibleAnswers';
        this.restartModalContainer = 'ep_restartModalContainer';
        this.thumbnailModalContainer = 'kc_modal_thumbnail';
        /* document service params */
        this.includeReferencesUpToLevel = 'all';
        /* if set to true, loose solutions will be rendered on a solution link */
        this.renderLooseSolutions = true;
        /* if set to true, solutions link will be rendered in the answers */
        this.renderSolutionsLink = true;
        /* if set to false, next button won't be rendered */
        this.renderNextButton = true;
        
        // Skip the start button and start the guided dialog automatically
        this.autoStart = false;        
    }

    /**
     * set a custom id for the html restart button
     * @param {*} p_restartButtonId
     */
    setRestartButtonId(p_restartButtonId) {
        this.restartButtonId = p_restartButtonId;
    }

    /**
     * set a custom id for the html start button
     * @param {*} p_startButtonId
     */
    setStartButtonId(p_startButtonId) {
        this.startButtonId = p_startButtonId;
    }

    /**
     * add additional action (function) before triggering "start"
     * @param {*} p_startButtonAction 
     */
    setStartButtonAction(p_startButtonAction) {
        this.startButtonAction = p_startButtonAction;
    }

    /**
     * set a custom id for the html finish button
     * @param {*} p_finishButtonId
     */
    setFinishButtonId(p_finishButtonId) {
        this.finishButtonId = p_finishButtonId;
    }

    /**
     * add additional action (function) after triggering "finish"
     * @param {*} p_finishButtonAction 
     */
    setFinishButtonAction(p_finishButtonAction) {
        this.finishButtonAction = p_finishButtonAction;
    }

    /**
     * add callback (function) after dialog is finished
     * @param {*} p_afterFinishCallback 
     */
     setAfterFinishCallback(p_afterFinishCallback) {
        this.afterFinishCallback = p_afterFinishCallback;
    }

    /**
     * show/hide the finish button. If not explicitly set to false, the finish button will be displayed.
     * @param {boolean} p_show 
     */
    setShowFinishButton(p_show) {
        this.showFinishButton = p_show;
    }

    /**
     * showResults Callback (function) that is called after questionnaire results are shown.
     * @param {*} p_showResultsCallback
     */
    setShowResultsCallback(p_showResultsCallback) {
        this.showResultsCallback = p_showResultsCallback;
    }
    /**
     * add action (function) that is triggered by an automatic question
     * @param {*} p_automaticQuestionAction 
     */
    setAutomaticQuestionAction(p_automaticQuestionAction) {
        this.automaticQuestionAction = p_automaticQuestionAction;
    }

    /**
     * Set a custom render history method.
     * @param {*} p_updateHistoryCallback
     */
    setUpdateHistoryCallback(p_updateHistoryCallback) {
        this.updateHistoryCallback = p_updateHistoryCallback;
    }

    /**
     * set a custom id for the html next button
     * @param {*} p_nextButtonId
     */
    setNextButtonId(p_nextButtonId) {
        this.nextButtonId = p_nextButtonId;
    }

    /**
     * add additional action (function) after triggering "next"
     * @param {*} p_nextButtonAction 
     */
    setNextButtonAction(p_nextButtonAction) {
        this.nextButtonAction = p_nextButtonAction;
    }

    /**
     * set a custom id for the html previous button
     * @param {*} p_nextButtonId
     */
    setPreviousButtonId(p_previousButtonId) {
        this.previousButtonId = p_previousButtonId;
    }

    /**
     * add additional action (function) after triggering "previous"
     * @param {*} p_previousButtonAction 
     */
    setPreviousButtonAction(p_previousButtonAction) {
        this.previousButtonAction = p_previousButtonAction;
    }

    /**
     * add custom function for parsing of content, for when content (that has to be parsed because of images, references ...) is inserted via JavaScript.
     * The function gets the content which needs to be parsed before being renderer and should return the parsed content.
     * @param {*} p_parsingFunction 
     */
    setCustomParsingFunction(p_parsingFunction) {
        this.customParsingFunction = p_parsingFunction;
    }

    /**
     * defines, if the client JWT should be added as header, default is true
     * if false, then the token is added as "sectoken" param to the url
     * @param {*} p_jwtUseAuthorizationHeader 
     */
    setJWTUseAuthorizationHeader(p_jwtUseAuthorizationHeader) {
        this.jwtUseAuthorizationHeader = p_jwtUseAuthorizationHeader;
    }

    /**
     * set client JWT in case of secured accesses to KFIRST API and the provided interfaces
     * @param {*} p_jwt 
     */
    setClientJWT(p_jwt) {
        this.clientJWT = p_jwt;
    }

    setDefaultLanguage(p_lang) {
        this.defaultLanguage = p_lang;
    }

    setDefaultMandator(p_mandator) {
        this.defaultMandator = p_mandator;
    }

    /**
     * default is true
     * defines, if loose solutions should be rendered when a solution is reached
     * @param {*} p_renderLooseSolutions 
     */
    setRenderLooseSolutions(p_renderLooseSolutions) {
        this.renderLooseSolutions = p_renderLooseSolutions;
    }

    /**
     * default is true
     * defines, if solutions link should be rendered in the answers
     * @param {*} p_renderSolutionsLink 
     */
    setRenderSolutionsLink(p_renderSolutionsLink) {
        this.renderSolutionsLink = p_renderSolutionsLink;
    }

    /**
     * Enable autostart in error patterns
     * @param {*} p_autoStart
     */
     setAutoStart(p_autoStart) {
        this.autoStart = p_autoStart;
    }

    /**
     * define, which custom fields should be rendered
     * {customFieldName: [{"de":wert}, {"en":value},...]}
     * @param {*} p_customFields 
     */
    setCustomFields(p_customFields) {
        this.customFields = p_customFields;
    }

    getClientJWT() {
        if (this.clientJWT != undefined && this.clientJWT != '') {
            return this.clientJWT;
        }
        return undefined;
    }

    getClientJWTAsURLParam() {
        if(this.getClientJWT() != undefined) {
            return "sectoken=" + this.getClientJWT();
        } else {
            return undefined;
        }
    }

    addClientJWTAsURLParam(p_url) {
        if (p_url) {
            let l_tokenUrl = this.getClientJWTAsURLParam();
            if(l_tokenUrl != undefined) {
                if (p_url.includes('&')) {
                    return p_url + '&' + l_tokenUrl;
                }
                return p_url + '?' + l_tokenUrl;
            }
        }
        return p_url;
    }

    getKFirstMediaPoolURL(p_mediaItemId) {
        let url = this.kfirstURL;
        if (!url.endsWith('/')) {
            url += '/';
        }
        url += this.kfirstMediaPoolPath;
        url += p_mediaItemId;
        url += '?';
        if (this.mediaPoolAuthenticationURLFragment) {
            url += this.mediaPoolAuthenticationURLFragment;
        }
        else {
            url += this.authenticationURLFragment;
        }
        if (this.addMediaPoolAddAuthenticationToken) {
            url += '&sectoken=' + this.clientJWT;
        }
        return url;
    }

    /**
     * Callback for when the renderer returns the html that can be inserted into the DOM or inserted the html into the DOM (depending on rendering function)
     * The callback will be called with the document JSON as parameter
     * Compared to the button functions, this does not allow for data manipulation (so if this function returns something, this is not checked)
     * @param {*} p_documentRenderedAction 
     */
    setDocumentRenderedAction(p_documentRenderedAction) {
        this.documentRenderedAction = p_documentRenderedAction;
    }

    /**
     * Callback for when the renderer processes a step in an active document engine
     * The callback will be called with a JSON parameter containing information about the current document, the structe/content of this JSON might vary depending on the document type 
     * Compared to the button functions, this does not allow for data manipulation (so if this function returns something, this is not checked)
     * @param {*} p_processStepAction 
     */
    setProcessStepAction(p_processStepAction) {
        this.processStepAction = p_processStepAction;
    }

    /**
     * callback that is called, when the docrenderer itself creates the active document engine.
     * @param {*} p_callback 
     */
    setActiveDocumentEngineFromRendererCallback(p_callback) {
        this.activeDocumentEngineFromRendererCallback = p_callback;
    }

    getCredentialsMode() {
        return this.credentialsMode;
    }

    getCsrfToken() {
        return this.csrfToken;
    }

    setSendQuestionnaireToValueStore(p_send) {
        this.sendQuestionnaireToValueStore = p_send;
    }

    setValueStoreSessionId(p_Id) {
        this.valueStoreSessionId = p_Id;
    }

    setValueStoreURL(p_url) {
        this.valueStoreURL = p_url;
    }

    setADHelper(p_Function) {
        this.adHelper = p_Function;
    }

    /**
     * The object, which contains all headers, that will be sent on every request the DocumentRenderer will execute
     * @returns The default header object
     */
    getDefaultHeaders() {
        return this.defaultHeaders;
    }

    /**
     * Add default headers, which will be sent on every request the DocumentRenderer will execute
     * @param {*} p_key The key of the header
     * @param {*} p_value The value of the header
     */
    addDefaultHeader(p_key, p_value) {
        if(p_key && p_value) {
            this.defaultHeaders[p_key] = p_value;
        }
    }

    setCustomErrorPatternHistoryContainer(p_id) {
        if(p_id) {
            this.historyContainer = p_id;
            this.historyTreeContainer = p_id;
        }
    }

    static withServiceconnectorBackend(p_scUrl, p_auth) {
        let scServicesUrl = p_scUrl + '/services/rest';
        let scDocumentsUrl = scServicesUrl + '/documents';
        return new BasicConfiguration(true, scDocumentsUrl + '/dialog', scServicesUrl, scDocumentsUrl, p_auth || '', p_scUrl);
    }
}

/**
 * default configuration for local development
 */
export const DEFAULT_DEV_CONFIGURATION = new BasicConfiguration(
    true,
    'http://localhost:3000/KGuideServiceMock',
    'http://localhost:3000/DocumentServiceMock',
    'http://localhost:3000/KnowledgecenterServiceMock',
    'gk_providerid=jwt-eternal&gk_token=eyJ0eXAiOiJKV1',
);

/**
* default configuration for local development
*/
export const DEFAULT_TEST_CONFIGURATION = new BasicConfiguration(
    true,
    'http://ustst076/knowledgeguide2/dialog',
    'http://ustst076/knowledgebase/documentservice',
    'http://ustst076/knowledgecenter',
    'gk_providerid=jwt-eternal&gk_token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIvYWRtaW4iLCJpc3MiOiJ1c3RzdDA3NiIsImlhdCI6MTU1MjM5OTMyNCwianRpIjoiMWMwZjdlNzgtODRlMS00ODM5LWI2MWUtY2M4NzU2YzM1M2VkOjIifQ.OIU51nMz5ZpzNgy3WRUoScT6Ivk6Iqod4weiA8DMGaU',
    'http://ustst076/serviceconnector'
);

