import { DefaultDocumentParser } from "../parser/default_documentparser";

export class MetaDocumentRenderer {

    constructor(p_jstemplatecenter, p_basicConfiguration) {
        this.jstemplatecenter = p_jstemplatecenter;
        this.basicConfiguration = p_basicConfiguration;
    }

     render(p_document, p_templateName) {

        let sections = [];
        this._handleSections(p_document, sections);
        p_document.sections = sections;

        p_document.tocVisible = (p_document.showTOC == 'true')? true : false;

        return this.jstemplatecenter.templates[p_templateName](p_document);
    }


    _handleSections(p_document, sections)
    {
        let references = p_document.references;

        if(!references) {
            return;
        }

        // Do not sort references. They are already sorted by KBase.

        let index = 1;
        let prefix = (p_document.chapter && p_document.chapter.length > 0)? p_document.chapter + '.' : '';
        for (let documentRef of references) {

            documentRef.chapter = prefix + (documentRef.order || index);
            let chapters = documentRef.chapter.split('.');
            documentRef.chapterPath = chapters.join('_');

            let initialDepth = (p_document.depth != undefined)? (p_document.depth + 1) : 0;
            documentRef.depth = initialDepth + (chapters.length - 1);

            let l_docParser = new DefaultDocumentParser(this.basicConfiguration);
            if (!(this.basicConfiguration.embeddedInKFirst)) {
                if (this.basicConfiguration.customParsingFunction) {
                    this.basicConfiguration.customParsingFunction(documentRef.content, documentRef.componentType).then(p_parsedContent => {
                        documentRef.content = p_parsedContent;
                        const contentElement = document.getElementById(documentRef.chapterPath + '-body');
                        if(contentElement)
                        {
                            contentElement.innerHTML = p_parsedContent;
                        }
                    }).catch((e) => {
                        console.log(e);
                    });
                } else {
                    documentRef.content = l_docParser.parse(documentRef.content);
                    //l_docParser.addImageListeners(document.guid);
                    //l_docParser.registerImageForContainer(document.content);
                    //l_docParser.addReferenceListeners(this.basicConfiguration.renderDocumentFunction, this.baseErrorPattern.master);
                }
            } else {
                if (this.basicConfiguration.customParsingFunction) {
                    this.basicConfiguration.customParsingFunction(documentRef.content, documentRef.componentType).then(p_parsedContent => {
                        documentRef.content = p_parsedContent;
                        const contentElement = document.getElementById(documentRef.chapterPath + '-body');
                        if(contentElement)
                        {
                            contentElement.innerHTML = p_parsedContent;
                        }
                    }).catch(() => {
                        // Do nothing
                    });
                } else {
                        // Do nothing
                }
            }

            sections.push(documentRef);

            if(documentRef.componentType == 'meta') {
                this._handleSections(documentRef, sections);
            } else {
                let l_renderedContent = this.basicConfiguration.documentRenderer.renderWithDefaultTemplate(documentRef);
                if (this.basicConfiguration.customParsingFunction) {
                    documentRef.content = l_renderedContent;
                    this.basicConfiguration.customParsingFunction(l_renderedContent, documentRef.componentType).then(p_parsedContent => {
                        documentRef.content = p_parsedContent;

                        const contentElement = document.getElementById(documentRef.chapterPath + '-body');
                        if(contentElement)
                        {
                            contentElement.innerHTML = p_parsedContent;
                        }
                    },() => {
                    });
                }
            }
            documentRef.componentType = documentRef.componentType;
            index++;
        }
    }
}