import { EXTRA_DATA_STATE } from "../engines/questionnaire/extraDataState";

export class Questionnairerenderer {
    constructor(p_jstemplatecenter, p_basicConfiguration) {
        this.jstemplatecenter = p_jstemplatecenter;
        this.basicConfiguration = p_basicConfiguration;
    }

    render(p_document, p_templateName) {

        this.contentLang = p_document.contentLang;
        try {
            this._prepareDocument(p_document);
        }catch (e) {
            console.log(e)
        }
        

        return this.jstemplatecenter.templates[p_templateName](p_document);
    }

    _prepareDocument(p_document) {
        this._prepareQuestions(p_document);
    }

    _prepareQuestions(p_document) {
        let questions = [];
        if(p_document.questions) {
            questions =  p_document.questions;
        } else if(p_document[0] && p_document[0].questions) {
            questions = p_document[0].questions;
        }

        for (let question of questions) {
            let answers = question['answers'];
            let preparedAnswers = [];
            let labelForPrefixArray = [];
            question.labelForPrefix = undefined; // Holds ID prefix of control addressed by label element
            question.labelForPrefix2 = undefined; // Holds ID prefix of another control addressed by label element

            if (answers) {
                if(question.answerMode === "single") {
                    question.mandatory = true;
                    question.mandatoryLabel = this._getQuestionIsMandatoryToAnwerLabelText(p_document.contentLang);
                } else {
                    question.mandatory = false;
                }

                if(!question['stepGui_answerLook'] || question['stepGui_answerLook'] === 'system' || question['stepGui_answerLook'] === 'box')
                {
                    for (let answer of answers) {
                        let preparedAnswer = answer;

                        preparedAnswer.content = this._prepareBoxAndSystemAnswerHTML(answer, question['stepGui_answerLook'], question['answerMode'], question['id']);

                        preparedAnswers.push(preparedAnswer);
                    }
                } else if(question['stepGui_answerLook'] === 'dropDown')
                {
                    question.preparedDropDown = this._prepareDropDownAnswerHTML(question);
                    labelForPrefixArray.push('answerDropDown');
                }
            }
            question.hasExtraData = false;
            if (question['stepGui_extraDataType'] !== "") {
                if(question.stepGui_extraDataMandatory === "true") {
                    question.extraDataMandatory = true;
                    question.extraDataMandatoryLabel = this._getExtraDataIsMandatoryToAnwerLabelText(p_document.contentLang);
                }
                question.hasExtraData = true;
                question.expectedExtraDataFormat = this._getExpectedExtraDataFormatLabel(p_document.contentLang, question['stepGui_extraDataType']);
                question.preparedExtraDataType = this._prepareExtraDataType(question['stepGui_extraDataType'], question.id).outerHTML;

                switch(question['stepGui_extraDataType']) {
                    case 'Usu_ExtraStepGuiType_Text':
                    case 'Usu_ExtraStepGuiType_TextArea':
                    case 'Usu_ExtraStepGuiType_Date':
                    case 'Usu_ExtraStepGuiType_Datetime':
                        labelForPrefixArray.push('extraDataInput');
                        break;
                    case 'Usu_ExtraStepGuiType_KBValueList':
                        labelForPrefixArray.push('extraDataDropdown');
                        break;
                    case 'Usu_ExtraStepGuiType_Upload':
                        question.isExtraDataUpload = true;
                        break;
                }
            }
            question.preparedAnswers = preparedAnswers;
            if (labelForPrefixArray.length > 0) {
                question.labelForPrefix = labelForPrefixArray[0];
            }
            if (labelForPrefixArray.length > 1) {
                question.labelForPrefix2 = labelForPrefixArray[1];
            }
        }
    }

    _prepareBoxAndSystemAnswerHTML(p_answer, p_answerStyle, p_answerMode, p_questionId) {
        let l_wrapperDiv = this._createBoxAndSystemAnswerWrapper(p_answerMode, p_answer, p_answerStyle, p_questionId);

        if (p_answerMode === 'multiple') {
            l_wrapperDiv.appendChild(
                this._createBoxAndSystemAnswerCheckbox(p_answer)
            );
        } else {
            l_wrapperDiv.appendChild(
                this._createBoxAndSystemAnswerRadio(p_answer, p_questionId)
            );
        }

        l_wrapperDiv.appendChild(
            this._createBoxAndSystemAnswerLabel(p_answerStyle, p_answer)
        );
        // return as html string not html js object, so handlebars can handle it as html instead of an object it seems to have problems with..
        return l_wrapperDiv.outerHTML;
    }

    _createBoxAndSystemAnswerWrapper(p_answerMode, p_answer, p_answerStyle, p_questionId) {
        let l_wrapperDiv = document.createElement('DIV');

        l_wrapperDiv.id = p_answer['id'] + '_wrapper';
        l_wrapperDiv.classList.add(p_answerMode);
        l_wrapperDiv.classList.add('answerwrapper');
        l_wrapperDiv.classList.add(p_answerStyle);


        l_wrapperDiv.setAttribute('answerorder', p_answer['order']);
        l_wrapperDiv.setAttribute('questionId', p_questionId);

        return l_wrapperDiv;
    }

    _createBoxAndSystemAnswerCheckbox(p_answer) {
        let l_answerInput = document.createElement('INPUT');

        l_answerInput.id = p_answer['id'];
        l_answerInput.setAttribute('value', p_answer['id'])
        l_answerInput.setAttribute('type', 'checkbox');

        return l_answerInput;
    }

    _createBoxAndSystemAnswerRadio(p_answer, p_questionId) {
        let l_answerInput = document.createElement('INPUT');

        l_answerInput.id = p_answer['id'];
        l_answerInput.setAttribute('value', p_answer['id'])
        l_answerInput.setAttribute('type', 'radio');
        l_answerInput.setAttribute('name', p_questionId);

        return l_answerInput
    }

    _createBoxAndSystemAnswerLabel(p_answerStyle, p_answer) {
        let l_answerLabel = document.createElement('LABEL');
        l_answerLabel.classList.add(p_answerStyle);
        l_answerLabel.classList.add('answerLabel');
        l_answerLabel.setAttribute('for', p_answer['id']);
        l_answerLabel.textContent = p_answer['title'];

        return l_answerLabel;
    }

    _prepareExtraDataType(p_extraDataType, p_questionId) {
        let l_extraDataType;
        switch (p_extraDataType) {
            case 'Usu_ExtraStepGuiType_Text':
                l_extraDataType = this._prepareExtraDataTypeInput(p_questionId, 'text');
                break;
            case 'Usu_ExtraStepGuiType_TextArea':
                l_extraDataType = this._prepareExtraDataTypeTextArea(p_questionId);
                break;
            case 'Usu_ExtraStepGuiType_Upload':
                let l_fileButtonLabel = this._getExpectedExtraDataFormatLabel(this.contentLang, p_extraDataType);
                l_extraDataType = this._prepareExtraDataTypeFileUpload(p_questionId, l_fileButtonLabel);
                break;
            case 'Usu_ExtraStepGuiType_Datetime':
                l_extraDataType = this._prepareExtraDataTypeInput(p_questionId, 'datetime-local');
                break;
            case 'Usu_ExtraStepGuiType_Date':
                l_extraDataType = this._prepareExtraDataTypeInput(p_questionId, 'date');
                break;
            case 'Usu_ExtraStepGuiType_KBValueList':
                l_extraDataType = this._prepareExtraDataTypeDropdown(p_questionId);
                break;
            default:
                l_extraDataType = this._prepareExtraDataTypeDummy();
        }
        return l_extraDataType;
    }

    _prepareExtraDataTypeInput(p_questionId, p_type) {
        let l_extraDataWrapper = document.createElement('DIV');
        l_extraDataWrapper.classList.add('extraDataWrapper');

        let l_extraDataInput = document.createElement('INPUT');
        l_extraDataInput.id = "extraDataInput_" + p_questionId;

        if (p_type != undefined) {
            try {
                l_extraDataInput.type = p_type;
            } catch(e) {
                console.kc_log("IE doesn't support date and datetime");
                l_extraDataInput.type = "text";
            }
        }
        
        let l_extraDataLabel = document.createElement('LABEL');
        l_extraDataLabel.classList.add('screenreader-only');
        l_extraDataLabel.setAttribute('for', l_extraDataInput.id);
        l_extraDataLabel.innerText = l_extraDataInput.type;
        
        l_extraDataInput.classList.add(EXTRA_DATA_STATE.UNTOUCHED);
        l_extraDataWrapper.appendChild(l_extraDataInput);
        l_extraDataWrapper.appendChild(l_extraDataLabel);

        return l_extraDataWrapper;
    }

    _prepareExtraDataTypeTextArea(p_questionId) {
        let l_extraDataWrapper = document.createElement('DIV');
        l_extraDataWrapper.classList.add('extraDataWrapper');
        l_extraDataWrapper.id = "extraDataType_" + p_questionId;

        let l_extraDataTextareaId = "extraDataInput_" + p_questionId;

        let l_extraDataLabel = document.createElement('LABEL');
        l_extraDataLabel.classList.add('screenreader-only');
        l_extraDataLabel.setAttribute('for', l_extraDataTextareaId);
        l_extraDataLabel.innerText = 'text area';

        let l_extraDataTextarea = document.createElement('TEXTAREA');
        l_extraDataTextarea.id = l_extraDataTextareaId;
        l_extraDataTextarea.classList.add(EXTRA_DATA_STATE.UNTOUCHED);
        l_extraDataWrapper.appendChild(l_extraDataTextarea);
        l_extraDataWrapper.appendChild(l_extraDataLabel);

        return l_extraDataWrapper;
    }

    _prepareExtraDataTypeDropdown(p_questionId) {
        let l_extraDataWrapper = document.createElement('DIV');
        l_extraDataWrapper.classList.add('extraDataWrapper');
        l_extraDataWrapper.id = "extraDataType_" + p_questionId;

        let l_extraDataDropdown = document.createElement('SELECT');
        l_extraDataDropdown.id = "extraDataDropdown_" + p_questionId;
        l_extraDataDropdown.classList.add(EXTRA_DATA_STATE.UNTOUCHED);

        let l_extraDataLabel = document.createElement('LABEL');
        l_extraDataLabel.classList.add('screenreader-only');
        l_extraDataLabel.setAttribute('for', l_extraDataDropdown.id);
        l_extraDataLabel.innerText = 'drop down';

        l_extraDataWrapper.appendChild(l_extraDataDropdown);
        l_extraDataWrapper.appendChild(l_extraDataLabel);

        return l_extraDataWrapper;
    }

    _prepareExtraDataTypeFileUpload(p_questionId, p_fileButtonLabel) {
        let l_extraDataWrapper = document.createElement('DIV');
        l_extraDataWrapper.classList.add('extraDataWrapper');
        l_extraDataWrapper.id = "extraDataType_" + p_questionId;

        let l_extraDataFileInput = document.createElement('FORM');
        l_extraDataFileInput.method = "post";
        l_extraDataFileInput.enctype = "multipart/form-data";

        let l_extraDataFileInputElement = document.createElement("INPUT");
        l_extraDataFileInputElement.type = "file";
        l_extraDataFileInputElement.multiple = "true";
        l_extraDataFileInputElement.id = "extraDataUpload_" + p_questionId;
        l_extraDataFileInputElement.classList.add(EXTRA_DATA_STATE.UNTOUCHED);
        l_extraDataFileInput.appendChild(l_extraDataFileInputElement);
        // Comply with WCAG
        let l_extraDataFileButtonElement = document.createElement("BUTTON");
        l_extraDataFileButtonElement.classList.add('screenreader-only');
        l_extraDataFileButtonElement.innerText = p_fileButtonLabel;
        l_extraDataFileInput.appendChild(l_extraDataFileButtonElement);

        let l_extraDataLabel = document.createElement('LABEL');
        l_extraDataLabel.classList.add('screenreader-only');
        l_extraDataLabel.setAttribute('for', l_extraDataFileInputElement.id);
        l_extraDataLabel.innerText = p_fileButtonLabel;
        l_extraDataFileInput.appendChild(l_extraDataLabel);

        l_extraDataWrapper.appendChild(l_extraDataFileInput);

        return l_extraDataWrapper;
    }

    _prepareExtraDataTypeDummy() {
        let l_extraDataWrapper = document.createElement('DIV');
        return l_extraDataWrapper;
    }

    _prepareDropDownAnswerHTML(p_question) {
        let l_dropDown = document.createElement('SELECT');
        l_dropDown.id = "answerDropDown_" + p_question.id;
        if(p_question['answerMode'] === 'multiple') {
            l_dropDown.multiple = true;
        } 

        let l_emptyOption = document.createElement('OPTION');
        l_emptyOption.value = "";
        l_emptyOption.text = "";
        l_dropDown.appendChild(l_emptyOption);
        
        for(let answer of p_question.answers) {
            let l_option = document.createElement('OPTION');
            l_option.value = answer.id;
            l_option.text = answer.title;

            l_dropDown.appendChild(l_option);
        }

        return l_dropDown.outerHTML;
    }

    _getQuestionIsMandatoryToAnwerLabelText(p_lang) {
        return this.basicConfiguration.getTranslatedLabel(p_lang, "q.label.mandatoryQuestion");
    }

    _getExtraDataIsMandatoryToAnwerLabelText(p_lang) {
        return this.basicConfiguration.getTranslatedLabel(p_lang, "q.label.mandatoryExtraData");
    }

    _getExpectedExtraDataFormatLabel(p_lang, p_extraDataType) {
        return this.basicConfiguration.getTranslatedLabel(p_lang, "q.label.expectedExtraDataFormats."+p_extraDataType);
    }
}