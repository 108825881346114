export class ConditionalRenderer {
    constructor(p_jstemplatecenter)
    {
        this.jstemplatecenter = p_jstemplatecenter;
    }

    render(p_document, p_templateName)
    {
        this._prepareDocument(p_document);

        return this.jstemplatecenter.templates[p_templateName](p_document);
    }

    _prepareDocument(p_document)
    {
        let questions;
        if(p_document.questions) {
            questions = p_document.questions 
        } else {
            if(p_document[0]) {
                questions = p_document[0].questions;
            }
        }

        if(questions) {
            for (let question of questions) {
                // Do no show OrgUnit questions at the moment (Not needed in SL)
                if(question.stepGui_extraDataType == 'Usu_ExtraStepGuiType_OrganizationUnit') {
                    question.visible = false;
                    continue;
                } else {
                    question.visible = true;
                }
    
                let answers = question['answers'];
                let preparedAnswers = [];
                if (answers) {
                    if(question['stepGui_answerLook'] === 'dropDown') {
                        question.preparedDropDown = this._prepareDropDownAnswerHTML(question);
                    } else {
                        for (let answer of answers) {
                            let preparedAnswer = {}; 
                                
                            Object.assign(preparedAnswer, answer);

                            preparedAnswer.title = this._prepareBoxAndSystemAnswerHTML(answer, question['stepGui_answerLook'], question['answerMode'], question['id']);
        
                            preparedAnswers.push(preparedAnswer);
                        }
                    }
                }
                question.preparedAnswers = preparedAnswers;
            }
        }
        
    }

    _prepareBoxAndSystemAnswerHTML(p_answer, p_answerStyle, p_answerMode, p_questionId) {
        let l_wrapperDiv = this._createBoxAndSystemAnswerWrapper(p_answerMode, p_answer, p_answerStyle);

        if (p_answerMode === 'multiple') {
            l_wrapperDiv.appendChild(
                this._createBoxAndSystemAnswerCheckbox(p_answer)
            );
        } else {
            l_wrapperDiv.appendChild(
                this._createBoxAndSystemAnswerRadio(p_answer, p_questionId)
            );
        }

        l_wrapperDiv.appendChild(
            this._createBoxAndSystemAnswerLabel(p_answerStyle, p_answer)
        );
        // return as html string not html js object, so handlebars can handle it as html instead of an object it seems to have problems with..
        return l_wrapperDiv.outerHTML;
    }

    _prepareDropDownAnswerHTML(p_question) {
        let l_dropDown = document.createElement('SELECT');
        l_dropDown.id = "answerDropDown_" + p_question.id;
        if(p_question['answerMode'] === 'multiple') {
            l_dropDown.multiple = true;
        } 

        let l_emptyOption = document.createElement('OPTION');
        l_emptyOption.value = "";
        l_emptyOption.text = "";
        l_dropDown.appendChild(l_emptyOption);
        
        for(let answer of p_question.answers) {
            let l_option = document.createElement('OPTION');
            l_option.value = answer.id;
            l_option.text = answer.title;

            l_dropDown.appendChild(l_option);
        }

        return l_dropDown.outerHTML;
    }

    _createBoxAndSystemAnswerWrapper(p_answerMode, p_answer, p_answerStyle) {
        let l_wrapperDiv = document.createElement('DIV');

        l_wrapperDiv.id = p_answer['id'] + '_wrapper';
        l_wrapperDiv.classList.add(p_answerMode);
        l_wrapperDiv.classList.add(p_answerStyle);
        l_wrapperDiv.classList.add('answerwrapper');

        l_wrapperDiv.setAttribute('answerorder', p_answer['order']);

        return l_wrapperDiv;
    }

    _createBoxAndSystemAnswerCheckbox(p_answer) {
        let l_answerInput = document.createElement('INPUT');

        l_answerInput.id = p_answer['id'];
        l_answerInput.setAttribute('value', p_answer['id'])
        l_answerInput.setAttribute('type', 'checkbox');

        return l_answerInput;
    }

    _createBoxAndSystemAnswerRadio(p_answer, p_questionId) {
        let l_answerInput = document.createElement('INPUT');

        l_answerInput.id = p_answer['id'];
        l_answerInput.setAttribute('value', p_answer['id'])
        l_answerInput.setAttribute('type', 'radio');
        l_answerInput.setAttribute('name', p_questionId);

        return l_answerInput
    }

    _createBoxAndSystemAnswerLabel(p_answerStyle, p_answer) {
        let l_answerLabel = document.createElement('LABEL');
        l_answerLabel.classList.add(p_answerStyle);
        l_answerLabel.classList.add('answerLabel');
        l_answerLabel.setAttribute('for', p_answer['id']);
        l_answerLabel.textContent = p_answer['title'];

        return l_answerLabel;
    }
}