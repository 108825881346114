import { ExtraData } from "./extradata";

export class Question {
    constructor(p_id, p_sid, p_displayed, p_answers, p_answerMode, p_canBeDisplayed, p_order, p_hasConditions, p_hasExtraData, p_extraDataType, p_extraDataMandatory, p_extraDataValueList, p_conditions, p_firstOnPage, p_final, p_isDropDown) {
        this.id = p_id;
        this.sid = p_sid;
        this.canBeDisplayed = p_canBeDisplayed;
        this.displayed = p_displayed;
        this.answers = p_answers;
        this.answerMode = p_answerMode;
        this.order = p_order;
        this.hasConditions = p_hasConditions;
        this.hasExtraData = p_hasExtraData;
        
        this.extraData = new ExtraData(p_extraDataType, p_extraDataMandatory, p_extraDataValueList)
        
        this.conditions = p_conditions;
        this.firstOnPage = p_firstOnPage;
        this.final = p_final;
        this.answered = false;
        this.previousPage = false;
        this.isDropdown = p_isDropDown;

        if(this.hasExtraData && !this.extraDataMandatory && !(Object.keys(this.answers).length > 0) || !(Object.keys(this.answers).length > 0) )
        {
            this.alwaysAnswered = true;
            this.answered = true;
        } else {
            this.alwaysAnswered = false;
        }
    }
}