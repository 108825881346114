export class DefaultADHelper {

  constructor(p_conf, p_options) {
    this.legacyDRCnf = p_conf; // src/configuration/basicConfiguration.js
    this.options = p_options || { css: '', guiLang: 'en', showDocumentHeader: true };

    // Create ObjectURL with 1x1 px transparent image
    this.dummyImmageObjectUrl = URL.createObjectURL(DefaultADHelper.dataURItoBlob('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACklEQVR42mMAAQAABQABoIJXOQAAAABJRU5ErkJggg=='));
  }

  documentLoader(docContexts) {
    return new Promise((resolve, reject) => {
      const promises = [];
      docContexts.forEach((docContext) => {
        const docRequest = docContext.request;
        let docEvent = {
          guid: docRequest.guid,
          contentLanguage: docRequest.contentLanguage,
          options: docRequest.options,

        };
        promises.push(
          this.getActiveDocument(docEvent)
            .then((data) => (docContext.jsonLdData = data))
            .catch((reason) => (docContext.errorReason = reason))
        );
      });
      Promise.all(promises)
        .then(() => resolve())
        .catch((/* reason is added as errorReason into docContext*/) => {
          resolve();
        });
    });
  }

  customFieldsProvider(docTypeGUID) {
    //I am using document without custom fields. You need to implement here working custom fields collection for the docTypeGUID
    //You can open KCenter and put some breakpoints into file ADEnvironmentCreator.js to see what this function should return exactly
    return [];
  }

  getValueList(valueListKey, tenant) {
    //I am using document without custom fields. You need to implement here working value list collection for the docTypeGUID
    return [];
  }

  urlRewriter(url, context, element, attrName) {
    let urlObj = new URL(url, 'http://localhost'); // Provide dummy base for relative URI
    let searchPar = urlObj.searchParams;

    console.kc_log('AD URL rewriter; el=' + element.tagName + ', atr=' + attrName + ', url=' + url);

    return url;
  }

  // End of definition of AD ENV handlers

  addActiveDocumentElement(params, p_docOptions) {
    const kcDocumentHTML = document.createElement("kc-document");

    // parameters only from AdHelperOptions
    kcDocumentHTML.setAttribute('css', this.options.css);

    // parameters from AdHelperOptions possibly overridden by DocumentOptions
    kcDocumentHTML.setAttribute('language', p_docOptions.guiLang || this.options.guiLang);
    kcDocumentHTML.setAttribute('data-show-document-header', p_docOptions.showDocumentHeader || this.options.showDocumentHeader);

    // parameters only from DocumentOptions
    p_docOptions.anchor && kcDocumentHTML.setAttribute('anchor', p_docOptions.anchor);

    // direct parameters
    kcDocumentHTML.setAttribute('guid', params.guid);
    kcDocumentHTML.setAttribute('mandator', params.mandator || this.legacyDRCnf.defaultMandator);
    kcDocumentHTML.setAttribute('content-language', params.contentLang || this.legacyDRCnf.defaultLanguage);

    if (p_docOptions.linkClickedListener) {
      // Beware that click on image doesn't fire linkclicked custom event
      kcDocumentHTML.addEventListener('linkclicked', p_docOptions.linkClickedListener);
    }

    const contentEl = document.getElementById(this.legacyDRCnf.content);
    if (contentEl) {
      // First remove element's children if exist
      while (contentEl.firstChild) {
        contentEl.removeChild(contentEl.lastChild);
      }
      contentEl.appendChild(kcDocumentHTML);
    } else {
      console.error("DocumentRenderer - Element with ID='" + this.legacyDRCnf.content + "' not found");
    }
  }

  getActiveDocument(docEvent) {
    let baseUrlTxt = this.legacyDRCnf.kcenterServiceURL + '/resources';
    let url = new URL(baseUrlTxt);

    url.searchParams.set('luceneQuery', 'docguid:"' + docEvent.guid + '"');

    let langParam = docEvent.contentLanguage;
    if (this.forcedLanguage != null && this.forcedLanguage.length == 2) {
      langParam = this.forcedLanguage;
    }

    url.searchParams.set('contentLanguage', langParam);
    url.searchParams.set('language', langParam);

    let urlTxt = url.href;

    if (this.legacyDRCnf.authenticationURLFragment) {
      urlTxt += '&' + this.legacyDRCnf.authenticationURLFragment;
    }

    console.kc_log('doDocumentRequest() for: ' + urlTxt);

    return fetch(urlTxt, {
      method: 'GET',
      credentials: this.legacyDRCnf.getCredentialsMode(),
      headers: this.createHeaders()
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Document not found; guid= ' + docEvent.guid);
    }).then(jsonData => {
      let items = jsonData && jsonData.items;
      if (Array.isArray(items)) {
        return items[0];
      }
      throw new Error('Document not valid; guid= ' + docEvent.guid);
    });
  }

  doBlobRequest(urlTxt) {
    return fetch(urlTxt, {
      method: 'GET',
      credentials: this.legacyDRCnf.getCredentialsMode(),
      headers: this.createHeaders()
    })
      .then(response => response.blob());
  }

  createHeaders(p_headers) {
    let l_headers = {};
    if (p_headers) {
      l_headers = p_headers;
    }
    if (this.legacyDRCnf.getClientJWT() != undefined) {
      if (this.legacyDRCnf.jwtUseAuthorizationHeader) {
        l_headers['Authorization'] = 'Bearer ' + this.legacyDRCnf.getClientJWT();
      }
      else {
        l_getDocumentURL = this.legacyDRCnf.addClientJWTAsURLParam(l_getDocumentURL);
      }
    }

    let csrfHeader = this.legacyDRCnf.getCsrfToken();
    if (csrfHeader) {
      l_headers['GK-CSRF-Token'] = csrfHeader;
    }

    let defaultHeaders = this.legacyDRCnf.getDefaultHeaders();
    if (defaultHeaders) {
      Object.keys(defaultHeaders).forEach(function (key) {
        l_headers[key] = defaultHeaders[key];
      });
    }

    return l_headers;
  }

  getDummyImmageObjectUrl() {
    return this.dummyImmageObjectUrl;
  }

  static dataURItoBlob(dataURI) {
    var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  }
}