import jstemplatecenter from "../template_lib/jstemplatecenter.runtime";
import { StaticRenderer } from "./renderers/staticrenderer";
import { DefaultDocumentParser } from "./parser/default_documentparser";
import { ActiveTopicRenderer } from "./renderers/activeTopicRenderer";
import { Questionnairerenderer } from "./renderers/questionnairerenderer";
import { QuestionnaireEngine } from "./engines/questionnaire/questionnaire.engine";
import { DocumentServiceRestClient } from "./rest/documentService/documentServiceRestClient";
import { BasicConfiguration } from "./configuration/basicConfiguration";
import { ErrorPatternRenderer } from "./renderers/errorPatternRenderer";
import { ErrorPatternEngine } from "./engines/errorPattern/errorPattern.engine";
import { KnowledgeServiceRestClient } from "./rest/knowledgeCenterService/knowledgeCenterServiceRestClient";
import { ConditionalRenderer } from "./renderers/conditionalRenderer";
import { ConditionalEngine } from "./engines/conditional/conditional.engine";
import { MetaEngine } from "./engines/meta/meta.engine";
import { Util } from "./utility/util";
import { KFirstRestClient } from "./rest/kFirst/kFirstRestClient";
import { MetaDocumentRenderer } from "./renderers/metaDocumentRenderer";
import { SUPPORTED_DOCUMENT_TYPES } from "./utility/supportedDocumentTypes";
import { DOCUMENT_STATES } from "./utility/documentStates";
import { FocusTracker } from "./utility/focusTracker";
import { DefaultADHelper} from "./ADRenderer/defaultADHelper";
import * as ADL from "@kcenter/usu-active-document";


// exporting classes need outside the library.
export { BasicConfiguration, DefaultADHelper, QuestionnaireEngine, DocumentServiceRestClient, Util, SUPPORTED_DOCUMENT_TYPES, DOCUMENT_STATES }

export class DocumentRenderer {

    constructor(p_basicConfiguration, p_dontUseDefaultDocTypes) {
        window.Jstemplatecenter = jstemplatecenter;
        jstemplatecenter.templates = [];

        this.documentParser = new DefaultDocumentParser(p_basicConfiguration);
        this.documentTypes = {};
        this.renderers = {};

        p_basicConfiguration.documentRenderer = this;

        let self = this;
        /**
         * create the renderDocumentFunction which automatically loads from the backend and chooses the correct renderer
         * @param {string} p_guid 
         * @param {string} p_mandator 
         * @param {string} p_lang
         * @param {Object} [p_docOptions]
         */
        let renderDocumentFunction = function (p_guid, p_mandator, p_lang, p_docOptions) {

            let l_KFirstRestClient = new KFirstRestClient(p_basicConfiguration);
            let l_KnowledgeServiceRestClient = new KnowledgeServiceRestClient(p_basicConfiguration);
            let l_DocumentServiceRestClient = new DocumentServiceRestClient(p_basicConfiguration);

            l_KFirstRestClient.getBaseSVGIcons()
                .then(function (p_iconMap) {
                    if (p_basicConfiguration.kfirstURL != undefined) // if there is no kfirst url this should not be executed at all. Think of cases where we would like to use this url but don't have a kfirst installation!
                    {
                        document.getElementsByTagName('body')[0].appendChild(self.stringToXML(p_iconMap).documentElement);
                    }
                })
                .catch(error => {
                    // do something?
                    console.kc_log(error)
                })
                .then(function () {
                    return l_KnowledgeServiceRestClient.getNLS(p_lang);
                })
                .then(function (p_labelCache) {
                    p_basicConfiguration.nls_label_cache = p_labelCache;
                })
                .catch(error => {
                    // do something?
                    console.kc_log(error)
                })
                .then(function () {
                    if (!p_docOptions || !p_docOptions.isAD) {
                        return l_DocumentServiceRestClient.getDocument(p_guid, p_mandator, p_lang);
                    }
                })
                .then(result => {
                    // Determine kind of document Legacy/ActiveDocument
                    if ((p_docOptions && p_docOptions.isAD) || result.componentType == 'activeDocument' && result.JsonLD) {

                        console.kc_log('Loaded Active document ' + p_guid);

                        if (!ADL) {
                            console.error('Active documents are not supported');
                            return;
                        }

                        let adHelper = p_basicConfiguration.adHelper;

                        window.adl = ADL; // Imported Actice Document library requires such global variable!
                        const env = ADL.createADEnvironment({});
                        window.adl.env = env;

                        env.documentLoader = adHelper.documentLoader.bind(adHelper);
                        env.urlRewriter.registerURLRewriter(adHelper.urlRewriter.bind(adHelper));
                        env.customFieldsProvider = adHelper.customFieldsProvider.bind(adHelper);
                        env.getValueList = adHelper.getValueList.bind(adHelper);

                        env.scriptApiConfigurers.push((api, context) => {
                            api.isKBaseActionPermissionAllowed = (name) => {
                              //This is only used when editing document, this is not needed for KFirst or SL, but needs to be defined
                            };
                        });

                        adHelper.addActiveDocumentElement({
                            guid: p_guid,
                            mandator: p_mandator,
                            contentLang: p_lang,
                        }, p_docOptions || {});

                        return;
                    }

                    let renderedDocument = self.renderWithDefaultTemplate(result);
                    let contentEl = document.getElementById(p_basicConfiguration.content);
                    contentEl.innerHTML = renderedDocument;
                    let engine = self.getActiveDocumentEngine(result, p_basicConfiguration);
                    if(p_basicConfiguration.activeDocumentEngineFromRendererCallback) {
                        p_basicConfiguration.activeDocumentEngineFromRendererCallback(engine);
                    }
                    /* 
                        if (!(p_basicConfiguration.embeddedInKFirst)) {
                            self.documentParser.addImageListeners(p_guid);
                            self.documentParser.addReferenceListeners(renderDocumentFunction, p_lang);
                            self.documentParser.setKFirstMediaPoolImages();
                        }
                        if (p_basicConfiguration.useNLSLabels) {
                            self.documentParser.setNLSLabels();
                        } else {
                            self.documentParser.setLabels(p_lang);
                        }
                    */
                   self.documentParser.runAfterRenderingFunctions(p_guid, p_basicConfiguration, p_lang, p_basicConfiguration.renderDocumentFunction);
                    if(p_basicConfiguration.finalRendererCallback) {
                        p_basicConfiguration.finalRendererCallback(contentEl);
                    }
                })
                .catch(function (error) {
                    console.kc_log("Loading document caused an error %o", error);
                    return;
                });
        }
        // add the function to the basicConfiguration to make it accessible for engine class
        p_basicConfiguration.renderDocumentFunction = renderDocumentFunction;
        this.configuration = p_basicConfiguration;

        // if this is set to true, no doctypes are registered and the user can limit the supported doctypes further, than what is supported by docrenderer
        if(!p_dontUseDefaultDocTypes) {
            this._registerDefaultDocumentTypes();
        }
        this._registerRenderers(jstemplatecenter, p_basicConfiguration);
        this._registerDefaultHelpers(jstemplatecenter, this);
    }

    stringToXML(oString) {
        //code for IE
        if (window.ActiveXObject) {
            var oXML = new ActiveXObject("Microsoft.XMLDOM"); oXML.loadXML(oString);
            return oXML;
        }
        // code for Chrome, Safari, Firefox, Opera, etc. 
        else {
            return (new DOMParser()).parseFromString(oString, "text/xml");
        }
    }

    /**
     * renders a document with the given parameters
     * @param {*} p_guid 
     * @param {*} p_mandator 
     * @param {*} p_lang
     * @param {Object} [p_docOptions] 
     */
    renderDocument(p_guid, p_mandator, p_lang, p_docOptions) {
        if (p_mandator != null) {
            this.configuration.setDefaultMandator(p_mandator);
        }
        if (p_lang != null) {
            this.configuration.setDefaultLanguage(p_lang);
        }
        this.configuration.renderDocumentFunction(p_guid, p_mandator, p_lang, p_docOptions);
    }

    _registerRenderers(p_jstemplatecenter, p_basicConfiguration) {
        this.renderers[SUPPORTED_DOCUMENT_TYPES.ACTIVE_TOPIC_DOCUMENT_TYPE] = new ActiveTopicRenderer(p_jstemplatecenter);
        this.renderers[SUPPORTED_DOCUMENT_TYPES.STATIC_DOCUMENT_TYPE] = new StaticRenderer(p_jstemplatecenter);
        this.renderers[SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE] = new Questionnairerenderer(p_jstemplatecenter, p_basicConfiguration);
        this.renderers[SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE] = new ErrorPatternRenderer(p_jstemplatecenter, p_basicConfiguration);
        this.renderers[SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE] = new ConditionalRenderer(p_jstemplatecenter);
        this.renderers[SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE] = new MetaDocumentRenderer(p_jstemplatecenter, p_basicConfiguration);
    }

    _registerDefaultDocumentTypes() {
        for (let docTypeKey of Object.keys(SUPPORTED_DOCUMENT_TYPES)) {
            let docType = SUPPORTED_DOCUMENT_TYPES[docTypeKey];

            this.documentTypes[docType] = docType;
        }
    }

    _registerDefaultHelpers(p_jstemplatecenter, p_documentRenderer) {
        p_jstemplatecenter.registerHelper('preprocess', function () {
            let options;
            for (let i = 0; i < arguments.length; i++)
                if (arguments[i] && arguments[i].data && arguments[i].name && arguments[i].fn)
                    options = arguments[i];

            let text = options.fn(this);

            return p_documentRenderer.documentParser.parse(text)
        });
        p_jstemplatecenter.registerHelper('hasStyles', function () {
            let options, hasStyles;
            for (let i = 0; i < arguments.length; i++) {
                if (arguments[i] && arguments[i].data && arguments[i].name && arguments[i].fn)
                    options = arguments[i];
                else
                    hasStyles = arguments[i];
            }

            if (hasStyles)
                return (options.fn(this));
            else
                return "";
        });
        let self = this;
        p_jstemplatecenter.registerHelper('customField', function () {
            let buffer = "";
            for (let i = 0; i < arguments.length; i++) {
                if (arguments[i] && arguments[i].data && arguments[i].name && arguments[i].fn) {
                    let argument = arguments[i];
                    let data = argument.data;
                    if (self.configuration.customFields && data.root && data.root.customFields) {
                        Object.keys(self.configuration.customFields).forEach(function (cfKey) {
                            // iterate over custom field items defined in configuration
                            let currentCustomField = self.configuration.customFields[cfKey];
                            if (currentCustomField) {
                                // try to resolve the label based on defaultLanguage
                                let customFieldLabel = cfKey;
                                let defaultLanguage = self.configuration.defaultLanguage;
                                if (currentCustomField[defaultLanguage]) {
                                    customFieldLabel = currentCustomField[defaultLanguage];
                                }
                                // if we find the custom field key in the document - add it to the display
                                if (data.root.customFields[cfKey]) {
                                    let item = { "customFieldLabel": customFieldLabel, "customFieldValues": data.root.customFields[cfKey] };
                                    buffer += argument.fn(item);
                                }
                            }
                        });
                    }
                }
            }

            return p_documentRenderer.documentParser.parse(buffer);
        });

        p_jstemplatecenter.registerHelper('date_only', function (dateTimeTxt) {
            let pad = function(num) {
                return (num < 10 ? '0' : '') + num;
            };
            let dateTime = new Date(dateTimeTxt);
            return pad(dateTime.getDate()) + '.' + pad(dateTime.getMonth() + 1) + '.' + dateTime.getFullYear();
        });

        p_jstemplatecenter.registerHelper('eq_DocTypeGuid', function (aGuid) {
            return this.doctypeguid === aGuid;
        });

        p_jstemplatecenter.registerHelper('eq_Generic', function (arg1, arg2) {
            return arg1 === arg2;
        });

        p_jstemplatecenter.registerHelper('ecrCustomFields', function () {

            let customFields = this.customFields;
            if (customFields) {
                let theadBuffer = "";
                let tbodyBuffer = "";

                Object.keys(customFields).forEach(function (cfKey) {
                    // iterate over all custom fields
                    let cfKeyLabel = cfKey;
                    let cfValueRaw = customFields[cfKey];
                    let cfValue = [];

                    if (typeof cfValueRaw === 'object' && cfValueRaw !== null) {
                        let cfObjectTitleRaw = cfValueRaw.title;
                        if (cfObjectTitleRaw != null) {
                            cfValue = cfObjectTitleRaw.split(/;;/);
                        }
                    } else {
                        cfValue = [cfValueRaw];
                    }
                    // Add all custom fields to template context
                    let item = { key: cfKey, keyLabel: cfKeyLabel, values: cfValue, valueRaw: cfValueRaw };

                    theadBuffer += '<th scope="col">' + p_jstemplatecenter.escapeExpression(cfKeyLabel) + '</th>';
                    let valueBuffer = '';
                    cfValue.forEach(function(item) {
                        if (valueBuffer) {valueBuffer += '<br>';}
                        valueBuffer += p_jstemplatecenter.escapeExpression(item);
                    });
                    tbodyBuffer += '<td>' + valueBuffer + '</td>';
                });

                let buffer = 
                    '<table class="table table-bordered table-sm">' +
                    '<thead class="thead-dark table-dark">' +
                    '<tr>' + 
                    theadBuffer +
                    '</tr>' +
                    '</thead>' +
                    '<tbody>' +
                    '<tr>' +
                    tbodyBuffer +
                    '</tr>' +
                    '</tbody>' +
                    '</table>';

                return new p_jstemplatecenter.SafeString(buffer);
            }
            return '';
        });
    }

    registerDefaultTemplate(p_template, p_documentType) {
        jstemplatecenter.templates[p_documentType] = jstemplatecenter.template(p_template);
    }


    /**
     * call would be like: registerDocumentType("customQuestionnaire", SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE)
     * @param {*} p_customDocumentType 
     * @param {*} p_documentType 
     */
    registerDocumentType(p_customDocumentType, p_documentType) {
        this.documentTypes[p_customDocumentType] = p_documentType;
    }

    // TODO: stop, if the document type is not supported
    renderWithVariableTemplate(p_document, p_templateName, p_template) {
        let renderer = this._chooseRenderer(p_document.componentType)

        jstemplatecenter.templates[p_templateName] = jstemplatecenter.template(p_template);

        let renderedContent = renderer.render(p_document, p_templateName);

        if(this.configuration.documentRenderedAction) {
            this._callDocumentRenderedCallback(p_document);
        }

        return renderedContent;
    }

    renderWithDefaultTemplate(p_document) {
        let renderer
        try {
            renderer = this._chooseRenderer(p_document.componentType)
        } catch (error) {
            console.error("An error happened while rendering: %o", error);
        }

        let renderedContent = renderer.render(p_document, p_document.componentType);

        if(this.configuration.documentRenderedAction) {
            this._callDocumentRenderedCallback(p_document);
        }

        return renderedContent;
    }

    getActiveDocumentEngine(p_document, p_basicConfiguration, p_externalLoad, p_lang) {
        if (p_externalLoad) {
            if(!document.getElementById(p_basicConfiguration.content)) {
                p_basicConfiguration.content = p_basicConfiguration.kbotContent;
            }
            let self = this;
            if (p_basicConfiguration.useNLSLabels) {
                new KnowledgeServiceRestClient(p_basicConfiguration).getNLS(p_document.contentLang).then(function (p_labelCache) {
                    p_basicConfiguration.nls_label_cache = p_labelCache;
                    self.documentParser.setNLSLabels()
                }).catch(error => {
                    console.kc_log(error)
                });
            } else {
                this.documentParser.setLabels(p_lang); // TODO: setLabels should be the call regardles which labels are used
            }
        }
        switch (this.documentTypes[p_document.componentType]) {
            case SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE:
                return new QuestionnaireEngine(p_document, p_basicConfiguration);

            case SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE:

                return new ErrorPatternEngine(p_document, p_basicConfiguration)
            case SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE:
                return new ConditionalEngine(p_document, p_basicConfiguration);

            case SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE:
                return new MetaEngine(p_document, p_basicConfiguration);
        }
    }

    _chooseRenderer(p_componentType) {
        switch (this.documentTypes[p_componentType]) {
            case SUPPORTED_DOCUMENT_TYPES.ACTIVE_TOPIC_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.STATIC_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE:
                return this.renderers[this.documentTypes[p_componentType]];
            default:
                throw this._unsupportedComponentType(p_componentType);
        }
    }

    _unsupportedComponentType(p_componentType) {
        return "component type '" + p_componentType + "' is currently not supported!";
    }

    _getDefaultTemplateName(p_documentType) {
        return this.defaultTemplates[p_documentType];
    }

    getSupportedDocumentTypes() {
        return SUPPORTED_DOCUMENT_TYPES;
    }

    _callDocumentRenderedCallback(p_document) {
        let documentState;

        switch (this.documentTypes[p_document.componentType]) {
            case SUPPORTED_DOCUMENT_TYPES.STATIC_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.CONDITIONAL_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.META_DOCUMENT_TYPE: 
                documentState = DOCUMENT_STATES.COMPLETED;
                break;
            case SUPPORTED_DOCUMENT_TYPES.QUESTIONNAIRE_DOCUMENT_TYPE:
            case SUPPORTED_DOCUMENT_TYPES.ERROR_PATTERN_DOCUMENT_TYPE: 
                documentState = DOCUMENT_STATES.NOT_STARTED;
                break;           
        }

        let callbackParameter = {
            "status": documentState,
            "componentType": p_document.componentType,
            "data": p_document
        }
        this.configuration.documentRenderedAction(callbackParameter);
    }

    documentParserAfterRenderFunctions(p_guid, p_lang) {
        this.documentParser.runAfterRenderingFunctions(p_guid, this.configuration, p_lang, this.configuration.renderDocumentFunction);
    }

    /**
     * @param {HTMLElement} p_element HTML Element to track, usually the element containing the Document.
     */
    trackFocus(p_element)
    {
        this.focusTracker = new FocusTracker(p_element);
    }

    documentHadFocus() {
        return this.focusTracker.getHadFocus();
    }

    returnFocus() {
        this.focusTracker.setFocusToLastElement();
    }

    resetFocus() {
        this.focusTracker.resetFocus();
    }

    getDocumentServiceRestClient() {
        return new DocumentServiceRestClient(this.configuration);
    }
}