import { Question } from "./question";
import { Questionnaire } from "./questionnaire";
import { Answer } from "../shared/answer";
import { ComplexAnswer } from "../shared/complexAnswer";
import { PrimitiveAnswer } from "../shared/primitiveAnswer";
import { Result } from "../shared/result";

export class QuestionnaireBuilder {
    constructor(p_rawQuestionnaire) {
        this.rawQuestionnaire = p_rawQuestionnaire;
    }

    parse() {
        return this._parseRawQuestionnaire(this.rawQuestionnaire);
    }

    _parseRawQuestionnaire(p_rawQuestionnaire) {
        let l_id = p_rawQuestionnaire['id'];
        let l_guid = p_rawQuestionnaire['guid'];
        let l_questions = this._parseQuestionsFromRawQuestionnaire(p_rawQuestionnaire);
        let l_results = this._parseResultsFromRawQuestionnaire(p_rawQuestionnaire);
        return new Questionnaire(l_id, l_guid, l_questions, l_results);
    }

    _parseQuestionsFromRawQuestionnaire(p_rawQuestionnaire) {
        let l_questions = {};
        let l_firstQuestionDisplayed = 1;
        if(p_rawQuestionnaire.questions)
        {
            for (let question of p_rawQuestionnaire.questions) {
                l_firstQuestionDisplayed = this._findFirstQuestionDisplayed(question, l_firstQuestionDisplayed);
                l_questions[question.id] = new Question(
                    question.id,
                    question.sid,
                    l_firstQuestionDisplayed > 0 ? false : true,
                    this._parseAnswersFromRawQuestionnaire(question),
                    question.answerMode,
                    this._canAnswerBeDisplayed(question),
                    question.order,
                    question.conditions ? true : false,
                    question.stepGui_extraDataType ? true : false,
                    question.stepGui_extraDataType,
                    question.stepGui_extraDataMandatory,
                    question.stepGui_extraDataValueList,
                    this._parseQuestionConditionsFromRawQuestionnaire(question),
                    question.firstOnThePage?true:false,
                    question.final?true:false,
                    question.stepGui_answerLook === 'dropDown'
                );
            }
        }
        return l_questions;
    }

    _parseResultsFromRawQuestionnaire(p_rawQuestionnaire) {
        let l_results = {};
        if (p_rawQuestionnaire.results) {
            for (let result of p_rawQuestionnaire.results) {
                l_results[result.id] = new Result(
                    result.id,
                    result.order,
                    this._parseResultConditionsFromRawQuestionnaire(result)
                )
            }
        }

        return l_results;
    }

    _findFirstQuestionDisplayed(p_question, p_currentTestedOrder) {
        if (p_currentTestedOrder > 0) {
            // first question without conditions, 
            // so we set the tested order to 0, 
            // that stops this test from running on further questions
            if (!p_question.conditions) {
                return 0;
            } else {
                return p_question.order + 1;
            }

        }
        return p_question.order;
    }

    _canAnswerBeDisplayed(p_question) {
        if (p_question.conditions) {
            return false;
        }
        return true;
    }

    _parseAnswersFromRawQuestionnaire(p_rawQuestionnaireQuestion) {
        let l_answers = {};
        if (p_rawQuestionnaireQuestion.answers) {
            for (let answer of p_rawQuestionnaireQuestion.answers) {
                l_answers[answer.id] = new Answer(answer.id, false, answer.title);
            }
        }

        return l_answers;
    }

    _parseQuestionConditionsFromRawQuestionnaire(p_question) {
        let l_conditions = [];
        if (p_question.conditions) {
            if (p_question.conditions.componentType) {
                if (p_question.conditions.componentType === "primitiveAnswer") {
                    l_conditions.push(new PrimitiveAnswer(p_question.id, p_question.conditions.referencedAnswer, p_question.conditions.negative));
                } else {
                    l_conditions.push(new ComplexAnswer(p_question, p_question.conditions.operator, p_question.conditions.components, p_question.conditions.negative));
                }
            } else {
                for (let condition of p_question.conditions) {
                    if (condition.componentType === "primitiveAnswer") {
                        l_conditions.push(new PrimitiveAnswer(p_question.id, condition.referencedAnswer, condition.negative));
                    } else {
                        l_conditions.push(new ComplexAnswer(p_question, condition.operator, condition.components, condition.negative));
                    }
                }
            }
        }
        return l_conditions;
    }

    _parseResultConditionsFromRawQuestionnaire(p_result) {
        let l_conditions = [];
        if(p_result.conditions)
        { 
            if (p_result.conditions.componentType) {
                if (p_result.conditions.componentType === "primitiveAnswer") {
                    l_conditions.push(new PrimitiveAnswer(p_result.id, p_result.conditions.referencedAnswer));
                } else {
                    l_conditions.push(new ComplexAnswer(p_result, p_result.conditions.operator, p_result.conditions.components));
                }
            } else {
                for (let condition of p_result.conditions) {
                    if (condition.componentType === "primitiveAnswer") {
                        l_conditions.push(new PrimitiveAnswer(condition.id, p_result.conditions.referencedAnswer));
                    } else {
                        l_conditions.push(new ComplexAnswer(p_result, condition.operator, condition.components));
                    }
                }
            }
        }
        return l_conditions;
    }
}