export class Conditional {
    constructor(p_id, p_guid, p_questions, p_results)
    {
        this.id = p_id;
        this.guid = p_guid;
        this.questions = p_questions;
        this.results = p_results;
        this.conditionsMet = [];
    }

    changeAnswer(p_answerId, p_questionId, p_newValueForAnswer) {
        let l_question = this.questions[p_questionId];

        if (l_question.answerMode === "single") {
            for (let key of Object.keys(l_question.answers)) {
                let answer = l_question.answers[key]
                if (answer.id === p_answerId) {
                    answer.chosen = p_newValueForAnswer;
                }
                else {
                    answer.chosen = false;
                }
            }
        } else {
            l_question.answers[p_answerId].chosen = p_newValueForAnswer;
        }

    }

    evaluateConditions() {
        let l_chosenAnswers = this.getChosenAnswers();
        for(let l_resultKeys of Object.keys(this.results))
        {
            let l_result = this.results[l_resultKeys];
            if(l_result.hasConditions)
            {
                let l_condition = l_result.conditions[0]; // For some reason this is an array. It contains 1 element max.
                
                this._evaluateCondition(l_condition, l_chosenAnswers);

                l_result.canBeDisplayed = l_condition.checkAnswered();
            }
        }

        // do this in the correct order, so it is possible to remove a question and it's anwers from the chosen answers 
        // if the question can't be displayed anymore
        for(let questionKey of Object.keys(this.questions))
        {
            let question = this.questions[questionKey];

            if (question.conditions && question.conditions.length > 0) {
                let condition = question.conditions[0]; // for some reason this is an array, but there can only be one top condition (otherwise we would miss an operator)
                this._evaluateCondition(condition, l_chosenAnswers);

                question.canBeDisplayed = condition.checkAnswered();
            }
            
        }
    }

    _evaluateCondition(p_condition, p_chosenAnswers) {
        if (p_condition.checkConditionsMet(p_chosenAnswers)) {
            this.conditionsMet.push(p_condition);
        }
    }

    getChosenAnswers() {
        let l_chosenAnswers = []; // this contains ids, of questions with chosen answers as well as answers
        for (let questionKey of Object.keys(this.questions)) {
            let question = this.questions[questionKey];

            // Check, if before answered question is hidden now.
            if (question.conditions && question.conditions.length > 0) {
                let condition = question.conditions[0]; // for some reason this is an array, but there can only be one top condition (otherwise we would miss an operator)
                this._evaluateCondition(condition, l_chosenAnswers);

                question.canBeDisplayed = condition.checkAnswered();
            }
            
            if(question.canBeDisplayed) {
                let l_questionAnswered = false;
                for (let answerKey of Object.keys(question.answers)) {
                    let answer = question.answers[answerKey];
                    if (answer.chosen) {
                        l_questionAnswered = true;
                        l_chosenAnswers.push(answer.id);
                    }
                }
                if (l_questionAnswered) {
                    l_chosenAnswers.push(question.id);
                }
            }
        }
        return l_chosenAnswers;
    }
}