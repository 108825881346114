import { ConditionalBuilder } from "./conditionalBuilder";
import { DOCUMENT_STATES } from "../../utility/documentStates";
import { MultipleSelect } from "../../utility/select/multipleSelect";
import { Util } from "../../utility/util";

export class ConditionalEngine {
    constructor(p_rawConditional, p_basicConfiguration) {
        this.rawConditional = p_rawConditional;
        this.basicConfiguration = p_basicConfiguration;
        
        this.conditionalBuilder = new ConditionalBuilder(p_rawConditional, p_basicConfiguration);
        this.conditional = this.conditionalBuilder.parse();
        this.multipleSelects = {};
        //if conditional document doesnt have questions or results, it is an older version
        //and we just display the content
        let hasQuestions = Object.keys(this.conditional.questions).length > 0 ? true : false;
        let hasResults = Object.values(this.conditional.results).length > 0 ? true : false;
        if(hasResults || hasQuestions)
        {
            this._addListenersToInputElements();
        }
        // Initial check to evaluate conditions that need no user input.
        this.conditional.evaluateConditions();
        this._showResults();
        
    }

    processStep(p_questionId, p_chosenAnswerId, p_newValueForAnswer) {
        // console.log(p_questionId, p_chosenAnswerId, p_newValueForAnswer)
        this.conditional.changeAnswer(p_chosenAnswerId, p_questionId, p_newValueForAnswer);
        this.conditional.evaluateConditions();
        this._showResults();
        if(this.basicConfiguration.processStepAction) {
            let callbackParameter = {
                "status": DOCUMENT_STATES.COMPLETED,
                "data": this.getReadableData()
            };
            this.basicConfiguration.processStepAction(callbackParameter);
        }
        

    }

    _addListenersToInputElements() {
        let l_answerIds = this._getAnswerIds();
        let self = this;

        let l_listenerFunction = function (evt) {
            if (evt.target) {
                let l_target = evt.target;
                let l_answerId = l_target.id;
                let l_questionId = self._findQuestionElement(l_target).id;

                self.processStep(l_questionId, l_answerId, l_target.checked);
            }

        }

        let _listenerFunctionSelectElement = function (p_question, p_event) {
            let l_options = p_event.target.options;
            let l_index = l_options.selectedIndex;

            let l_answerId = l_options[l_index].value;
            if(l_answerId === '') {
                self.processStep(p_question.id, l_answerId, false)    
            } else {
                self.processStep(p_question.id, l_answerId, true)    
            }
        }

        for (let id of l_answerIds) {
            let ele = document.getElementById(id);
            if(ele) {
                ele.addEventListener('click', l_listenerFunction);
            }        
        }

        // functions for multiple dropdown, when an answer is selected or removed from the selected answers
        let multipleSelectCallbackSelect = function(p_option, p_questionId) {
            self.processStep(p_questionId, p_option.value, true);
        }
        let multipleSelectCallbackUnselect = function(p_option, p_questionId) {
            self.processStep(p_questionId, p_option.value, false);
        }
        // add listener to dropdown or create a multipleselect dropdown.
        for(let question of this._getDropDownQuestions()) {
            if(question.answerMode === "multiple") {
                this.multipleSelects[question.id] = new MultipleSelect("answerDropDown_"+question.id, multipleSelectCallbackSelect, multipleSelectCallbackUnselect, question.id, this.basicConfiguration);
            } else {
                document.getElementById("answerDropDown_" + question.id).addEventListener('change', _listenerFunctionSelectElement.bind(null, question), false);
            }
        }
    }

    _getAnswerIds() {
        let l_answerIds = [];

        if(this.rawConditional.questions) {
            for (let question of this.rawConditional.questions) {
                if (question.answers) {
                    for (let answer of question.answers) {
                        l_answerIds.push(answer.id)
                    }
                }
            }
        }
        return l_answerIds;
    }

    _findQuestionElement(p_target) {
        let l_parent = p_target.parentElement;

        while(!l_parent.classList.contains('question')) {
            l_parent = l_parent.parentElement;
        }

        return l_parent;
    }

    _showResults()
    {
        let l_results = Object.values(this.conditional.results);

        for(let result of l_results) {
                result.displayed = result.canBeDisplayed;
                this._addDisplayedClassToElement(result.id, result.displayed);
        }

        for(let questionKey of Object.keys(this.conditional.questions)) {
            let question = this.conditional.questions[questionKey];
            this._addDisplayedClassToElement(question.id, question.canBeDisplayed);
        }
    }

    _addDisplayedClassToElement(p_elementId, p_display)
    {
        let elementClassList = Util.getElementByIdWithQuery(p_elementId, this.basicConfiguration);
        if(elementClassList) {
            elementClassList = elementClassList.classList;
            
            let classToAdd = p_display ? 'displayed' : 'notDisplayed';
            let classToRemove = p_display ? 'notDisplayed' : 'displayed';
    
            elementClassList.add(classToAdd);
            if (elementClassList.contains(classToRemove)) {
                elementClassList.remove(classToRemove);
            }
        }
        
    }

    /**
     * returns questions, that are displayed as dropdowns
     */
    _getDropDownQuestions() {
        let l_questions = [];
        if (this.rawConditional.questions) {
            for(let question of this.rawConditional.questions)
            {
                if(question.stepGui_answerLook === "dropDown") {
                    l_questions.push(question)
                }
            }
        }
        return l_questions;
    }

    getReadableData() {
        let l_readableObject = {
            'id': this.conditional.id,
            'guid': this.conditional.guid,
            'dialogId': undefined
        };

        l_readableObject.questions = [];
        for(let questionsKey of Object.keys(this.conditional.questions))
        {
            let l_question = this.conditional.questions[questionsKey];
            
            let l_questionAnswered = false;
            for(let answerKey of Object.keys(l_question.answers)) {
                let l_answer = l_question.answers[answerKey];
                if(l_answer.chosen) {
                    l_questionAnswered = true;
                }
            }
            if(l_questionAnswered) {
                l_readableObject.questions.push(this._createReadableQuestion(l_question));
            }
            
        }

        let l_results = Object.values(this.conditional.results);

        l_readableObject.results = [];
        for(let result of l_results) {
            if(result.canBeDisplayed) {
                l_readableObject.results.push(this._getRawResult(result.id));
            }
        }

        return l_readableObject;
    }

    _getRawQuestion(p_questionId)
    {
        return Object.values(this.rawConditional.questions).find(element => element.id === p_questionId);
    }

    _getRawAnswerFromQuestion(p_answerId, p_rawQuestion)
    {
        return Object.values(p_rawQuestion.answers).find(element => element.id === p_answerId);
    }

    _getRawResult(p_resultId) {
        return Object.values(this.rawConditional.results).find(element => element.id === p_resultId);
    }

    _createReadableQuestion(p_question)
    {
        let l_rawQuestion = this._getRawQuestion(p_question.id);

        let l_readableQuestion = {};
        
        l_readableQuestion['id'] = p_question.id;
        l_readableQuestion['answerMode'] = p_question.answerMode;
        l_readableQuestion['hasExtraData'] = p_question.hasExtraData;
        l_readableQuestion['extraDataValueProperties'] = p_question.extraDataValueProperties;
        l_readableQuestion['title'] = l_rawQuestion.title;
        l_readableQuestion['content'] = l_rawQuestion.content;
        l_readableQuestion['sid'] = l_rawQuestion.sid;
        l_readableQuestion['answers'] = [];

        for(let answerKey of Object.keys(p_question.answers))
        {
            let l_answer = p_question.answers[answerKey];

            if(l_answer.chosen)
            {
                l_readableQuestion.answers.push(this._createReadableAnswer(l_answer, l_rawQuestion));
            }
        }

        return l_readableQuestion;
    }

    _createReadableAnswer(p_answer, p_rawQuestion)
    {
        let l_rawAnswer = this._getRawAnswerFromQuestion(p_answer.id, p_rawQuestion);
        let l_readableAnswer = {};

        l_readableAnswer['id'] = p_answer.id;
        l_readableAnswer['title'] = l_rawAnswer.title;
        l_readableAnswer['content'] = l_rawAnswer.content;
        
        return l_readableAnswer;
    }

    dertermineDocumentState() {
        return DOCUMENT_STATES.COMPLETED;
    }

    getDocumentInfo() {
        return {
            'status': this.dertermineDocumentState(),
            'data': this.getReadableData()
        };
    }
}