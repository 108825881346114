import { PrimitiveAnswer } from "./primitiveAnswer";
import { PrimitiveValue } from "./primitiveValue";

export class ComplexAnswer {
    constructor(p_dependendComponent, p_operator, p_components, p_userOrgUnitGuids, p_negative) {
        this.dependendComponent = p_dependendComponent;
        this.operator = p_operator;
        this.userOrgUnitGuids = p_userOrgUnitGuids;
        this.components = this._parseComponents(p_components);
        this.negative = p_negative;
        // Answered in this case means that the components (complex or primitive answers) are answered 
        this._answered = false;
    }

    checkAnswered() {
        return this._answered;
    }

    _parseComponents(p_components) {

        
        let l_components = [];

        for (let component of p_components) {
            let l_newComponent;

            switch(component.componentType) {
                case "primitiveAnswer":
                    l_newComponent = new PrimitiveAnswer(this, component.referencedAnswer, component.negative);
                    break;
                case "complex":
                    l_newComponent = new ComplexAnswer(this, component.operator, component.components, this.userOrgUnitGuids, component.negative);
                    break;
                case "primitiveValue":
                    l_newComponent = new PrimitiveValue(this, component.referencedQuestion, component.expectedValue, this.userOrgUnitGuids);
                    break;
            }

            l_components.push(l_newComponent);
        }
        return l_components;
    }

    /**
     * checks the conditions, that are currently met (for the whole questionnaire) 
     * and checks it's components
     * returns a boolean whether the answers conditions are met.
     * @param {*} p_conditions the conditions currently met
     */
    checkConditionsMet(p_chosenAnswers, p_notChosenAnswers) {

        let l_andCheckResult = true;
        let positiveAnswer = false;
        for (let component of this.components) {
            let l_result = component.checkConditionsMet(p_chosenAnswers, p_notChosenAnswers);

            if (this.operator === 'or') {
                positiveAnswer = positiveAnswer || l_result;
            } else {
                // if all results are true, this stays true so a complex condition with the and operator should be answered
                l_andCheckResult = l_andCheckResult && l_result;
            }

        }
        if (this.operator === 'and') {
            positiveAnswer = l_andCheckResult;
        }
        this._answered = (this.negative) ? !positiveAnswer : positiveAnswer;
        return this._answered;
    }
}